import React, { useState } from 'react';
import { track } from '../../../../utility';

function FeaturedItem (props) {
  if (typeof props.item.price === 'undefined' || !props.item.price)
    return <></>;

  return (
    <div className="feature-item" onClick={() => {console.log('Track fetured item visit'); track(props.company.id, 'featuredItem'); window.location.href = props.item.url}}>
      <div className="f-image">
        <img src={props.item.image} alt={props.item.title} />
      </div>
      <div className="f-info-container">
        <div className="f-info">
          <div className="f-title">{props.item.title}</div>
          <div className="f-subtitle">{props.item.subtitle}</div>
        </div>
        <div className={(props.item.onSale) ? 'f-price f-sale' : 'f-price'}>
          {(props.item.price.toString().includes("$")) ? props.item.price : '$' + props.item.price}
        </div>
      </div>
    </div>
  );
}

export default FeaturedItem;