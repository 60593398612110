import React, { Component } from 'react';
import VideoManager from '../../../components/VideoManager/videomanager.component';
import FileUpload from '../../../components/FileUpload/fileupload.component';

class Media extends Component {
  render() {
    return (
      <div>
        <h2>Media</h2>
        <div className="dash-stat-block">
          <h3>Logo</h3>
          <FileUpload id={this.props.id}
            region={this.props.region}
            requiredName="logo"
            size="small"
            type="logo"
            service="logo"
            options={{
              outputFormat: 'jpeg',
              targetWidth: 512,
              targetHeight: 512
            }} />
        </div>
        <div className="dash-stat-block">
          <h3>Header</h3>
          <FileUpload id={this.props.id}
            region={this.props.region}
            destination="/profile/"
            requiredName="header"
            type="profile-header"
            service="header"
            options={{
              outputFormat: 'jpeg',
              targetWidth: 1920,
              targetHeight: 1080
            }} />
        </div>
        <div className="dash-stat-block">
          <h3>Gallery</h3>
          <FileUpload id={this.props.id}
            region={this.props.region}
            destination="/profile/"
            type="profile-gallery"
            multiple={true}
            service="profile-gallery"
            options={{
              outputFormat: 'jpeg',
              targetWidth: 1024,
              targetHeight: 1024
            }} />
        </div>
        <div className="dash-stat-block">
          <h3>Videos</h3>
          <VideoManager id={this.props.id} region={this.props.region} />
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({
        id: this.props.id
      })
    }
    if (prevProps.region !== this.props.region) {
      this.setState({
        region: this.props.region
      })
    }
  }
}

export default Media;