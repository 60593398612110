import React, { useState, useEffect } from 'react';
import Switch from "react-switch";
import client from '../../feathers';

import './storeSettings.css';

function StoreSettings(props) {

  const [accountData, setAccountData] = useState({});
  const [storeData, setStoreData] = useState({});
  const [hideSecretKey, setHideSecretKey] = useState(true);
  
  const handleStoreChange = (event) => {
    if (typeof storeData.id === 'undefined') return;

    setStoreData({
      ...storeData,
      [event.currentTarget.name]: event.currentTarget.value
    })


    client.service('store-settings').update(
      storeData.id,
      {
        ...storeData,
        [event.currentTarget.name]: event.currentTarget.value
      }
    ).then(res => {
      console.log('save store settings', res)
    }).catch(err => {
      console.error(err);
    })
  }

  const changeStoreAllowFlatRate = (value) => {
    setStoreData({
      ...storeData,
      allowFlatRate: value
    })

    client.service('store-settings').update(
      storeData.id,
      {
        ...storeData,
        allowFlatRate: value
      }
    ).then(res => {
      console.log('save store settings', res)
    }).catch(err => {
      console.error(err);
    })
  }

  const changeTestStripeData = (value) => {
    setStoreData({
      ...storeData,
      useTestStripeData: value
    })

    client.service('store-settings').update(
      storeData.id,
      {
        ...storeData,
        useTestStripeData: value
      }
    ).then(res => {
      console.log('save store settings', res)
    }).catch(err => {
      console.error(err);
    })
  }

  const changeStoreAllowPickup = (value) => {
    setStoreData({
      ...storeData,
      allowLocalPickup: value
    })

    client.service('store-settings').update(
      storeData.id,
      {
        ...storeData,
        allowLocalPickup: value
      }
    ).then(res => {
      console.log('save store settings', res)
    }).catch(err => {
      console.error(err);
    })
  }

  const changeStoreAllowDelivery = (value) => {
    setStoreData({
      ...storeData,
      allowDelivery: value
    })

    client.service('store-settings').update(
      storeData.id,
      {
        ...storeData,
        allowDelivery: value
      }
    ).then(res => {
      console.log('save store settings', res)
    }).catch(err => {
      console.error(err);
    })
  }

  const handleAccountChange = (event) => {
    setAccountData({
      ...accountData,
      [event.currentTarget.name]: event.currentTarget.value
    })

    client.service('account').patch(
      props.id,
      {[event.currentTarget.name]: event.currentTarget.value}
    ).catch(err => {
      console.error(err);
    })
  }

  const loadData = async () => {
    client.service('store-settings').get(
      null,
      { query: { customerId: props.id } }
    ).then(res => {
      console.log(res);
      if (res.length > 0) {
        setStoreData({
          ...res[0]
        })
      } else {
        client.service('store-settings').create({ customerId: props.id }).then(res => {
          console.log(res);
          setStoreData({
            ...res
          })
        })
      }
    })

    client.service('account').get(
      props.id
    ).then(res => {
      setAccountData({
        stripePublicKey: res.stripePublicKey
      })
    })
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <div className="dash-stat-block">
      <h3>Store Settings</h3>
      <form autoComplete="off">
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Store Name</div>
        <div className='mcg-details-input'>
          <input type="text" name="storeName" onChange={handleStoreChange} defaultValue={storeData.storeName} placeholder="Business Name" autoComplete="off" />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Store Email</div>
        <div className='mcg-details-input'>
          <input type="text" name="storeEmail" onChange={handleStoreChange} defaultValue={storeData.storeEmail} placeholder="sales@business.com" autoComplete="off" />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Notification Email</div>
        <div className='mcg-details-input'>
          <input type="text" name="receiveEmail" onChange={handleStoreChange} defaultValue={storeData.receiveEmail} placeholder="jane@business.com" autoComplete="off" />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Store Logo</div>
        <div className='mcg-details-input'>
          <input type="text" name="storeLogo" onChange={handleStoreChange} defaultValue={storeData.storeLogo} placeholder="https://business.com/images/logo.png" autoComplete="off" />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Store URL</div>
        <div className='mcg-details-input'>
          <input type="text" name="storeUrl" onChange={handleStoreChange} defaultValue={storeData.storeUrl} placeholder="https://www.business.com" autoComplete="off" />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Use Test Stripe Data</div>
        <div className='mcg-details-input'>
          <Switch checked={storeData.useTestStripeData || false}
            onChange={(value) => { changeTestStripeData(value) }}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={24}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={14}
            width={36} />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Stripe Public Key</div>
        <div className='mcg-details-input'>
          <input type="text" name="stripePublicKey" onChange={handleAccountChange} defaultValue={accountData.stripePublicKey} autoComplete="off" />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Stripe Secret Key <span className="mcg-reveal-secret" onClick={() => setHideSecretKey(!hideSecretKey)}>({(hideSecretKey) ? 'show' : 'hide'})</span></div>
        <div className='mcg-details-input'>
          <input type="text" name="stripeSecretKey" onChange={handleStoreChange} defaultValue={storeData.stripeSecretKey} autoComplete="off" />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Stripe Test Public Key</div>
        <div className='mcg-details-input'>
          <input type="text" name="stripeTestPublishKey" onChange={handleStoreChange} defaultValue={accountData.stripeTestPublishKey} autoComplete="off" />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Stripe Test Secret Key <span className="mcg-reveal-secret" onClick={() => setHideSecretKey(!hideSecretKey)}>({(hideSecretKey) ? 'show' : 'hide'})</span></div>
        <div className='mcg-details-input'>
          <input type="text" name="stripeTestSecretKey" onChange={handleStoreChange} defaultValue={storeData.stripeTestSecretKey} autoComplete="off" />
        </div>
      </div>
      </form>
      <h3>Store Shipping Settings</h3>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Allow local pickup?</div>
        <div className='mcg-details-input'>
          <Switch checked={storeData.allowLocalPickup || false}
            onChange={(value) => { changeStoreAllowPickup(value) }}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={24}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={14}
            width={36} />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Allow shipping?</div>
        <div className='mcg-details-input'>
          <Switch checked={storeData.allowFlatRate || false}
            onChange={(value) => { changeStoreAllowFlatRate(value) }}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={24}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={14}
            width={36} />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Allow Delivery?</div>
        <div className='mcg-details-input'>
          <Switch checked={storeData.allowDelivery || false}
            onChange={(value) => { changeStoreAllowDelivery(value) }}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={24}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={14}
            width={36} />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Shipping Cost</div>
        <div className='mcg-details-input'>
          <input type="number" name="flatRate" onChange={handleStoreChange} defaultValue={storeData.flatRate} placeholder="Flat Shipping Rate Amount" autoComplete="off" />
        </div>
      </div>
      <div className='mcg-details-item'>
        <div className='mcg-details-descriptor'>Delivery Cost</div>
        <div className='mcg-details-input'>
          <input type="number" name="deliveryRate" onChange={handleStoreChange} defaultValue={storeData.deliveryRate} placeholder="Delivery Charge Amount" autoComplete="off" />
        </div>
      </div>
    </div>
  )
}

export default StoreSettings;