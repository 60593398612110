import React, { Component } from 'react';
import Footer from './footer';
import Header from './header';
import Navigation from './navigation';
import '../../front-end.css';
import '../../styles/front-end/hero.css'
import client from '../../feathers';
import Checkout from '../../components/Checkout/Checkout';

class Subpage extends Component {
	constructor(props) {
		super(props);

		this.state = {
      company: this.props.company,
      show: false,
      height: 72
    };

    this.toggleNav = this.toggleNav.bind(this);
    this.heightAdjusted = this.heightAdjusted.bind(this);

    this.loadData();
  }

  render() {
    return (
    <div className="mcg-app-wrapper">
      <Navigation links={this.state.links} show={this.state.show} toggleNav={this.toggleNav} />
      <Header company={this.state.company} links={this.state.links} toggleNav={this.toggleNav} heightAdjusted={this.heightAdjusted}
        cart={this.props.cart}
        toggleCart={this.props.toggleCart} />
      {(typeof this.props.company.profile !== 'undefined') ? (
        <div className="sub-hero-dark" style={{marginTop: this.state.height + 'px'}}>
          <div className="sub-hero-image" style={{
            backgroundImage: 'url(' + this.props.company.profile.header + ')',
            }}>
          </div>
          <div className="sub-header-title">{this.props.title}</div>
        </div>
      ) : ''}
      {this.props.children}
      <Footer company={this.state.company} links={this.state.links} />
    </div>
    );
  }

  heightAdjusted(height) {
    this.setState({height})
  }

  toggleNav() {
    this.setState({
      show: !this.state.show
    })
  }

  loadData() {
    console.log('spid', this.props.id)
    client.service('profile-links').find({
      query: {
        customerId: this.props.id
      }
    }).then(result => {
      let dataList = [];
      result.forEach(data => {
        dataList.push({
          link: data.link,
          label: data.text,
          order: data.order
        });
      })

      this.setState({ links: dataList.sort((a, b) => { return a.order - b.order; }) });
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company)
      this.setState({company: this.props.company});
    
    if (prevProps.id !== this.props.id)
      this.loadData();
    
  }
}

export default Subpage;