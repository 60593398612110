import React, { Component } from 'react';
import '../styles/front-end/formfield.css';

class FormField extends Component {
  render() {
    return (
      <div className="mcg-form-field">
        {(this.props.textarea) ? (
          <textarea id={this.props.name} name={this.props.name} value={this.props.value} onChange={this.props.onChange} rows={this.props.height} required />
        ) : (
          <input id={this.props.name} name={this.props.name} value={this.props.value} onChange={this.props.onChange} type="text" required />
        )}
        <label htmlFor={this.props.name}>{this.props.label}{(this.props.required) ? <span className="form-required">*</span> : ''}</label>
      </div>
    );
  }
}

export default FormField;