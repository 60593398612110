import React, { Component } from 'react';
import { withController } from 'react-scroll-parallax';

class ControllerImage extends Component {
  handleLoad = () => {
    this.props.parallaxController.update();
  };

  render() {
    return <img src={this.props.src} onLoad={this.handleLoad} alt={this.props.alt} />;
  }
}

export default withController(ControllerImage);