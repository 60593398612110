import React, { Component } from 'react';
import config from '../../config';
import '../../styles/front-end/gallery.css';
import ControllerImage from '../../components/controllerimage';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

class ParallaxGallery extends Component {
	constructor(props) {
		super(props);

		this.state = {
      images: this.props.images
    };
  }

  render() {
    if (this.props.images.length <= 0)
      return '';

    return (
      <ParallaxProvider>
      <section className="mcg-section-override photos-slider">
        <div className="gallery-view-button">
          <span className="gallery-button" onClick={this.props.toggleGallery}>View gallery</span>
        </div>
        <div className="gallery-gradient"></div>
        <div className="gallery-left-side-gradient"></div>
        <div className="gallery-right-side-gradient"></div>
        {(this.props.title.length > 0) ? <h2 className="gallery-header animation fadeIn">{this.props.title}</h2> : ''}
        <div className="gallery-container">
          {this.renderData()}
        </div>
      </section>
      </ParallaxProvider>
    );
  }

  renderData() {
    let images = [];
    let results = [];

    for (let index = 0; index < this.state.images.length; index++) {
      const image = this.state.images[index];
      
      images.push(
        <div key={'gal-' + index} className="images-item">
          <ControllerImage className='layer-bottom' src={config.api + '/assets/' + this.props.region + '/' + this.props.id + '/profile/' + image.name} alt={image.name} />
        </div>
      );
    }

    let imagesPerRow = 3;
    let limit = 100;
    if (this.state.images.length > 11) {
      imagesPerRow = 4;
      limit = 12;
    } else if (this.state.images.length > 7) {
      imagesPerRow = 4;
      limit = 8;
    } else if (this.state.images.length > 5) {
      imagesPerRow = 3;
      limit = 6;
    } else {
      imagesPerRow = 5;
      limit = 5;
    }


    let count = 0;
    for (let x = 0; x < images.length / imagesPerRow && count < limit; x++) {
      let row = [];

      for (let i = (x * imagesPerRow); i < ((x * imagesPerRow) + imagesPerRow) && count < limit; i++) {
        if (i < images.length) {
          const image = images[i];
          
          row.push(image);
          count++;
        }
      }

      results.push(
        <Parallax key={'row-' + x} className="gallery-parallax" x={(x % 2 === 0) ? [-10, 10] : [10, -10]} tagOuter="figure">
          <div className="gallery-row" onClick={(event) => {console.log(event.currentTarget.offsetWidth)}}>
            {row}
          </div>
        </Parallax>
      );
    }


    return results;
  }

  static getDerivedStateFromProps (props, state) {
    state.images = props.images;
    
    return state;
  }
}

export default ParallaxGallery;