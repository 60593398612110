import React, { Component, useState, useEffect } from 'react';
import '../../styles/front-end/articles.css';
import config from '../../config';

const cacheTime = 1000 * 60 *60 * 12;

function Facebook(props) {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    loadData();
  }, [props.facebookUsername])

  function loadData() {
    // fetch to get data
    fetch(config.api + 'get-facebook', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        customerId: props.customerId,
        facebookId: props.facebookUsername,
        limit: props.options.facebookLimit
      })
    }).then(response => { if (response.ok) return response.json(); throw response.json(); }).then(data => {
      console.log(data);
      setArticles(data.data);
    })

    // TEST ALG ONLY
    //facebookScrape(props.facebookUsername, props);
  }

  function renderFacebook() {
    if (typeof articles === 'undefined' || typeof props.options === 'undefined')
      return;

    let result = [];

    for (let index = 0; index < articles.length && index < props.options.facebookLimit; index++) {
      const post = articles[index];

      if ((typeof post !== 'undefined' && typeof post.content !== 'undefined')) {
        result.push(
          <div key={'fb-story-' + index} className="mcg-story">
            {(post.hasOwnProperty('image') && post.image) ? (<div className="story-bubble">
              <img src={post.image} alt={'facebook post'} />
            </div>) : ''}
            <div className="story-inner">
              <div className="story-source"><a href={post.link} target="_blank">Facebook</a></div>
              <div className="story-content">
                <a href={post.link} target="_blank">
                  <div className="story-headline" dangerouslySetInnerHTML={{__html: post.content}}>
                    {/*((typeof post.content !== 'undefined') ? post.content : '')*/}
                  </div>
                </a>
              </div>
              {/*<div className="story-created"><Moment fromNow date={post.created_time}></Moment></div>*/}
            </div>
          </div>
        );
      }
    }
    return result;
  }

  function renderFacebookHorizontal() {
    if (typeof articles === 'undefined' || typeof props.options === 'undefined')
      return;

    let result = [];

    for (let index = 0; index < articles.length && index < props.options.facebookLimit; index++) {
      const post = articles[index];

      if ((typeof post !== 'undefined' && typeof post.content !== 'undefined')) {
        result.push(
          <div key={'fb-horizontal-story-' + index} className="mcg-story-horizontal">
            {(post.hasOwnProperty('image') && post.image) ? (<div className="story-horizontal-image">
              <img src={post.image} alt={'facebook post'} />
            </div>) : ''}
            <div className="story-horizontal-inner">
              <div className="story-horizontal-content">
                <a href={post.link} target="_blank">
                  <div className="story-horizontal-headline" dangerouslySetInnerHTML={{__html: post.content}}>
                  </div>
                </a>
              </div>
              <div className="story-source"><a href={post.link} target="_blank">Facebook</a></div>
            </div>
          </div>
        );
      }
    }
    return result;
  }

  return (
    <section id={props.block}>
      {(props.title.length > 0) ? <h2 className="animation fadeIn">{props.title}</h2> : ''}
      {(props.options.facebookDisplay === 'horizontal') ? (
        <div className={"mcg-articles-horizontal animation fadeIn"}>
          {renderFacebookHorizontal()}
        </div>
      ) : (
        <div className={"mcg-articles animation fadeIn"}>
          {renderFacebook()}
        </div>
      )}
    </section>
  );
}

// Test fb scrape
/*async function facebookScrape(facebookId, props) {
  await Axios.get('https://www.facebook.com/pg/' + facebookId + '/posts/').then(async (result) => {
    let parser = new DOMParser();
    let htmlDoc = parser.parseFromString(result.data, 'text/html');
    let elements = htmlDoc.getElementsByClassName('userContentWrapper');
    let facebookData = [];
    let fbPost, content, images;

    //console.log('elements', elements);

    for (let i = 0; i < elements.length; i++) {
      content = elements[i].getElementsByClassName('userContent');
      images = elements[i].getElementsByClassName('scaledImageFitHeight');

      fbPost = {
        link: 'http://www.facebook.com/' + facebookId,
        customerId: props.customerId
      };
      if (typeof content !== 'undefined')
        fbPost.content = content[0].innerHTML;

      if (images.length > 0 && typeof images[0].dataset !== 'undefined') {
        fbPost.image = images[0].dataset.src;
        
        //console.log('images', images[0].attributes.src);
        //console.log('image ds:', images[0].dataset.src);
      }

      if (Object.keys(fbPost).length > 0){
        facebookData.push({...fbPost});

        //client.service('facebook-cache').create({...fbPost}).catch(error => {
        //  console.error(error);
        //});
      }
    }

    console.log('facebookData',facebookData);
  }).catch(error => {
    console.error(error);
  })
}*/

export default Facebook;