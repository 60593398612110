import React, { Component } from 'react';
import config from '../../config';
import '../../styles/front-end/files.css';
import { track } from '../../utility';

class Files extends Component {
	constructor(props) {
		super(props);

		this.state = {
      files: this.props.files
    };
  }

  render() {
    if (this.props.files.length <= 0)
      return '';

    return (
      <section id={this.props.block}>
        {(this.props.title.length > 0) ? <h2 className="animation fadeIn">{this.props.title}</h2> : ''}
        <div className="files-list">
          {this.renderData()}
        </div>
      </section>
    );
  }

  renderData() {
    let results = [];

    for (let index = 0; index < this.state.files.length; index++) {
      const file = this.state.files[index];

      results.push(
        <div key={'file-' + index} className="files-item">
          <a href={config.api + '/assets/' + this.props.region + '/' + this.props.id + '/profile/' + file.name} onClick={() => { console.log('Track file click'); track(this.props.company.id, 'fileDownload');}} className="file-indicator" target="_blank" rel="noopener noreferrer">
            {(file.name.includes('.pdf')) ? 
              <i className="fas fa-file-pdf"></i>
            :
            <i className="fal fa-file-image"></i>
            } <span className="file-label">{file.name}</span>
          </a>
        </div>
      );
    }

    return results;
  }

  static getDerivedStateFromProps (props, state) {
    state.files = props.files;

    return state;
  }
}

export default Files;