import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import Marketplace from './containers/dashboard/page/marketplace/marketplace';
import App from './App';
import AdminApp from './admin-app';
import './index.css';
import { track } from './utility';
import Orders from './containers/dashboard/page/orders/orders';
import OrderItem from './containers/dashboard/page/orders/orderItem';
import client from './feathers';
import CreateItem from './containers/dashboard/page/marketplace/CreateItem';

// Test Company: 12298, Homes with Jessie: 7343, Pub 981: 2518, Jimmy MacKinley: 16154


ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
/*document.querySelectorAll('.admin-area')
.forEach(domContainer => {
	const id = domContainer.dataset.id;
  const region = domContainer.dataset.region;

	ReactDOM.render(
		<AdminApp
      id={id}
      region={region}
		/>,
		domContainer
	);
});
document.querySelectorAll('.marketplace-admin')
.forEach(domContainer => {
	const id = domContainer.dataset.id;
  	const region = domContainer.dataset.region;

	ReactDOM.render(
		<BrowserRouter>
		<Switch>
		<Route path="/">
			<Marketplace customerId={id} region={region} adminLink={true} />
		</Route>
		</Switch>
		</BrowserRouter>,
		domContainer
	);
});
document.querySelectorAll('.marketplace-item-admin')
.forEach(domContainer => {
	const id = domContainer.dataset.id;
	const region = domContainer.dataset.region;
	const itemId = domContainer.dataset.itemId;

	ReactDOM.render(
		<BrowserRouter>
		<Switch>
		<Route path="/:id">
			<CreateItem customerId={id} region={region} itemId={itemId} adminLink={true} />
		</Route>
		<Route path="/">
			<CreateItem customerId={id} region={region} itemId={itemId} adminLink={true} />
		</Route>
		</Switch>
		</BrowserRouter>,
		domContainer
	);
});
document.querySelectorAll('.account-orders-admin')
.forEach(domContainer => {
	const id = domContainer.dataset.id;
  const region = domContainer.dataset.region;

	ReactDOM.render(
		<BrowserRouter>
			<Switch>
				<Route path="/">
    			<Orders customerId={id} region={region} adminLink={true} />
				</Route>
			</Switch>
		</BrowserRouter>,
		domContainer
	);
});
document.querySelectorAll('.account-orderitem-admin')
.forEach(domContainer => {
	const id = domContainer.dataset.id;
	const region = domContainer.dataset.region;
	const itemId = domContainer.dataset.itemId;

	ReactDOM.render(
		<BrowserRouter>
			<Switch>
				<Route path="/:id">
					<OrderItem customerId={id} region={region} itemId={itemId} adminLink={true} />
				</Route>
				<Route path="/">
					<OrderItem customerId={id} region={region} itemId={itemId} adminLink={true} />
				</Route>
			</Switch>
		</BrowserRouter>,
		domContainer
	);
});*/


//<Marketplace  customerId={this.props.id} region={this.props.company.region} />

serviceWorker.unregister();
