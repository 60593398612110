import React, { Component } from 'react';
import TimePicker from 'react-time-picker';
import Switch from "react-switch";

import client from '../../feathers';

import './hourmanager.css';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

class HourManager extends Component {
	constructor(props) {
		super(props);

    this.state = {
      time: [],
      created: false
    }

    this.onChange = this.onChange.bind(this);
    this.loadData();
  }

  render() {
    return (
      <div className="dash-stat-block">
        <h3>Business Hours</h3>
        {this.renderDays()}
      </div>
    );
  }

  renderDays() {
    if (typeof this.state.time === 'undefined' || this.state.time.length <= 0)
      return;

    let results = [];

    for (let index = 0; index < days.length; index++) {
      const day = days[index];

      results.push(
      <div key={day + index} className='hours-definition'>
        <div className='hours-day-group'>
          <div className='hours-day'>
            {day}
          </div>
          <div className='hours-opened-group'>
            <label>{(this.state.time[day].isOpen) ? 'Opened' : 'Closed'}</label>
            <Switch checked={this.state.time[day].isOpen}
              onChange={() => this.changeToggle(day, 'isOpen')}
              className='hours-opened'
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={24}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={14}
              width={36} />
          </div>
        </div>
        <div className='hours-appointment-group'>
          <label>By Appointment</label>
          <Switch checked={this.state.time[day].byAppointment}
            onChange={() => this.changeToggle(day, 'byAppointment')}
            className='hours-appointment'
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={24}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={14}
            width={36} />
        </div>
        <div className='hours-time'>
          <div className='hours-time-select'>
            <label>From</label>
            <TimePicker
              className='hours-time-picker'
              disableClock={true}
              onChange={(time) => this.onChange(time, day, 'open')}
              value={this.state.time[day].open}
            />
          </div>
          <div className='hours-time-select'>
            <label>To</label>
            <TimePicker
              className='hours-time-picker'
              disableClock={true}
              onChange={(time) => this.onChange(time, day, 'close')}
              value={this.state.time[day].close}
            />
          </div>
        </div>
      </div>
      );
    }

    return results;
  }

  onChange(time, day, selector) {
    let currenttime = this.state.time;

    if (!time) {
      if (selector === 'open')
        currenttime[day][selector] = '09:00:00';
      else
        currenttime[day][selector] = '17:00:00';
      
      
      currenttime[day].isOpen = false;
    } else {
      currenttime[day][selector] = time;
    }

    this.setState({
      time: currenttime
    });

    client.service('profile-hours').patch(
      currenttime[day].id,
      {
        open: currenttime[day].open,
        close: currenttime[day].close,
        isOpen: currenttime[day].isOpen
      }
    )
  }

  changeToggle(day, selector) {
    let currenttime = this.state.time;
    currenttime[day][selector] = !this.state.time[day][selector];

    this.setState({
      time: currenttime
    })

    console.log('currenttime',currenttime);
    
    client.service('profile-hours').patch(
      currenttime[day].id,
      {
        [selector]: currenttime[day][selector]
      }
    )
  }

  loadData() {
    client.service('profile-hours').find({
      query: {
        customerId: this.props.id
      }
    }).then(res => {
      let time = {};

      if (res.length > 0) {
        for (let index = 0; index < res.length; index++) {
          const value = res[index];
          const day = value.day;
          time[day] = {};
          time[day].id = value.id;
          time[day].customerId = this.props.id;
          time[day].open = value.open;
          time[day].close = value.close;
          time[day].isOpen = value.isOpen;
          time[day].byAppointment = value.byAppointment;
        }
      } else {
        for (let index = 0; index < days.length; index++) {
          let temp = {};
          temp.day = days[index];
          temp.customerId = this.props.id;
          temp.open = '09:00:00';
          temp.close = '17:00:00';
          temp.isOpen = false;
          temp.byAppointment = false;

          console.log('saving',temp);
          if (typeof this.props.id !== 'undefined' && this.props.id)
          client.service('profile-hours').create(
            temp
          ).then(res => {
            console.log('created', res);
          })
        }
        
        this.setState({created: true});
        if (!this.state.created)
          setTimeout(() => this.loadData(), 1000);
      }

      this.setState({
        time: time
      })
    })
  }
}

export default HourManager;