import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import client from '../../feathers';

class ContentManager extends Component {
	constructor(props) {
		super(props);

		this.state = {
      value: ''
    };
    
    this.onChange = this.onChange.bind(this);
    this.saveData = this.saveData.bind(this);

    this.loadData();
  }

  render() {
    return (
      <div className="dash-stat-block">
        <h3>Content Area</h3>
        <ReactQuill value={this.state.value} onChange={this.onChange} onBlur={this.saveData} />
      </div>
    );
  }

  saveData() {
    client.service('account').patch(
      this.props.id,
      {
        str_content_area_content: this.state.value
      }
    )
  }

  loadData() {
    client.service('account').get(
      this.props.id,
      {
        query: {
          $select: ['str_content_area_content']
        }
      }
    ).then(res => {
      this.setState({
        value: res.str_content_area_content
      })
    })
  }

  onChange(value) {
    this.setState({
      value
    })
  }
}

export default ContentManager;