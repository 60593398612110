import React, {useState, useEffect} from 'react';
import { useHistory, Link } from 'react-router-dom';

import client from '../../../../feathers';

import './orders.css';
import Moment from 'react-moment';

function Orders(props) {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  let history = useHistory();

  useEffect(() => {
    loadOrders();
  }, [props.customerId]);

  const loadOrders = () => {
    client.service('account-orders').find({
      query: {
        customerId: props.customerId
      }
    }).then(res => {
      //console.log(res)
      setOrders(res.sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt) }));
      setFilteredOrders(res.sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt) }));
    }).catch(error => {
      console.log(error);
    })
  }

  const renderOrders = () => {
    let results = [];
    const sortedOrders = filteredOrders.sort((a, b) => b.createdAt - a.createdAt);

    for (let i = 0; i < sortedOrders.length; i++) {
      const order = sortedOrders[i];
      
      results.push(
        <div key={'order'+i} className="ma-order-item" onClick={() => {
          if (props.adminLink) {
            window.location.href = '/modules/orders/item/?r=' + props.region + '&custid=' + props.customerId + '&item=' + order.id;
          } else
            history.push('/dashboard/orders/' + order.id); 
        }}>
          <div className="ma-order-item-name">
            <div>
              <div>{order.name}</div>
              <div className="ma-order-item-order">ORDER # {order.orderNumber} - {(order.shippingType === 'pickup') ? 'Pickup' : 'Shipping'}</div>
            </div>
            <div>
              <div className="ma-order-item-createdAt"><Moment fromNow date={order.createdAt} /></div>
            </div>
          </div>
          <div className="ma-order-item-extra-details">
            <div className="ma-order-item-details">
              <div className="ma-order-item-order-status">{order.status}</div>
              <div className="ma-order-item-item-count">{calculateItemCount(order.lineItems)} {(calculateItemCount(order.lineItems) > 1) ? 'Items' : 'Item'}</div>
              <div className="ma-order-item-total">{(order.total) ? '$' + (order.total * 0.01).toFixed(2) : ''}</div>
            </div>
          </div>
        </div>
      );
    }

    return results;
  }

  const calculateItemCount = (items) => {
    let results = 0;
    let lineItems = items.split(',');

    lineItems.forEach(item => {
      if (typeof item !== 'undefined' && item)
        results += Number(item.split(':')[2]);
    });

    return results;
  }

  const filterOrders = (value) => {
    if (value === 'none')
      setFilteredOrders(orders.sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt) }));
    else
      setFilteredOrders(orders.filter(e => e.status === value).sort((a, b) => { return new Date(b.createdAt) - new Date(a.createdAt) }));
  }

  return (
    <div className="ma-order-section">
      <h2>Orders</h2>
      <div className="ma-order-status-filters">
        Status Filter:
        <span className="ma-order-filter-links" onClick={() => filterOrders('none')}>none</span>|
        <span className="ma-order-filter-links" onClick={() => filterOrders('paid')}>paid</span>|
        <span className="ma-order-filter-links" onClick={() => filterOrders('shipped')}>shipped</span>|
        <span className="ma-order-filter-links" onClick={() => filterOrders('Complete')}>complete</span>
      </div>
      {renderOrders()}
    </div>
  )
}

export default Orders;