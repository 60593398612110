import React, { useState, useEffect, useRef } from 'react';

function DisplayItem(props) {
  const itemRef = useRef(null);

  const [showAlert, setShowAlert] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [alert, setAlert] = useState('');
  const [screenWidth, setScreenWidth] = useState((window.innerWidth < window.outerWidth) ? window.innerWidth : window.outerWidth);

  useEffect(() => {
    detectAlerts();
    document.addEventListener("mousedown", (e) => {
      if (itemRef.current && !itemRef.current.contains(e.target)) {
        setShowAlert(false);
        setShowActions(false);
      }
    });
    
    window.addEventListener('resize', () => {
      setScreenWidth((window.innerWidth < window.outerWidth) ? window.innerWidth : window.outerWidth);
    })
  }, [])

  const detectAlerts = () => {
    if (props.item.status === 'draft')
      setAlert('Draft, this item will not be visible until published.')

    if (props.item.trackStock && (getStock() <= (props.item.stockWarning || 3)))
      setAlert('Low stock warning.')

    if (typeof props.item.images === 'undefined' || props.item.images.length <= 0)
      setAlert('This item appears to be missing an image.')
  }

  const viewDetails = () => {
    props.select(props.item);
  }
  
  const deleteItem = () => {
    props.remove(props.item.id);
    console.log('deleting item with id: ', props.item.id);
  }

  const getPrices = () => {
    if (props.item.variations.length > 1) {
      let lowest = Number.POSITIVE_INFINITY;
      let highest = 0;
      for (let i = 0; i < props.item.variations.length; i++) {
        const variation = props.item.variations[i];

        if (typeof variation.price !== 'undefined' && variation.price) {
          if (Number(variation.price) > highest)
            highest = Number(variation.price);
          
          if (Number(variation.price) < lowest)
            lowest = Number(variation.price);
        }
      }
      
      if (lowest !== highest)
        return '$' + lowest.toFixed(2) + ' - $' + highest.toFixed(2);
      else
        return '$' + lowest.toFixed(2);
    } else {
      if (props.item.variations.length > 0)
        if (typeof props.item.variations[0].price !== 'undefined' && props.item.variations[0].price)
          return '$' + Number(props.item.variations[0].price).toFixed(2);
    }
    
    return '-';
  }

  const getStock = () => {
    let stock = 0;

    for (let i = 0; i < props.item.variations.length; i++) {
      const variation = props.item.variations[i];

      if (typeof variation.stock !== 'undefined' && variation.stock)
        stock += Number(variation.stock);
    }

    return stock;
  }

  return (
    <tr className="a-market-table-item" ref={itemRef}>
      <td className="a-market-table-featured">
        <span className={'market-featured market-icon' + ((props.item.featured) ? '' : ' market-not-featured ')} onClick={() => { props.setFeatured(props.item.id, !props.item.featured) }}>
          <i className="fas fa-star"></i>
        </span>
        <span className={'market-promoted market-icon' + ((props.item.promoted) ? '' : ' market-not-promoted')} onClick={() => { props.setPromoted(props.item.id, !props.item.promoted) }}>
          <i className="fas fa-ad"></i>
        </span>
      </td>
      <td className="a-market-table-title a-market-table-clickable" onClick={viewDetails}>
        {(alert.length > 0) ? (
          <span className="mcg-missing-component-alert" onClick={(e) => { e.stopPropagation(); setShowAlert(true)}} onPointerEnter={() => setShowAlert(true)} onPointerLeave={() => setShowAlert(false)}>
            <i className="fas fa-exclamation-triangle"></i>
            <div className={(showAlert) ? 'alert-tooltip show' : 'alert-tooltip'}>{alert}</div>
          </span>
        ) : ''}
        {props.item.title}
      </td>
      <td className={'a-market-table-sku a-market-table-clickable' + ((screenWidth < 1000) ? ' market-hide' : '')} onClick={viewDetails}>{(props.item.variations.length > 1) ? props.item.variations.length + ' Variations' : ((props.item.variations.length > 0) ? props.item.variations[0].sku : '')}</td>
      {(typeof props.categories.find(e => e.id === props.item.categoryId) !== 'undefined') ? (
        <td className={'a-market-table-category a-market-table-clickable' + ((screenWidth < 1200) ? ' market-hide' : '')} onClick={viewDetails}>{props.categories.find(e => e.id === props.item.categoryId).category}</td>
      ) : (
        <td className={'a-market-table-category a-market-table-clickable' + ((screenWidth < 1200) ? ' market-hide' : '')} onClick={viewDetails}>-</td>
      )}
      <td className={'a-market-table-stats a-market-table-clickable' + ((screenWidth < 800) ? ' market-hide' : '')} onClick={viewDetails}>{(typeof props.stats !== 'undefined') ? props.stats.views + props.stats.clicks : ''}</td>
      <td className={'a-market-table-stock a-market-table-clickable' + ((screenWidth < 600) ? ' market-hide' : '')} onClick={viewDetails}>{getStock()}</td>
      <td className={'a-market-table-price a-market-table-clickable' + ((screenWidth < 1000) ? ' market-hide' : '')} onClick={viewDetails}>{getPrices()}</td>
      <td className="a-market-table-actions">
        <span className="market-icon" onClick={(e) => { setShowActions(!showActions)}}><i className="fas fa-pen"></i></span>
        <div className={(showActions) ? 'market-actions-menu show' : 'market-actions-menu'}>
          <div onClick={() => { viewDetails(); setShowActions(false);}}>Edit</div>
          <div onClick={() => { props.setFeatured(props.item.id, !props.item.featured); setShowActions(false);}}>Toggle Website Featured</div>
          <div onClick={() => { props.setPromoted(props.item.id, !props.item.promoted); setShowActions(false);}}>Toggle Marketplace Ad</div>
          <div onClick={() => { deleteItem(); setShowActions(false); }}>Delete</div>
        </div>
      </td>
    </tr>
  );
}

export default DisplayItem;