import React, { Component } from 'react';

class DashboardInput extends Component {
  render() {
    return (
      <label className="mcg-label">
        {(typeof this.props.label !== 'undefined') ? <span className="mcg-label-text">{this.props.label} {this.props.icon}</span> : ''}
        <input type="text" name={this.props.name} value={this.props.value} onChange={this.props.onChange} placeholder={this.props.placeholder} />
      </label>
    );
  }
}

export default DashboardInput;