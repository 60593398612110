import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router';
import DisplayItem from './displayItem';
import { CSVLink, CSVDownload } from "react-csv";

import client from '../../../../feathers';

import './marketplace.css';
import CreateItem from './CreateItem';
import config from '../../../../config';

function Marketplace(props) {
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [itemStats, setItemStats] = useState([]);
  const [textFilter, setTextFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [filteredItems, setFilteredItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [screenWidth, setScreenWidth] = useState((window.innerWidth < window.outerWidth) ? window.innerWidth : window.outerWidth);
  const [newlyCreated, setNewlyCreated] = useState(false);
  const [sortStyle, setSortStyle] = useState('newest');

  const [csvItems, setCSVItems] = useState([]);

  useEffect(() => {
    loadCategories();
    loadItems();
    window.addEventListener('resize', () => {
      setScreenWidth((window.innerWidth < window.outerWidth) ? window.innerWidth : window.outerWidth);
    })
    console.log('adminLink', props.adminLink);
  }, [])
  useEffect(() => {
    let results = [];
    items.forEach(item => {
      item.variations.forEach(variation => {
        let cat = item.category;
        delete item.category;
        let temp = {...item, ...variation, ...cat};

        delete temp.variations;
        delete temp.options;
        delete temp.images;

        if (temp.description != null)
          temp.description = temp.description.split('"').join('""');

        results.push(temp);
      })
    })

    setCSVItems(results);
  }, [items]);

  const loadItems = async () => {
    client.service('market-stats').find({
      query: {
        customerId: props.customerId
      }
    }).then(res => {
      setItemStats(res);
    }).catch(error => {
      console.error(error);
    })
    
    client.service('market-items').find({
      query: {
        customerId: props.customerId
      }
    }).then(res => {
      setItems(res);
      setFilteredItems(res);
    }).catch(error => {
      console.error(error);
    })
  }

  const filterResults = (e) => {
    setTextFilter(e.currentTarget.value.toLowerCase().trim().split(' ').join(''));
  }

  const filterCategories = (e) => {
    setCategoryFilter(e.currentTarget.value);
  }
  
  const loadCategories = () => {
    client.service('market-categories').find({
      query: {
        customerId: props.customerId
      }
    }).then(res => {
      setCategories([{id: -1, category: 'all'}, ...res]);
    }).catch(error => {
      console.log(error);
    })
  }

  const filter = () => {
    let results = [], categoryResults = [];

    // Filter based on category select
    if (categoryFilter !== 'all') {
      items.forEach(item => {
        if (typeof item.categoryId !=='undefined' && item.categoryId) {
          //console.log(item.categoryId, categories.find(e => e.category.toLowerCase().trim().split(' ').join('') === categoryFilter));
          if (item.categoryId === categories.find(e => e.category.toLowerCase().trim().split(' ').join('') === categoryFilter).id)
            categoryResults.push(item);
        } else {
          console.log('issue with category loader');
        }
      })
    }

    // Filter based on search
    if (textFilter.length > 0) {
      let itemsorter = '';

      if (categoryResults.length > 0)
        results.push(categoryResults);

      items.forEach(item => {
        itemsorter = (item.title + item.category + item.sku + item.description).toLowerCase().trim().split(' ').join('');
  
        if (itemsorter.includes(textFilter))
          results.push(item);
      })
      
      setFilteredItems(results);
    } else if (categoryResults.length > 0) {
      setFilteredItems(categoryResults);
    } else {
      setFilteredItems(items);
    }
  }

  useEffect(() => {
    filter();
  }, [items, itemStats, textFilter, categoryFilter])

  const setFeatured = (id, value) => {
    let currentItem = {...items.find(e => e.id === id)};
    currentItem.featured = value;
    client.service('market-items').update(id, currentItem).then(res => {
      let tempItems = [...items];
      tempItems[tempItems.findIndex(e => e.id === id)] = res;
      setItems(tempItems);
    })
  }

  const setPromoted = (id, value) => {
    let currentItem = {...items.find(e => e.id === id)};
    currentItem.promoted = value;
    client.service('market-items').update(id, currentItem).then(res => {
      let tempItems = [...items];
      tempItems[tempItems.findIndex(e => e.id === id)] = res;
      setItems(tempItems);
    })
  }

  const renderItems = () => {
    let results = [];

    switch (sortStyle) {
      case 'alphaAsc':
        filteredItems.sort((a, b) => { return (a.title < b.title) ? -1 : 1});
        break;
      case 'alphaDesc':
        filteredItems.sort((a, b) => { return (a.title > b.title) ? -1 : 1});
        break;
      case 'oldest':
        filteredItems.sort((a, b) => { return (new Date(a.createdAt) < new Date(b.createdAt)) ? -1 : 1});
        break;
      case 'newest':
      default:
        filteredItems.sort((a, b) => { return (new Date(a.createdAt) > new Date(b.createdAt)) ? -1 : 1});
        break;
    }

    filteredItems.forEach(item => {
      results.push(<DisplayItem key={'it' + item.id}
      categories={categories}
      item={item}
      stats={itemStats.find(e => e.itemId === item.id)}
      setFeatured={setFeatured}
      setPromoted={setPromoted}
      remove={removeItem}
      select={(item) => {
        console.log('adminLink', props.adminLink);
        if (props.adminLink) {
          window.location.href = '/modules/market/item/?r=' + props.region + '&custid=' + props.customerId + '&item=' + item.id;
        } else
          history.push('/dashboard/marketplace/' + item.id);
      }} />)
    })

    return results;
  }

  const renderCategories = () => {
    let results = [];

    if (categories.length <= 0) return <option>No Categories</option>

    categories.forEach(category => {
      if (typeof category !== 'undefined' && category && typeof category.category !== 'undefined')
        results.push(<option key={'co_' + category.id} value={category.category.toLowerCase().trim().split(' ').join('')}>{(category.category === 'all') ? 'All Categories' : category.category}</option>)
    })

    return results;
  }

  const removeItem = (id) => {
    client.service('market-images').remove(null, {
      query: {
        itemId: id
      }
    }).catch(error => {
      console.log(error);
    });
    client.service('market-stats').remove(null, {
      query: {
        itemId: id
      }
    }).catch(error => {
      console.log(error);
    });
    client.service('market-options').remove(null, {
      query: {
        itemId: id
      }
    }).catch(error => {
      console.log(error);
    });
    client.service('market-variations').remove(null, {
      query: {
        itemId: id
      }
    }).catch(error => {
      console.log(error);
    });
    client.service('market-items').remove(id).then(res => {
      let itemsList = [...items];
      itemsList.splice(items.findIndex(e => e.id === res.id), 1)
      
      setItems(itemsList);
      setNewlyCreated(false);
    }).catch(error => {
      console.log(error);
    });
  }

  const create = () => {
    console.log('creating item', categories,
    'category', (categories.length > 1) ? categories[1] : null,
    'categoryId', (categories.length > 1) ? categories[1].id : null)
    client.service('market-items').create({
      customerId: props.customerId,
      title: 'New Item',
      newlyCreated: true,
      //category: (categories.length > 1) ? categories[1] : null,
      categoryId: (categories.length > 1) ? categories[1].id : null,
      variations: [
        {
          order: 0,
          variation: 'Regular'
        }
      ],
      images: [],
      options: []
    }).then(res => {
      console.log('item created', res)

      setNewlyCreated(true);
      if (props.adminLink) {
        window.location.href = '/modules/market/item/?r=' + props.region + '&custid=' + props.customerId + '&item=' + res.id;
      } else
        history.push('/dashboard/marketplace/' + res.id);
    }).catch(error => {
      console.error(error);
    })
  }

  return (
    <div className="a-market-wrapper">
      <div className="a-market-actions">
        <div className="a-market-filter-category">
          <select onChange={filterCategories}>
            {renderCategories()}
          </select>
        </div>
        <div className="a-market-filter-search"><input type="text" placeholder="Search by name, description, category or SKU" onChange={filterResults} /></div>
        <div className="a-market-filter-category a-market-filter-sort">
          <select onChange={(e) => setSortStyle(e.currentTarget.value)}>
            <option value="newest">Sort Options</option>
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
            <option value="alphaAsc">A -&gt; Z</option>
            <option value="alphaDesc">Z -&gt; A</option>
          </select>
        </div>
        {/*<div className="mcg-button market-button" onClick={convert}>Convert DB</div>*/}
        {(props.adminLink) ? <div className="mcg-button market-button" style={{marginLeft: 'auto'}} onClick={() => {
          window.location.href = '/modules/orders/?r=' + props.region + '&custid=' + props.customerId;
        }}>Orders</div> : ''}
        <CSVLink filename={"MCGItemExport.csv"}
          className="mcg-button market-button"
          data={csvItems}>
            Export
        </CSVLink>
        <div className="mcg-button market-button" onClick={create}>Create an item</div>
      </div>
      <table className="a-market-table">
        <thead className="a-market-table-header">
          <tr>
            <th className="a-market-table-featured"></th>
            <th className="a-market-table-title">Item</th>
            <th className={'a-market-table-sku' + ((screenWidth < 1000) ? ' market-hide' : '')}>SKU</th>
            <th className={'a-market-table-category' + ((screenWidth < 1200) ? ' market-hide' : '')}>Category</th>
            <th className={'a-market-table-stats' + ((screenWidth < 800) ? ' market-hide' : '')}>Reach</th>
            <th className={'a-market-table-stock' + ((screenWidth < 600) ? ' market-hide' : '')}>In Stock</th>
            <th className={'a-market-table-price' + ((screenWidth < 1000) ? ' market-hide' : '')}>Price</th>
            <th className="a-market-table-actions"></th>
          </tr>
        </thead>
        <tbody>
          {(items.length > 0) ? renderItems() : <tr><td></td></tr>}
        </tbody>
      </table>
      {((items.length <= 0)) ? <div className="a-market-no-items">You currently have no items.</div> : ''}
    </div>
  );
}

export default Marketplace;