import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import Moment from 'react-moment';

import client from '../../../../feathers';
import config from '../../../../config';

import './orders.css';

function OrderItem(props) {
  let { id } = useParams();
  //const [id, setId] = useState();
  const [order, setOrder] = useState({});
  const [company, setCompany] = useState({});
  const [customerId, setCustomerId] = useState(props.customerId);
  const [marketItems, setMarketItems] = useState([]);
  const [sendTrackingEmail, setSendTrackingEmail] = useState(true);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [provider, setProvider] = useState('CanadaPost');
  const [confirmationStatus, setConfirmationStatus] = useState('ready');

  useEffect(() => {
    if (props.adminLink)
      id = props.itemId;

    if (typeof props.company === 'undefined') {
      client.service('account').find({
        query: {
          customerId: customerId
        }
      }).then(res => {
        if (res.length > 0) {
          setCompany(res[0]);
        }
      })
    }

    loadOrder();
    loadItems();
  }, [])

  const loadOrder = () => {
    client.service('account-orders').find({
      query: {
        id: Number(id)
      }
    }).then(res => {
      console.log(res[0]);
      setOrder(res[0]);
    }).catch(error => {
      console.error(error);
    })
  }

  const loadItems = () => {
    client.service('market-items').find({
      query: {
        customerId: customerId
      }
    }).then(res => {
      setMarketItems(res);
    }).catch(error => {
      console.error(error);
    })
  }

  const renderItems = () => {
    if (typeof order.lineItems === 'undefined') return '';
    let results = [];

    order.lineItems.split(',').forEach(item => {
      if (item.length > 0) {
        let variationId = item.split(':')[0];
        let itemId = item.split(':')[1];
        let quantity = item.split(':')[2];
        let fullItem = {};
        marketItems.forEach(item => {
          if (item.id === parseInt(itemId)) {
            fullItem = {...item, variationId: variationId};
          }
        })

        results.push(
          <div key={itemId} data-id={itemId} className="ma-oi-line-item">
            {(typeof fullItem !== 'undefined' && typeof fullItem.images !== 'undefined') ? (
              <div className="ma-oi-line-item-img">
                {(typeof fullItem.images[0] !== 'undefined') ? <img src={"https://api.mcgmedia.net/assets/market/" + customerId + "/" + itemId + "/" + fullItem.images[0].url} alt={fullItem.title} /> : ''}
              </div>
            ) : ''}
            <div className="ma-oi-line-item-title">
              {fullItem.title}
              {(typeof fullItem.variations !== 'undefined') ? ' - ' + fullItem.variations.find(e => e.id === Number(fullItem.variationId)).variation : ''}
            </div>
            <div className="ma-oi-line-item-quantity">
              x{quantity}
            </div>
          </div>
        )
      }
    })

    return results;
  }

  const markOrderComplete = () => {
    // Send delivery/pickup email
    if (sendTrackingEmail) {
      client.service('store-settings').find({
        query: {
          customerid: customerId
        }
      }).then(res => {
        console.log(res);
        if (res.length > 0) {
          let logo = res[0].storeLogo;
          let storeLink = res[0].storeUrl;
          let storeEmail = res[0].storeEmail;
          let storeName = res[0].storeName;

          client.service('profile-styles').find({
            query: {
              customerId: customerId
            }
          }).then(res => {
            console.log(res);
            if (res.length > 0) {
              let colorSettings = res[0];

              switch(order.shippingType) {
                case 'shipping':
                  sendEmail(getShippingEmailHTML(logo, storeLink, colorSettings), storeEmail, storeName);
                  break;/*
                case 'pickup':
                  sendEmail(getPickupEmailHTML(logo, storeLink, colorSettings), storeEmail, storeName);
                  break;
                default:
                  sendEmail(getDeliveryEmailHTML(logo, storeLink, colorSettings), storeEmail, storeName);
                  break;*/
              }

              setOrder({...order, status: 'Complete'});
              client.service('account-orders').update(order.id, {
                ...order,
                status: 'Complete'
              }).then(res => {
                console.log(res);
              }).catch(error => console.log(error));
            }
          }).catch(error => console.log(error));
        }
      }).catch(error => console.log(error));
    } else {
      setOrder({...order, status: 'Complete'});
      client.service('account-orders').update(order.id, {
        ...order,
        status: 'Complete'
      }).then(res => {
        console.log('complete', res);
      }).catch(error => console.log(error));
    }
  }

  const sendEmail = (email, storeEmail, storeName) => {
    fetch(config.api + '/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        from: storeEmail,
        to: order.email.trim(),
        contact: order.name.trim(),
        subject: storeName + '' + ((order.shippingType === 'shipping') ? ' | Order Shipped' : (order.shippingType === 'delivery') ? ' | Order out for Delivery' : (order.shippingType === 'pickup') ? ' | Order Ready for Pickup' : ' | Order Complete'),
        message: 'You do not support HTML emails, we are contacting you to let you know your order is done processing and has either shipped or is ready for pick up, for more information enable html emails.',
        html: email
      })
    }).then(response => {
      if (response.ok)
        return response.json();

      throw response.json();
    }).then(data => {
			setTimeout(() => { setConfirmationStatus('success') }, 3000);
    }).catch(error => {
			setTimeout(() => { setConfirmationStatus('ready') }, 5000);
    })
  }

  const getDeliveryEmailHTML = (logo, storeLink, colorSettings) => {
    return (`<div>
    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
      <tr bgcolor="` + colorSettings.theme + `">
        <td style="color: ` + colorSettings.fontlight + `; padding: 40px 0px;">
          <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
            <td style="padding-left: 20px;">
              <a href="` + storeLink + `"><img src="` + logo + `" width="100px" /></a>
            </td>
            <td align="right" style="padding-right: 20px;">
              <p style="margin: 0;">ORDER ID:</p>
              <p style="margin: 0;">` + order.orderNumber + `</p>
            </td>
          </table>
        </td>
      </tr>
      <tr>
        <td style="margin: 0; padding: 40px 40px 0px 40px; font-size: 18px; color: #555555;" align="center">
          <p style="font-size: 42px;">Your order is out for delivery!</p>
        </td>
      </tr>
      <tr>
        <td style="margin: 0; padding: 40px 10px; border-top: 1px solid #dddddd;" align="center">
        <p style="margin: 0;"><a href="` + storeLink + `">` + company.name + `</a></p>
        </td>
      </tr>
    </table>
    </div>`);
  }

  const getPickupEmailHTML = (logo, storeLink, colorSettings) => {
    return (`<div>
    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
      <tr bgcolor="` + colorSettings.theme + `">
        <td style="color: ` + colorSettings.fontlight + `; padding: 40px 0px;">
          <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
            <td style="padding-left: 20px;">
              <a href="` + storeLink + `"><img src="` + logo + `" width="100px" /></a>
            </td>
            <td align="right" style="padding-right: 20px;">
              <p style="margin: 0;">ORDER ID:</p>
              <p style="margin: 0;">` + order.orderNumber + `</p>
            </td>
          </table>
        </td>
      </tr>
      <tr>
        <td style="margin: 0; padding: 40px 40px 0px 40px; font-size: 18px; color: #555555;" align="center">
          <p style="font-size: 42px;">Your order has ready for pickup!</p>
        </td>
      </tr>
      <tr>
        <td style="margin: 0; padding: 0 10px 40px 10px; font-size: 20px; color: #555555;" align="center">
          <p style="font-style: italic; margin-bottom: 40px;">You can pickup your order at:</p>
          <p style="margin: 20px 0 0 0;">` + company.address.street + `</p>
          <p style="margin: 0;">` + company.address.city + `` + company.address.province + `</p>
          <p style="margin: 0;">` + company.address.postalCode + `</p>
        </td>
      </tr>
      <tr>
        <td style="margin: 0; padding: 40px 10px; border-top: 1px solid #dddddd;" align="center">
        <p style="margin: 0;"><a href="` + storeLink + `">` + company.name + `</a></p>
        </td>
      </tr>
    </table>
    </div>`);
  }

  const getShippingEmailHTML = (logo, storeLink, colorSettings) => {
    let trackingLink = '';
    if (provider === 'CanadaPost') trackingLink = 'https://www.canadapost.ca/trackweb/en#/search?searchFor=' + trackingNumber;
    if (provider === 'UPS') trackingLink = 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + trackingNumber; 
    if (provider === 'Fedex') trackingLink = 'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=' + trackingNumber; 
    if (provider === 'Purolator') trackingLink = 'https://www.purolator.com/en/shipping/tracker?pins=' + trackingNumber;

    let results = `<div>
      <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
        <tr bgcolor="` + colorSettings.theme + `">
          <td style="color: ` + colorSettings.fontlight + `; padding: 40px 0px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
              <td style="padding-left: 20px;">
                <a href="` + storeLink + `"><img src="` + logo + `" width="100px" /></a>
              </td>
              <td align="right" style="padding-right: 20px;">
                <p style="margin: 0;">ORDER ID:</p>
                <p style="margin: 0;">` + order.orderNumber + `</p>
              </td>
            </table>
          </td>
        </tr>`;

    if (trackingNumber.length > 0) {
      results += `<tr>
          <td style="margin: 0; padding: 40px 40px 100px 40px; font-size: 18px; color: #555555;" align="center">
            <p style="font-size: 42px;">Your order has shipped!</p> 
            <p style="font-size: 20px; margin-bottom: 0;">Your tracking number is:</p>
            <p style="font-size: 36px; margin-top: 0; margin-bottom: 0;">` + trackingNumber + `</p>
            <p style="font-size: 16px; margin-top: 0; margin-bottom: 60px;">` + provider + `</p>
            <a name="trackinglink" target="_blank" href="` + trackingLink + `" style="font-size: 20px; width: 150px; margin: 20px 0; padding: 15px 40px; text-decoration: none; background-color: #1f2e45; color: #ffffff; border-radius: 5px;">Track Package</a>
          </td>
        </tr>`
    }
    
    results += `<tr>
          <td style="margin: 0; padding: 40px 10px; border-top: 1px solid #dddddd;" align="center">
            <p style="margin: 0;"><a href="` + storeLink + `">` + company.name + `</a></p>
          </td>
        </tr>
      </table>
    </div>`;

    return results;
  }

  return (
    <div className="ma-oi-order">
      <div className="ma-oi-order-number">Order # {order.orderNumber}</div>
      <div className="ma-oi-order-time">Ordered <Moment fromNow date={order.createdAt} /></div>
      <div className="ma-oi-order-status">Status: {order.status}</div>
      <div className="ma-oi-order-wrapper">
        <div className="ma-oi-items ma-oi-group">
          <div className="ma-oi-section">
            <h3>Order Notes</h3>
          </div>
          <div className="ma-oi-section">
            <div className="ma-oi-section-notes">{order.notes}</div>
          </div>
          <div className="ma-oi-section">
            <h3>Order Details</h3>
          </div>
          <div className="ma-oi-section">
            <div>{renderItems()}</div>
            <div className="ma-oi-total-wrapper">
              <div className="ma-oi-total-label">Total Order Value:</div>
              <div className="ma-oi-total-value">${(order.total * .01).toFixed(2)}</div>
            </div>
          </div>
          {(order.status === 'paid') ? (
            <div className="ma-oi-tracking-group">
              {(order.shippingType === 'shipping') ? (<div className="ma-oi-tracking">
                <div className="ma-oi-tracking-label">Tracking Number</div>
                <div className="ma-oi-tracking-provider">
                  <select onChange={(e) => { setProvider(e.currentTarget.value) }} value={provider}>
                    <option value="CanadaPost">Canada Post</option>
                    <option value="UPS">UPS</option>
                    <option value="Fedex">Fedex</option>
                    <option value="Purolator">Purolator</option>
                  </select>
                </div>
                <div className="ma-oi-tracking-input">
                  <input type="text" onChange={(e) => { setTrackingNumber(e.currentTarget.value) }} placeholder="EE 999 999 999 CA" value={trackingNumber} />
                </div>
                <div className="ma-oi-tracking-toggle">
                  <div>Send Email?</div>
                  <Switch checked={sendTrackingEmail}
                    name="sendTrackingEmail"
                    onChange={(value) => { setSendTrackingEmail(value) }}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={14}
                    width={36} />
                </div>
              </div>) : '' }
              <div className="mcg-button market-button" onClick={markOrderComplete}>
              {(order.shippingType === 'shipping') ? 'Mark Order as Shipped' : 'Complete Order' }
              </div>
            </div>
          ) : ''}
        </div>
        <div className="ma-oi-customer ma-oi-group">
          <h3>Customer</h3>
          <div className="ma-oi-section">
              <h4>Contact Details</h4>
              <div>{order.name}</div>
              <div>{order.email}</div>
              <div>{order.phone}</div>
          </div>
          {(order.shippingType === 'shipping' || order.shippingType === 'delivery') ? (
            <div className="ma-oi-section">
              <h4>Shipping Address</h4>
              <div>{order.recipient}</div>
              <div>{order.address}</div>
              <div>{order.city} {order.province}</div>
              <div>{order.country} {order.postalcode}</div>
            </div>
          ) : (order.shippingType === 'pickup') ? (
            <div className="ma-oi-section">
              <h4>Pickup Order</h4>
              <div>Order for {order.name}</div>
            </div>
          ) : ''}
        </div>
      </div>
    </div>
  )
}

export default OrderItem;