import React, { Component } from 'react';
import OptionsManager from '../../../components/OptionsManager/optionsmanager';
import PaymentCalculatorOptions from '../../../components/PaymentCalculatorOptions/paymentcalculatoroptions';
import AppOptions from '../../../components/AppOptions/appoptions';
import StoreSettings from '../../../components/StoreSettings/StoreSettings';

class Options extends Component {
  render() {
    return (
      <div>
        <OptionsManager id={this.props.id} />
        <PaymentCalculatorOptions id={this.props.id} />
        <StoreSettings id={this.props.id} />
        <AppOptions id={this.props.id} />
      </div>
    );
  }
}

export default Options;