import React, { Component } from 'react';
import config from '../../config';
import FormField from '../../components/formfield';
import StatusNotice from '../../components/statusnotice';
import '../../styles/front-end/contact.css';
import client from '../../feathers';

const dayOrder = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

class Contact extends Component {
	constructor(props) {
		super(props);

		this.state = {
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      message: '',
      status: '',
      noticeMessage: '',
      hours: []
    };

    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);

    this.loadHours();
  }

  render() {
    if (typeof this.props.company.address === 'undefined') return '';
    
    return (
      <section key='mcg-contact' id={this.props.block}>
        {(this.props.title.length > 0) ? <h2 className="animation fadeIn">{this.props.title}</h2> : ''}
        <div className="contact-container">
          <div className="contact-details">
            <div className="c-call">
              <p>Email, call or visit us, we are more than happy to hear from you.</p>
            </div>
            {this.getHours()}
            <div className="c-details">
              <div>{this.props.company.address.street}</div>
              <div>{this.props.company.address.city + ' ' + this.props.company.address.province + ' ' + this.props.company.address.postalCode}</div>
              {(this.props.company.contact.phone) ? (
                <div>
                  PHONE <a href={'tel:' + this.props.company.contact.phone}>{this.props.company.contact.phone}</a>
                  {(this.props.company.contact.tollfree) ? ' | ' : ''}
                  {(this.props.company.contact.tollfree) ? (
                    <a href={'tel:' + this.props.company.contact.tollfree}>{this.props.company.contact.tollfree}</a>
                  ) : ''}
                </div> 
              ): ''}
              {(this.props.company.contact.fax) ? <div>FAX {this.props.company.contact.fax}</div> : ''}
              {(this.props.company.contact.email) ? <div><a href={'mailto:' + this.props.company.contact.email}>{this.props.company.contact.email}</a></div> : ''}
            </div>
          </div>
          <div className="contact-form">
            <div className="form-field-grouping">
              <FormField name="firstName" label="First Name" value={this.state.firstName} onChange={this.onChange} required={true} />
              <FormField name="lastName" label="Last Name" value={this.state.lastName} onChange={this.onChange} />
            </div>
            <div className="form-field-grouping">
              <FormField name="email" label="Email" value={this.state.email} onChange={this.onChange} required={true} />
            </div>
            <div className="form-field-grouping">
              <FormField name="subject" label="Subject" value={this.state.subject} onChange={this.onChange} />
            </div>
            <div className="form-message form-field-grouping">
              <FormField name="message" label="Message" value={this.state.message} onChange={this.onChange} textarea={true} required={true} height="6" />
            </div>
            <div>
              <input type="button" className="form-element form-button" value="Get in Touch" onClick={this.submitForm} />
            </div>
          </div>
        </div>
        
        <StatusNotice status={this.state.status} message={this.state.noticeMessage} />
      </section>
    );
  }

  onChange(event) {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  getHours() {
    if (!this.props.options.showHours) return '';

    let results = [];
    let isOpened = false;
    
    for (let i = 0; i < this.state.hours.length; i++) {
      const hours = this.state.hours[i];
      if (hours.time !== 'Closed')
        isOpened = true;
      results.push(<div key={'hourdisplay'+i}>{hours.name} {hours.time}</div>);
    }

    if (!isOpened) return <></>;

    return (<div className="c-hours">{results}</div>)
  }

  loadHours() {
    if (typeof this.props.company === 'undefined') return;

    client.service('profile-hours').find({
      query: {
        customerId: this.props.company.id
      }
    }).then(res => {
      let hourgroup = [];
      let results = [];

      let sortedRes = [];
      let tempDay;
      dayOrder.forEach(day => {
        tempDay = res.find(e => e.day === day);
        if (tempDay)
        sortedRes.push(tempDay);
      });

      sortedRes.forEach(day => {
        if(day.customerId === this.props.company.id) {
          if (hourgroup.length > 0) {
            let lastDay = hourgroup[hourgroup.length-1];
            if ((lastDay.open === day.open.substring(0,5) && lastDay.close === day.close.substring(0,5) && lastDay.isOpen === day.isOpen && lastDay.byAppointment === day.byAppointment) || (lastDay.isOpen === false && day.isOpen === false) || (lastDay.byAppointment === true && day.byAppointment === true)) {
              hourgroup[hourgroup.length-1].days.push(day.day);
            } else {
              hourgroup.push({
                days: [day.day],
                open: day.open.substring(0,5),
                close: day.close.substring(0,5),
                isOpen: day.isOpen,
                byAppointment: day.byAppointment
              });
            }
          } else {
            hourgroup.push({
              days: [day.day],
              open: day.open.substring(0,5),
              close: day.close.substring(0,5),
              isOpen: day.isOpen,
              byAppointment: day.byAppointment
            });
          }
        }
      });

      //console.log('hourgroup', hourgroup);

      let sortedDays = [];
      hourgroup.forEach(hourSlot => {
        // organize the hourslot days here before for correct output
        sortedDays = [];
        dayOrder.forEach(day => {
          if (hourSlot.days.includes(day))
            sortedDays.push(day);
        });
        hourSlot.days = sortedDays;

        let hour = parseInt(hourSlot.open.substring(0,2));
        let pm = false;
        if (hour > 12) {
          hour = hour - 12;
          pm = true;
        }

        let open = hour.toString() + ((hourSlot.open.substring(3,5) !== '00') ? ':' + hourSlot.open.substring(3,5) : '') + ((pm) ? 'PM' : 'AM');
        pm = false;
        hour = parseInt(hourSlot.close.substring(0,2));
        if (hour > 12) {
          hour = hour - 12;
          pm = true;
        }
        let close = hour.toString() + ((hourSlot.close.substring(3,5) !== '00') ? ':' + hourSlot.close.substring(3,5) : '') + ((pm) ? 'PM' : 'AM');
        let time = open + '-' + close;
        let name = hourSlot.days[0].substring(0,3) + '-' + hourSlot.days[hourSlot.days.length-1].substring(0,3) + ':';

        if (hourSlot.byAppointment)
          time = 'By Appointment';
          
        if (!hourSlot.isOpen)
          time = 'Closed';

        results.push({
          name: (hourSlot.days.length > 1) ? name : (hourSlot.days[0] + ':'),
          time: time
        })
      })
        
      this.setState({hours: results});
    })
  }

  submitForm() {
    if (this.state.firstName.length <= 0) {
      this.setState({
        status: 'failed',
        noticeMessage: 'Error: Please provide your first name.'
      });
      return;
    }
    if (this.state.email.length <= 0 || !(this.state.email.includes('@') && this.state.email.includes('.'))) {
      this.setState({
        status: 'failed',
        noticeMessage: 'Error: Please provide a valid email address.'
      });
      setTimeout(() => { this.removeNotice() }, 5000);
      return;
    } 
    if (this.state.message.length <= 0) {
      this.setState({
        status: 'failed',
        noticeMessage: 'Error: Please include a message to send.'
      });
      setTimeout(() => { this.removeNotice() }, 5000);
      return;
    }

    // Send message here

    this.setState({
      status: 'waiting',
      noticeMessage: 'Sending message.'
    });

    /*
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
    */
   console.log('sending email');

    fetch(config.api + '/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        from: this.state.email,
        to: this.props.company.contact.email,
        contact: (this.state.firstName + ' ' + this.state.lastName).trim(),
        subject: this.state.subject,
        message: this.state.message
      })
    }).then(response => {
      console.log('response',response);

      if (response.ok)
        return response.json();

      throw response.json();
    }).then(data => {
      console.log('data',data);
      
      this.setState({
        status: 'success',
        noticeMessage: 'Message sent successfully!'
      });

      setTimeout(() => { this.removeNotice() }, 5000);
    }).catch(error => {
      console.log('Failed server response.', error);
      this.setState({
        status: 'failed',
        noticeMessage: 'Error: There was an issue with the server response.'
      });

			setTimeout(() => { this.removeNotice() }, 5000);
    })
  }

	removeNotice() {
		this.setState({
			status: '',
			noticeMessage: ''
		});
	}
}

export default Contact;