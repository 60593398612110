import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

class StyleColor extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="design-style-container">
        <div className="design-style-box" onClick={this.props.onSelected}>
          <div className="design-style-color" style={{backgroundColor: this.props.color}}></div>
        </div>
        <div className={(this.props.selected === this.props.name) ? 'design-style-selector show' : 'design-style-selector'}>
          <SketchPicker color={this.props.color} onChangeComplete={(color) => this.props.onChange(this.props.name, color)} disableAlpha={true} />
        </div>
      </div>
    );
  }
}

export default StyleColor;