import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import '../../styles/front-end/navigation.css';

class Navigation extends Component {
	constructor(props) {
		super(props);

		this.state = {
      show: this.props.show,
      links: this.props.links
    };
  }
  
  render() {
    return (
      <div className={'nav-container' + ((this.state.show) ? ' nav-show' : '')}>
        <div className="nav-header">
          <div className="nav-header-label">Navigation</div>
          <div className="nav-header-button" onClick={this.props.toggleNav}><i className="far fa-times"></i></div>
        </div>
        <ul className="nav-list">
          {this.renderLinks()}
        </ul>
      </div>
    );
  }

  renderLinks() {
    if (typeof this.state.links === 'undefined')
      return '';
      
    let results = [];
    for (let index = 0; index < this.state.links.length; index++) {
      const element = this.state.links[index];
      results.push(
        <li key={'navi-' + index} style={{"--animation-order": index}} className={(this.state.show) ? 'navi-link-effect fadeInUp' : ''}>
          {(element.link.substring(0,1) === '#') ? (
            <HashLink smooth to={'/' + element.link}>{element.label}</HashLink>
          ) : (
            <a href={element.link}>{element.label}</a>
          )}
        </li>
      );
    }

    return results;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show)
      this.setState({show: this.props.show});

    if (prevProps.links !== this.props.links)
      this.setState({links: this.props.links});
  }
}

export default Navigation;