import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import client from '../../../feathers';
import Select from 'react-select';
import StyleColor from '../../../components/stylecolor';
import './design.css';

class Design extends Component {
  constructor(props) {
    super(props);

    this.themeChange = this.themeChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onColorChange = this.onColorChange.bind(this);
    this.renderColors = this.renderColors.bind(this);
    
    this.loadStyles();
  }

  render() {
    if (typeof this.state === 'undefined' || !this.state) return '';
    return (
      <div>
        {/*<div className="dash-stat-block">
          <h3>Theme Selector</h3>
          <Select options={themeOptions} onChange={this.themeChange} />
        </div>*/}
        <div className="dash-stat-block">
          <h3>Theme Colors</h3>
          <div className="design-color-list">
          {this.renderColors()}
          </div>
        </div>
        <div className="dash-stat-block">
          <h3>Custom Styles</h3>
          <ReactQuill value={this.state.customStyles} onChange={(value) => { this.setState({'customStyles': value})}} onBlur={() => this.save('customStyles')} />
        </div>
      </div>
    );
  }

  renderColors() {
    if (typeof this.state === 'undefined' || !this.state) return [];
    let results = [];

    Object.keys(this.state).forEach((key,index) => {
      switch(key) {
        case 'themeType':
        case 'customStyles':
        case 'customerId':
        case 'id':
        case 'selected':
          break;
        default :
          results.push(
            <div className='mcg-details-item'>
              <div className='mcg-details-descriptor'>{titleCase(key.replace('dark', ' dark').replace('light', ' light').replace('fade', ' fade').replace('sub', ' sub').replace('nav', ' nav').replace('header', ' header').replace('hover', ' fade').replace('footer', ' footer').replace('high light', 'highlight'))}</div>
              <div className='mcg-details-input'>
                <StyleColor name={key} color={this.state[key]} onChange={this.onColorChange} selected={this.state.selected} onSelected={() => this.onSelected(key)} />
              </div>
            </div>
          )
          break;
      }
    })

    return results;
  }

  onSelected(value) {
    if (this.state.selected === value)
      this.setState({selected: ''})
    else
      this.setState({selected: value})
  }

  onColorChange(name, color) {
    console.log('onChange', name, color.hex);
    this.setState({
      [name]: color.hex
    }, () => this.save(name))
  }


  save(selector) {
    console.log('saving', selector, this.state[selector]);
    client.service('profile-styles').patch(
      this.state.id,
      {
        [selector]: this.state[selector]
      }
    ).then(res => {
      console.log('saved ' + selector, res);
    }).catch(err => {
      console.error(err);
    })
  }

  themeChange(theme) {
    console.log('implement the theme selector');
  }
  
  onChange(event) {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    }, () => this.save(event.currentTarget.name));
  }

  loadStyles() {
    client.service('profile-styles').find({
      query: {
        customerId: this.props.id
      }
    }).then(res => {
      if (res.length > 0) {
        this.setState({
          ...res[0]
        });
      } else {
        client.service('profile-styles').create({
          customerId: this.props.id
        }).then(res => {
          this.setState({
            ...res[0]
          });
        })
      }
    })
  }
}

function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' '); 
}

export default Design;