import React, { Component } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import config from '../config';
import 'react-awesome-slider/dist/styles.css';
import '../styles/front-end/slider.css';

class Slider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: this.props.show
    };
  }

  render() {
    return (
      <div className={(this.state.show) ? 'slider-container slider-show' : 'slider-container'}>
        <div className="slider-close" onClick={this.props.toggleGallery}><i className="fal fa-times"></i></div>
        <AwesomeSlider className="aws-styles slider-limit-height" bullets={false} fillParent={false}>
          {this.props.images.map((image) => <div key={image.name} data-src={config.api + '/assets/' + this.props.region + '/' + this.props.id + '/profile/' + image.name}/> )}
        </AwesomeSlider>
      </div>
    );
  }

  static getDerivedStateFromProps (props, state) {
    state.show = props.show;

    return state;
  }
}

export default Slider;