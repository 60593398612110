import React, { useState, useEffect } from 'react';
import Gallery from 'react-grid-gallery';
import config from '../../config';
import '../../styles/front-end/listgallery.css'

function ListGallery(props) {
  let [images, setImages] = useState([]);

  useEffect(() => {
    //console.log('props.images', props.images);

    if (typeof props.images === 'undefined' || props.images.length <= 0) return;

    let results = [];
    props.images.forEach(item => {
      results.push({
        src: config.api + '/assets/' + props.region + '/' + props.id + '/profile/' + item.name,
        thumbnail: config.api + '/assets/' + props.region + '/' + props.id + '/profile/' + item.name,
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        caption: item.name
      });
    })

    setImages(results);
  }, [props.images]);

  return (
    <section id={props.block}>
      {(props.title.length > 0) ? <h2 className="animation fadeIn">{props.title}</h2> : ''}
      <div className="mcg-articles mcg-list-gallery animation fadeIn">
        <Gallery images={images} enableImageSelection={false} />
      </div>
    </section>
  );
}

export default ListGallery;