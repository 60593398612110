import React, { Component } from 'react';
import { convertToCurrency, track } from '../../../../utility';

class Item extends Component {
  
  render() {
    let image = this.props.item.images.sort((a,b) => { return a.isPrimary })[0];
    return (
      <div className="auto-item" onClick={() => {console.log('Track automotive item visit'); track(this.props.company.id, 'automotiveItem'); window.location.href = '/automotive/' + this.props.item.id}}>
        <div className="auto-item-image">
          <img src={(typeof image !== 'undefined' && image) ? 'https://images.cwportals.com/automotive/' + this.props.company.region + '/' + this.props.company.id + '/' + image.itemId + '/' + image.image + '.jpg' : 'https://images.cwportals.com/nopic.jpg'} alt={this.props.item.year + ' ' + this.props.item.make + ' ' + this.props.item.model} />
        </div>
        <div className="auto-item-area">
          <div className="auto-item-title">
            {this.props.item.year} {this.props.item.make} {this.props.item.model}
          </div>
          <div className="auto-item-details">
            <div className={(this.props.item.mileage == null) ? 'hide' : ''}>
              Mileage <span className="auto-list-detail">{this.props.item.mileage}</span>
            </div>
            <div className={(this.props.item.color == null) ? 'hide' : ''}>
              Color <span className="auto-list-detail">{this.props.item.color}</span>
            </div>
            <div className={(this.props.item.transmission == null) ? 'hide' : ''}>
              Transmission <span className="auto-list-detail">{this.props.item.transmission}</span>
            </div>
          </div>
        </div>
        <div className="auto-item-price">
          {this.renderPrice()}
        </div>
      </div>
    );
  }

  renderPrice() {
    let item = this.props.item;
    let tag = '';
    let price = 0;

    if (typeof item.paymentType !== 'undefined' && item.paymentType) {
      price = this.props.item.altPrice;
      tag = ' ' + item.paymentType;
    } else if (typeof item.specialPrice !== 'undefined' && item.specialPrice) {
      price = this.props.item.specialPrice;
    } else {
      price = this.props.item.price;
    }

    if (price && price > 0)
      return convertToCurrency(price) + tag;
    else
      return 'Call for Price';
  }
}

export default Item;