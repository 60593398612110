import React, {Component} from 'react';
import {Route} from "react-router-dom";
import Admin from './containers/dashboard/admin';
import client from './feathers';
import { parseFacebookUser } from './utility';
import './app.css';


class AdminApp extends Component {
	constructor(props) {
		super(props);

		this.state = {
      id: 0,
      company: {}
    };

    this.loadData();
  }

  render() {
    return (
      <Admin {...this.props} id={this.state.id} company={this.state.company} />
    );
  }

  // Test Company: 12298, Homes with Jessie: 7343, Pub 981: 2518, Jimmy MacKinley: 16154
  loadData() {
    client.service('account').find({
      query: {
        'int_id': this.props.id
      }
    }).then(res => {
      console.log('company fetch',res);
      if (res.length > 0) {
        let company = this.mapData(res[0]);
        console.log('company',company);
        if (typeof company.region === 'undefined' || !company.region) {
          console.log('updating region');
          if (this.props.region) {
            client.service('account').patch(
              this.props.id,
              {
                regionId: this.props.region
              }
            ).then(res => {
              console.log(res);
            })
            company.region = this.props.region;
          } else {
            console.error('No Region');
          }
        }

        this.setState({
          id: company.id,
          company: company
        })
      }
    }).catch(error => {
      console.error('Failed to retrieve company.', error);
    })
  }

  mapData(data) {
    if (typeof data === 'undefined')
      return;

    let company = {
      id: data.int_id,
      status: data.int_status_id,
      region: data.regionId,
      name: data.str_company_name,
      address: {
        street: data.str_address1 + ' ' + data.str_address2,
        city: data.str_city,
        province: data.str_province,
        postalCode: data.str_pcode,
        country: data.str_country,
      },
      contact: {
        contact: data.str_contact_name,
        phone: data.str_phone,
        tollfree: data.str_tollfree,
        mobile: data.str_mobile,
        fax: data.str_fax,
        email: data.str_email
      },
      social: {
        instagram: 'mightymiramichi',
        facebook: parseFacebookUser(data.str_facebook),
        twitter: data.str_twitter_id,
        //youtube: 'mightymiramichi',
        linkedin: 'mightymiramichi'
      },
      profile: {
        logo: 'https://api.mcgmedia.net/assets/' + data.regionId + '/' + data.int_id + '/' + data.str_logo_image,
        header: 'https://api.mcgmedia.net/assets/' + data.regionId + '/' + data.int_id + '/profile/' + data.str_header_image ,//'https://mcgmedia.net/wp-content/uploads/2018/07/website.jpg',
        subtitle: data.str_title,
        about: data.str_description,
        contentTitle: data.str_content_area_title,
        contentArea: data.str_content_area_content,
        services: (typeof data.str_services !== 'undefined' && data.str_services) ? data.str_services.split('\n') : [],
        files: [],
        gallery: [],
        videos: []
      },
      links: [],
      options: {
        showHours: true,
        facebookLimit: 5,
        noticeLimit: 3,
        dataRefresh: 6
      },
      blocks: [
        {
          name: 'about',
          title: 'A little about us',
          order: 0,
          show: true
        },
        {
          name: 'featured',
          title: 'Featured Items',
          order: 1,
          show: true
        },
        {
          name: 'facebook',
          title: 'From our Facebook',
          order: 2,
          show: true
        },
        {
          name: 'notices',
          title: 'Recent Happenings',
          order: 3,
          show: true
        },
        {
          name: 'gallery',
          title: 'From our Gallery',
          order: 4,
          show: true
        },
        {
          name: 'videos',
          title: 'Videos',
          order: 5,
          show: true
        },
        {
          name: 'files',
          title: 'Downloads',
          order: 6,
          show: true
        },
        {
          name: 'services',
          title: 'A bit of what we do',
          order: 7,
          show: true
        },
        {
          name: 'content',
          title: 'Content Area',
          order: 8,
          show: true
        },
        {
          name: 'contact',
          title: '',
          order: 9,
          show: true
        },
        {
          name: 'map',
          title: '',
          order: 10,
          show: true
        }
      ]
    }
    
    return company;
  }
}

export default AdminApp;
