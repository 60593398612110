import React, { Component } from 'react';

class Item extends Component {
  render() {
    return (
      <div className="restaurant-item">
        {(this.props.item.hasImage) ? <div className="restaurant-image"><img src={'https://images.cwportals.com/restaurant/' + this.props.company.region + '/' + this.props.company.id + '/' + this.props.item.id + '_thumb.jpg'} alt={this.props.item.name} /></div> : ''}
        <div className="restaurant-info-container">
          <div className="restaurant-info">
            <div className="restaurant-name">{this.props.item.name}</div>
            <div className="restaurant-cost">{this.props.item.cost}</div>
          </div>
          <div className="restaurant-description">{this.props.item.description}</div>
        </div>
      </div>
    );
  }
}

export default Item;