import React, { Component } from 'react';
import {Switch, NavLink, Link} from "react-router-dom";
import PrivateRoute from './privateroute';
import Stats from './routes/stats';
import Details from './routes/details';
import Media from './routes/media';
import Content from './routes/content';
import Options from './routes/options';
import Design from './routes/design';
import '../../styles/dashboard/dashboard.css'
import Marketplace from './page/marketplace/marketplace';
import Orders from './page/orders/orders';
import OrderItem from './page/orders/orderItem';
import CreateItem from './page/marketplace/CreateItem';

class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
      show: false
    }

    this.toggleNav = this.toggleNav.bind(this);
  }
  render() {
    return (
      <div>
        <div className="dash-header">
          <div className="dash-logo"><img src="https://mcgmedia.net/wp-content/uploads/2017/07/mcglogowebdark.png" alt='mcg media dashboard area' /></div>
          <div className="dash-welcome">
            Welcome <Link to='/dashboard/account'>{(typeof this.props.company.contact.contact === 'undefined') ? 'Back' : this.props.company.contact.contact}</Link>
            <div className="dash-nav-button" onClick={this.toggleNav}>
              <i className="far fa-bars"></i>
            </div>
          </div>
        </div>
        <div className="dash-content-wrapper">
          <div className={(this.state.show) ? 'dash-nav-mobile nav-show' : 'dash-nav-mobile'}>
            <div className="dash-nav-mobile-button" onClick={this.toggleNav}>
              <i className="far fa-times"></i>
            </div>
            
            <a href="/" className="dash-nav-link dash-site-preview"><i className="far fa-external-link"></i> Preview Site</a>

            <h3>Navigation</h3>
            <NavLink to="/dashboard" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="fas fa-analytics"></i> Dashboard</NavLink>
            <NavLink to="/dashboard/details" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="far fa-edit"></i> Details</NavLink>
            <NavLink to="/dashboard/media" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="far fa-photo-video"></i> Media</NavLink>
            <NavLink to="/dashboard/content" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="fas fa-text-size"></i> Content</NavLink>
            <NavLink to="/dashboard/options" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="fal fa-sliders-v"></i> Settings</NavLink>
            <NavLink to="/dashboard/design" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="far fa-paint-roller"></i> Design</NavLink>

            <h3>Applications</h3>
            <NavLink to="/dashboard/marketplace" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="far fa-inventory"></i> Products</NavLink>
            <NavLink to="/dashboard/orders" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="far fa-box"></i> Orders</NavLink>
{/*         <NavLink to="/dashboard/events" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="far fa-calendar-edit"></i> Events</NavLink>
            <NavLink to="/dashboard/automotive" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="fas fa-car"></i> Automotive</NavLink>
            <NavLink to="/dashboard/funeral" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="far fa-clouds"></i> Funeral</NavLink>
            <NavLink to="/dashboard/property" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="fas fa-home-lg"></i> Real Estate</NavLink>
            <NavLink to="/dashboard/recreational" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="fas fa-rv"></i> Recreational</NavLink>
            <NavLink to="/dashboard/Specials" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="fas fa-tags"></i> Specials</NavLink>
*/}
            <h3>Account</h3>
            <NavLink to="/dashboard/account" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="far fa-user"></i> Account</NavLink>
            <NavLink to="/dashboard/support" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="fas fa-question"></i> Support</NavLink>
          </div>
          <div className="dash-nav">
            <a href="/" className="dash-nav-link dash-site-preview"><i className="far fa-external-link"></i> Preview Site</a>
            <h3>Navigation</h3>
            <NavLink to="/dashboard" className="dash-nav-link" activeClassName="dash-active"><i className="fas fa-analytics"></i> Dashboard</NavLink>
            <NavLink to="/dashboard/details" className="dash-nav-link" activeClassName="dash-active"><i className="far fa-edit"></i> Details</NavLink>
            <NavLink to="/dashboard/media" className="dash-nav-link" activeClassName="dash-active"><i className="far fa-photo-video"></i> Media</NavLink>
            <NavLink to="/dashboard/content" className="dash-nav-link" activeClassName="dash-active"><i className="fas fa-text-size"></i> Content</NavLink>
            <NavLink to="/dashboard/options" className="dash-nav-link" activeClassName="dash-active"><i className="fal fa-sliders-v"></i> Settings</NavLink>
            <NavLink to="/dashboard/design" className="dash-nav-link" activeClassName="dash-active"><i className="far fa-paint-roller"></i> Design</NavLink>

            <h3>Applications</h3>
            <NavLink to="/dashboard/marketplace" className="dash-nav-link" activeClassName="dash-active"><i className="far fa-inventory"></i> Products</NavLink>
            <NavLink to="/dashboard/orders" className="dash-nav-link" onClick={this.toggleNav} activeClassName="dash-active"><i className="far fa-box"></i> Orders</NavLink>
{/*         <NavLink to="/dashboard/events" className="dash-nav-link" activeClassName="dash-active"><i className="far fa-calendar-edit"></i> Events</NavLink>
            <NavLink to="/dashboard/automotive" className="dash-nav-link" activeClassName="dash-active"><i className="fas fa-car"></i> Automotive</NavLink>
            <NavLink to="/dashboard/funeral" className="dash-nav-link" activeClassName="dash-active"><i className="far fa-clouds"></i> Funeral</NavLink>
            <NavLink to="/dashboard/property" className="dash-nav-link" activeClassName="dash-active"><i className="fas fa-home-lg"></i> Real Estate</NavLink>
            <NavLink to="/dashboard/recreational" className="dash-nav-link" activeClassName="dash-active"><i className="fas fa-rv"></i> Recreational</NavLink>
            <NavLink to="/dashboard/Specials" className="dash-nav-link" activeClassName="dash-active"><i className="fas fa-tags"></i> Specials</NavLink>
*/}
            <h3>Account</h3>
            <NavLink to="/dashboard/account" className="dash-nav-link" activeClassName="dash-active"><i className="far fa-user"></i> Account</NavLink>
            <a href="https://mcgmedia.net/login/" target="_blank" className="dash-nav-link" activeClassName="dash-active"><i className="fas fa-question"></i> Support</a>
          </div>
          <div className="dash-content">
            <Switch>
              <PrivateRoute path="/dashboard/account" auth={this.props.auth}>
                Account
              </PrivateRoute>
              <PrivateRoute path="/dashboard/details" auth={this.props.auth}>
                <Details id={this.props.id} />
              </PrivateRoute>
              <PrivateRoute path="/dashboard/media" auth={this.props.auth}>
                <Media id={this.props.id} region={this.props.company.region} />
              </PrivateRoute>
              <PrivateRoute path="/dashboard/content" auth={this.props.auth}>
                <Content id={this.props.id} region={this.props.company.region} />
              </PrivateRoute>
              <PrivateRoute path="/dashboard/options" auth={this.props.auth}>
                <Options id={this.props.id} />
              </PrivateRoute>
              <PrivateRoute path="/dashboard/design" auth={this.props.auth}>
                <Design id={this.props.id} />
              </PrivateRoute>
              <PrivateRoute path="/dashboard/automotive" auth={this.props.auth}>
              automotive
              </PrivateRoute>
              <PrivateRoute path="/dashboard/funeral" auth={this.props.auth}>
              funeral
              </PrivateRoute>
              <PrivateRoute path="/dashboard/marketplace/:id" auth={this.props.auth}>
                <CreateItem customerId={this.props.id} region={this.props.company.region} />
              </PrivateRoute>
              <PrivateRoute path="/dashboard/marketplace" auth={this.props.auth}>
                <Marketplace customerId={this.props.id} region={this.props.company.region} />
              </PrivateRoute>
              <PrivateRoute path="/dashboard/orders/:id" auth={this.props.auth} >
                <OrderItem customerId={this.props.id} region={this.props.company.region} company={this.props.company} />
              </PrivateRoute>
              <PrivateRoute path="/dashboard/orders" auth={this.props.auth}>
                <Orders customerId={this.props.id} region={this.props.company.region} />
              </PrivateRoute>
              <PrivateRoute path="/dashboard/property" auth={this.props.auth}>
              real estate
              </PrivateRoute>
              <PrivateRoute path="/dashboard/recreational" auth={this.props.auth}>
              recreational
              </PrivateRoute>
              <PrivateRoute path="/dashboard/specials" auth={this.props.auth}>
              specials
              </PrivateRoute>
              <PrivateRoute path="/dashboard/events" auth={this.props.auth}>
              events
              </PrivateRoute>
              <PrivateRoute path="/dashboard/" auth={this.props.auth}>
                <Stats id={this.props.id} />
              </PrivateRoute>
            </Switch>
          </div>
        </div>
      </div>
    );
  }

  toggleNav() {
    this.setState({
      show: !this.state.show
    })
  }
}

export default Dashboard;