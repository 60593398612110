import React, { Component } from 'react';
import Sortable from 'react-sortablejs';
import Config from '../../config';
import { resolve } from 'path';
import client from '../../feathers';
import shortid from 'shortid';
import DashboardInput from '../dashboardinput';

import './videomanager.styles.css';

class VideoManager extends Component {
	constructor(props) {
		super(props);

		this.state = {
      data: [],
      link: '',
      caption: '',
      status: '',
      statusMessage: ''
    };
    
    this.addVideo = this.addVideo.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.renderVideos = this.renderVideos.bind(this);
    this.loadData = this.loadData.bind(this);
    this.removeItem = this.removeItem.bind(this);
	}

  render() {
    return (
      <div>
        <Sortable
          className="mcg-video-manager"
          onChange={(order, sortable, event) => {
            this.onChange(order);
          }}>
            {this.renderVideos()}
        </Sortable>
        <div className="mcg-notice-form-item">
          <DashboardInput label='Caption' name='caption' value={this.state.caption} onChange={this.onFieldChange} />
          <DashboardInput label='Video URL' name='link' value={this.state.link} onChange={this.onFieldChange} />
          <label>
            <button className="mcg-form-button" type="button" onClick={this.addVideo}>Add Video</button>
          </label>
        </div>
      </div>
    );
  }

  onFieldChange(event) {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  addVideo() {

    if (typeof this.props.id === 'undefined' && typeof this.props.region === 'undefined') {
      console.error('No ID or Region provided for notice.');
      this.setState({
        status: 'failed',
        statusMessage: 'There is an issue with the form, please contact support to resolve the issue.'
      });

      return;
    }

    if (!this.validForm()) {
      console.error('Invalid form input.');

      this.setState({
        status: 'failed',
        statusMessage: 'Invalid form input, please double check your video url and try again.'
      });

      return;
    }

    let element = {};
    let lastorder = 0;

    if (this.state.data.length > 0) {
      this.state.data.forEach(i => {
        lastorder = i.order;
      })
    }
    lastorder++;

    element.customerId = this.props.id;
    element.order = lastorder;
    element.caption = this.state.caption;
    element.link = this.state.link;

    client.service('profile-videos').create(
      element
    ).then(result => {
      let newData = this.state.data;
      newData.push(result);
      this.setState({
        data: newData
      })
    }).catch(error => {
      console.error(error);
    })
  }

  renderVideos() {
    let renderFiles = this.state.data.sort((a, b) => { return a.order - b.order; });
    let results = [];
    for (let index = 0; index < renderFiles.length; index++) {
      const element = this.state.data[index];

      let imgSource = new URLSearchParams(element.link.split('?')[1]).get('v');

      results.push(
      <div key={'video-' + index} data-id={element.id} className='mcg-video-item'>
        <div>
          <a href={element.link} target="_blank" rel="noopener noreferrer">
            <img className='mcg-video-thumbnail' src={'https://img.youtube.com/vi/' + imgSource + '/hqdefault.jpg'} alt={element.caption} />
          </a>
        </div>
        <div className='mcg-video-url'>{element.link}</div>
        <div className='mcg-video-caption'>{element.caption}</div>
        <div className="mcg-remove" data-id={element.id} onClick={this.removeItem}>
          <i className="far fa-trash-alt mcg-video-icon-space"></i> Delete
        </div>
      </div>);
    }
    return results;
  }

  removeItem(event) {
    console.log('remove', event.currentTarget.dataset.id);
    client.service('profile-videos').remove(
      event.currentTarget.dataset.id
    ).then(result => {
      console.log(result);
      this.setState({
        data: this.state.data.filter(item => { return item.id !== result.id})
      })
    }).catch(error => {
      console.error(error);
    });
  }

  validForm() {
    if (this.state.link.length <= 0)
      return false;

    if (!(this.state.link.includes('http://') || this.state.link.includes('https://')))
      return false;

    if (!this.state.link.includes('.'))
      return false;
    
    return true;
  }

  loadData() {
    client.service('profile-videos').find({
      query: {
        customerId: this.props.id
      }
    }).then(result => {
      let dataList = [];
      result.forEach(data => {
        dataList.push({
          id: data.id,
          link: data.link,
          caption: data.caption,
          order: data.order,
          uid: shortid.generate()
        });
      })

      this.setState({
        data: dataList
      });
    }).catch(error => {
      console.error(error);
    });
  }

  onChange(order) {
    let newOrder = [];
    let updateArray = [];
    for (let i = 0; i < order.length; i++) {
      let file = this.state.data.find(f => { return f.id.toString() === order[i].toString(); });
      file.order = i+1;
      newOrder.push(file);
      updateArray.push({
        id: order[i],
        order: i+1
      });
    }

    this.setState({ data: newOrder });

    fetch(Config.api + 'order-patch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: 'profile-videos',
        orderArray: updateArray
      })
    }).then(response => {
      if (response.ok)
        return response.json();

      throw response.json();
    }).then(data => {
      if (data.ok){
        console.log('Order updated successfully.');
      } else {
        console.log('Not OK: Failed to update order.', data.message);
      }
      resolve();
    }).catch(error => {
      console.log('Failed to update order.');
    })
  }

  componentDidMount() {
    this.loadData();
  }
}

export default VideoManager;