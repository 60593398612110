import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {fileExists, track} from '../../utility';
import '../../styles/front-end/header.css';
import { HashLink } from 'react-router-hash-link';

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sticky: false,
      links: this.props.links,
      logoExists: false
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleNavFocus = this.handleNavFocus.bind(this);
    this.handleNavFocusReset = this.handleNavFocusReset.bind(this);

    this.headerRef = React.createRef();
  }

  render() {
    if (typeof this.props.company.profile === 'undefined')
      return '';

    return (
      <div className={'header' + ((this.state.sticky) ? ' h-sticky' : '')} ref={(headerRef) => this.headerRef = headerRef}>
        <div className="h-logo">
          <Link to="/" onClick={() => {console.log('Track home navigation'); track(this.props.company.id, 'navigation');}}>
            {(this.state.logoExists) ? (
              <img src={this.props.company.profile.logo} alt={this.props.company.name} />
            ) : (
              <span className="logo-text-replace">{this.props.company.name}</span>
            )}
          </Link>
        </div>
        <div className="h-nav-button h-nav-button-menu" onClick={this.props.toggleNav}>
          <i className="far fa-bars"></i>
        </div>
        <div className="h-nav-list">
          {this.renderLinks()}
          {(typeof this.props.cart !== 'undefined' && this.props.cart.length > 0 && typeof this.state.links  !== 'undefined') ? (
          <a onClick={this.props.toggleCart} data-id={this.state.links.length} className={(this.state.hovered) ? 'nav-reduce-visibility' : ''} onMouseEnter={this.handleNavFocus} onMouseLeave={this.handleNavFocusReset}>Cart ({this.getItemCount()})</a>
          ) : ''}
        </div>
        {(typeof this.props.cart !== 'undefined' && this.props.cart.length > 0) ? (
           <div className="h-nav-button" onClick={this.props.toggleCart}>
            <i className="fas fa-shopping-cart"></i> ({this.getItemCount()})
          </div>
        ) : ''}
      </div>
    );
  }

  
  getItemCount() {
    if (typeof this.props.cart === 'undefined' && this.props.cart.length <= 0) return;
    let result = 0;

    for (let i = 0; i < this.props.cart.length; i++)
      result += this.props.cart[i].quantity;
    
    return result;
  }

  renderLinks() {
    if (typeof this.state.links === 'undefined')
      return '';
    
    let results = [];

    for (let index = 0; index < this.state.links.length; index++) {
      const element = this.state.links[index];
      if (element.link.toLowerCase() === 'sales flyer')
        element.link = 'Marketplace'
      
      results.push(
        (element.link.substring(0,1) === '#') ? (
          <HashLink key={'h-navi-' + index} data-id={index} className={(this.state.hovered) ? 'nav-reduce-visibility' : ''} onMouseEnter={this.handleNavFocus} onMouseLeave={this.handleNavFocusReset} onClick={() => { console.log('Track interaction'); track(this.props.company.id, 'interact'); }} smooth to={element.link}>{element.label}</HashLink>
        ) : (
          <a key={'h-navi-' + index} data-id={index} className={(this.state.hovered) ? 'nav-reduce-visibility' : ''} href={element.link} onMouseEnter={this.handleNavFocus} onMouseLeave={this.handleNavFocusReset} onClick={() => { console.log('Track navigation'); track(this.props.company.id, 'navigation'); }}>{element.label}</a>
        )
      );
    }

    return results;
  }

  handleNavFocus(event) {
    this.setState({
      hovered: event.currentTarget.dataset.id
    })
  }
  handleNavFocusReset() {
    this.setState({
      hovered: null
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    if (typeof this.props.company.profile === 'undefined') return '';
      
    fileExists(this.props.company.profile.logo).then(exists => {
      this.setState({logoExists: exists})
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.setState({
      sticky: (window.scrollY > 0)
    }, this.handleSizeChange)
  }

  handleSizeChange() {
    if (typeof this.props.heightAdjusted !== 'undefined')
      this.props.heightAdjusted(this.headerRef.clientHeight);

    setTimeout( function () {
      if (typeof this.props.heightAdjusted !== 'undefined')
        this.props.heightAdjusted(this.headerRef.clientHeight);
    }.bind(this), 200)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company) {
      this.setState({company: this.props.company});
      fileExists(this.props.company.profile.logo).then(exists => {
        this.setState({logoExists: exists})
      })
    }

    if (prevProps.links !== this.props.links)
      this.setState({links: this.props.links});
  }
}

export default Header;