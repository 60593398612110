import React, { Component } from 'react';
import FileManagerItem from './filemanager.item.component';
import Sortable from 'react-sortablejs';
import Config from '../../config';
import { resolve } from 'path';

import './filemanager.styles.css';

class FileManager extends Component {
	constructor(props) {
		super(props);

		this.state = {
			files: this.props.files
    };
    
    this.onChange = this.onChange.bind(this);
    this.renderFiles = this.renderFiles.bind(this);
	}

  render() {
    return (
      (this.props.options.sortable) ? (
      <Sortable
        className="mcg-file-manager"
        onChange={(order, sortable, event) => {
          this.onChange(order);
        }}>
          {this.renderFiles()}
      </Sortable>
      ) : (
        <div className="mcg-file-manager">
          {this.renderFiles()}
        </div>
      )
    );
  }

  renderFiles() {
    let renderFiles = this.state.files.sort((a, b) => { return a.order - b.order; });

    return renderFiles.map(file => (
      <FileManagerItem
        key={file.uid}
        file={file}
        id={this.props.id}
        region={this.props.region}
        service={this.props.service}
        updateData={this.props.updateData} />
    ));
  }

  onChange(order) {
    let newOrder = [];
    let updateArray = [];
    for (let i = 0; i < order.length; i++) {
      let file = this.state.files.find(f => { return f.id.toString() === order[i].toString(); });
      file.order = i+1;
      newOrder.push(file);
      updateArray.push({
        id: order[i],
        order: i+1
      });
    }

    this.setState({ files: newOrder });

    fetch(Config.api + 'order-patch', {
			method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
			body: JSON.stringify({
        type: this.props.service,
        orderArray: updateArray
      })
		}).then(response => {
			if (response.ok)
				return response.json();

			throw response.json();
		}).then(data => {
			if (data.ok){
        console.log('Order updated successfully.');
			} else {
        console.log('Not OK: Failed to update order.');
			}
			resolve();
		}).catch(error => {
			console.log('Failed to update order.');
		})
  }

  static getDerivedStateFromProps (props, state) {
    state.files = props.files;

    return state;
  }
}

export default FileManager;