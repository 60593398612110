import React, {useEffect} from 'react';

function CartItem(props) {

  const onChange = (event) => {
    const value = event.currentTarget.value;
    
    props.update(value);
  }

  return (
    <div className="checkout-item-container">
      <div className="checkout-item-remove" onClick={props.remove}>
        <i className="fas fa-times"></i>
      </div>
      <div className="checkout-item-image">
        <img src={props.item.image} alt={props.item.name} />
      </div>
      <div className="checkout-item-information">
        <div className="checkout-item-title">{props.item.name}</div>
        <div className="checkout-item-subtitle">{props.item.variation.variation}</div>
        <div className="checkout-item-quantity"><input type="Number" value={props.item.quantity} onChange={onChange} /> x ${props.item.price.toFixed(2)}</div>
      </div>
      <div className="checkout-item-price">${(props.item.quantity * props.item.price).toFixed(2)}</div>
    </div>
  )
}

export default CartItem;