import React, { Component } from 'react';
import Details from './routes/details';
import Media from './routes/media';
import Content from './routes/content';
import Options from './routes/options';
import Design from './routes/design';
import '../../styles/admin.css'
import Stats from './routes/stats';

class Admin extends Component {
	constructor(props) {
		super(props);

		this.state = {
      show: false,
      section: 'details'
    }

    this.renderSection = this.renderSection.bind(this);
  }

  componentDidMount() {
    this.setState({ section: 'details'})
  }

  render() {
    return (
      <div className="admin-container">
        <div className="admin-nav">
          <div onClick={() => { this.setState({ section: 'details'}) }} className="admin-link"><i className="far fa-edit"></i> Details</div>
          <div onClick={() => { this.setState({ section: 'media'}) }} className="admin-link"><i className="far fa-photo-video"></i> Media</div>
          <div onClick={() => { this.setState({ section: 'content'}) }} className="admin-link"><i className="fas fa-text-size"></i> Content</div>
          <div onClick={() => { this.setState({ section: 'options'}) }} className="admin-link"><i className="fal fa-sliders-v"></i> Settings</div>
          <div onClick={() => { this.setState({ section: 'design'}) }} className="admin-link"><i className="far fa-paint-roller"></i> Design</div>
          <div onClick={() => { this.setState({ section: 'analytics'}) }} className="admin-link"><i className="fas fa-analytics"></i> Analytics</div>
          <a href={'https://hub.mcgmedia.net/?previewId=' + this.props.id} className="admin-link" target="_blank"><i className="far fa-external-link"></i> Preview Site</a>
        </div>
        <div className="admin-container">
          {this.renderSection()}
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({ section: 'details'})
    }
  }

  renderSection() {
    console.log('render admin', this.props)
    switch(this.state.section) {
      case 'media':
        return <Media id={this.props.id} region={this.props.company.region} />
      case 'content':
        return <Content id={this.props.id} region={this.props.company.region} />
      case 'options':
        return <Options id={this.props.id} />
      case 'design':
        return <Design id={this.props.id} />
      case 'details':
        return <Details id={this.props.id} />
      case 'analytics':
        return <Stats id={this.props.id} />
      default:
        return <Details id={this.props.id} />
    }
  }
}

export default Admin;