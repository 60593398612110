import React, { Component } from 'react';
import './featured.css';
import Item from './featureditem';
import { convertToCurrency } from '../../../../utility';

class Featured extends Component {
	constructor(props) {
		super(props);

		this.state = {
      items: this.props.featuredItems
    };

    this.getPriceValue = this.getPriceValue.bind(this);
    this.getValue = this.getValue.bind(this);
  }

  render() {
    return (
      <div className="featured-list">
        {this.renderItems()}
      </div>
    );
  }

  renderItems() {
    if (typeof this.state.items === 'undefined') return;
    let results = [];
    let items = shuffle([...this.state.items]);
    let count = 0;

    for (let index = 0; index < items.length && count < this.props.options.featureLimit; index++) {
      switch(items[index].type) {
        case 'restaurant':
          if (items[index].hasImage) {
            results.push(<Item key={'featured' + index} {...this.props} item={{
              title: items[index].name,
              subtitle: '',
              price: items[index].cost,
              url: '/restaurant',
              image: 'https://images.cwportals.com/restaurant/' + this.props.company.region + '/' + this.props.company.id + '/' + items[index].id + '.jpg'
            }} />);
            count++;
          }
          break;
        case 'marketplace':
          results.push(<Item key={'featured' + index} {...this.props} item={{
            title: items[index].title,
            brand: items[index].subTitle,
            price: (typeof items[index].variations[0] !== 'undefined') ? this.getPriceValue(items[index]).toFixed(2) : '',
            url: '/marketplace/' + items[index].id,
            //image: (typeof items[index].images !== 'undefined' && typeof items[index].images.sort((a,b) => { return (a.primary === b.primary)? 0 : a.primary? -1 : 1; })[0] !== 'undefined') ? 'https://images.cwportals.com/marketplace/' + this.props.company.region + '/' + this.props.company.id + '/' + items[index].id + '/' + items[index].images.sort((a,b) => { return (a.primary === b.primary)? 0 : a.primary? -1 : 1; })[0].image + '.jpg' : 'https://images.cwportals.com/nopic.jpg',
            image: (items[index].images.length > 0) ? 'https://api.mcgmedia.net/assets/market/' + items[index].customerId + '/' + items[index].id + '/' + items[index].images[0].url : 'https://images.cwportals.com/nopic.jpg',
            onSale: items[index].onSale
          }} />);
          count++;
          break;
        case 'automotive':
          let price = this.getAutoPrice(items[index]);
          results.push(<Item key={'featured' + index} {...this.props} item={{
            title: items[index].year + ' ' + items[index].make + ' ' + items[index].model,
            price: price,
            url: '/automotive/' + items[index].id,
            image: (typeof items[index].images !== 'undefined' && typeof items[index].images.sort((a,b) => { return (a.primary === b.primary)? 0 : a.primary? -1 : 1; })[0] !== 'undefined') ? 'https://images.cwportals.com/automotive/' + this.props.company.region + '/' + this.props.company.id + '/' + items[index].id + '/' + items[index].images.sort((a,b) => { return (a.primary === b.primary)? 0 : a.primary? -1 : 1; })[0].image + '.jpg' : 'https://images.cwportals.com/nopic.jpg',
            onSale: (items[index].specialPrice) ? true : false
          }} />);
          count++;
          break;
        default:
          break;
      }
    }

    return results;
  }
  
  getPriceValue(item) {
    let value = this.getValue(item);
    let saleValue = value;

    if (item.onSale && new Date(item.saleEnd) > new Date()) {
      switch (item.discountType) {
        case 'percent':
          saleValue = saleValue * (1 - (item.discountValue / 100));
          break;
        default:
          saleValue -= Number(item.discountValue);
          break;
      }
    }
    
    if (value <= 0) return 0;

    return (saleValue !== value) ? saleValue : value
  }

  getValue(item) {
    if (typeof item === 'undefined' || !item) return 0;

    return item.variations[0].price;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.featuredItems !== this.props.featuredItems) {
      this.setState({items: this.props.featuredItems});
    }
  }

  getAutoPrice(item) {
    let tag = '';
    let price = 0;

    if (typeof item.paymentType !== 'undefined' && item.paymentType) {
      price = item.altPrice;
      tag = ' ' + item.paymentType;
    } else if (typeof item.specialPrice !== 'undefined' && item.specialPrice) {
      price = item.specialPrice;
    } else {
      price = item.price;
    }

    if (price && price > 0)
      return convertToCurrency(price) + tag;
    else
      return 'Call for Price';
  }
}

function shuffle (arr){
  return arr.reduce( 
    (newArr, _, i) => {
      var rand = i + ( Math.floor( Math.random() * (newArr.length - i) ) );
      [newArr[rand], newArr[i]] = [newArr[i], newArr[rand]]
      return newArr
    }, [...arr]
  )
}

export default Featured;