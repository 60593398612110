import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import resizeImage from 'smart-img-resize';
import Switch from "react-switch";
import DatePicker from 'react-date-picker'
import { urltoFile } from '../../../../utility';
import DashboardInput from '../../../../components/dashboardinput';
import client from '../../../../feathers';
import config from '../../../../config';
import { useHistory, useParams } from 'react-router';
import Sortable from 'react-sortablejs';
import ReactQuill from 'react-quill';

function CreateItem(props) {
  const history = useHistory();
  let { id } = useParams();
  
  const [cancelProcessing, setCancelProcessing] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [description, setDescription] = useState('');

  const [imageHover, setImageHover] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState([]);
  const [status, setStatus] = useState({ status: '', message: ''});
  const [item, setItem] = useState({});
  const [categories, setCategories] = useState([]);
  const [showNewCategory, setShowNewCategory] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [showCategoryManagement, setShowCategoryManagement] = useState(false);
  const [stats, setStats] = useState({});

  useEffect(() => {
    console.log('description changed');

    let tempItem = {...item};
    tempItem.description = description;

    setItem(tempItem);
  }, [description])

  useEffect(() => {
    if (props.adminLink)
      id = props.itemId;
  })

  useEffect(() => {
    loadItem();
    loadCategories();
  }, []);

  const loadItem = () => {
    if (props.adminLink)
      id = props.itemId;

    client.service('market-items').find({
      query: {
        id: id
      }
    }).then(res => {
      console.log(res[0]);
      setItem(res[0]);
      setDescription(res[0].description);
    }).catch(error => {
      console.log(error);
    })
    
    client.service('market-stats').find({
      query: {
        itemId: id
      }
    }).then(res => {
      setStats(res[0]);
      //console.log(res[0]);
    }).catch(error => {
      console.log(error);
    })
  }

  const onDrop = (files) => {
    setImageHover(false);
  }
  const onDropAccepted = (images) => {
    images.forEach(image => {
      resizeImage(image, {
        outputFormat: 'jpeg',
        targetWidth: 512,
        targetHeight: 512,
        crop: false
      }, (err, preview) => {
        if (err) {
          console.error(err);
          return;
        }
        
        urltoFile(preview, image.name, 'image/jpeg')
        .then(image => {
          setUploading(false);
          setImage([image]);
          let prevtemp = [...previewImage];
          prevtemp.push(preview);
          setPreviewImage(prevtemp);
        })

        // image upload
        const marketData = new FormData();
        
        marketData.append('destination', 'assets/market/' + props.customerId + '/' + id + '/');
        marketData.append('id', props.customerId);
        marketData.append('region', props.region);
        marketData.append('type', 'market-images');
        
        // Image Details
        marketData.append('itemId', id);
        marketData.append('files', image);
        
        fetch(config.api + 'media-upload', {
          method: 'POST',
          body: marketData
        }).then(response => {
          if (response.ok)
            return response.json();

          throw response.json();
        }).then(data => {
          console.log(data);
          client.service('market-images').find({
            query: {
              itemId: id
            }
          }).then(res => {
            console.log(res);
            
            let temp = {...item};
            temp.images = [];
            res.forEach(img => {
              temp.images.push(img);;
            })
            setItem(temp);
          })
        });
      })
    });
  }
  const onDropRejected = (files) => {
    setUploading(false);
    setStatus({ status: 'failed', message: 'Image failed to upload, upload attempt rejected, please verify that your Image is the correct format and size.' });
  }
	const dropStateRender = () => {
		if (imageHover)
      return <p className="mcg-large-text"><i className="fas fa-cloud-upload-alt"></i> Drop to Upload</p>;
      
    if (previewImage.length > 0)
      return <img className="mcg-restrict-width" src={previewImage[0]} alt='' />

		return <p className="mcg-large-text">Drag 'n' drop files here, or click to select files</p>;
  }

  const onChange = (e) => {
    let tempItem = {...item};
    tempItem[e.currentTarget.name] = e.currentTarget.value;

    setItem(tempItem);
  }

  const onVariationChange = (e, index) => {
    let tempItem = {...item};
    let tempVariations = [...tempItem.variations];

    tempVariations[index][e.currentTarget.name] = e.currentTarget.value;
    
    tempItem.variations = tempVariations;

    setItem(tempItem);
  }
  const addVariation = () => {
    let tempItem = {...item};
    let tempVariations = [...tempItem.variations];

    tempVariations.push({
      variation: '',
      sku: '',
      price: '',
      stock: ''
    })

    tempItem.variations = tempVariations;

    setItem(tempItem);
  }
  const removeVariation = (index) => {
    if (item.variations.length <= 1) return;

    // Confirmation Message
    console.log(index);

    let tempItem = {...item};
    let tempVariations = [...tempItem.variations];

    tempVariations.splice(index, 1);
    tempItem.variations = tempVariations;

    setItem(tempItem);
  }
  const renderVariations = () => {
    if (typeof item.variations === 'undefined' || !item.variations) return;

    let results = [];

    for (let index = 0; index < item.variations.length; index++) {
      const element = item.variations[index];
      
      results.push(
        <div className="a-market-table-row" key={'variation' + index} data-id={element.id}>
          <div className="a-market-table-cell am-delete" onClick={() => { removeVariation(Number(index)) }}><i className="far fa-trash-alt"></i></div>
          <div className="a-market-table-cell am-label"><input type="text" name="variation" onChange={(e) => onVariationChange(e, index)} placeholder="Variation" value={element.variation || ''} /></div>
          <div className="a-market-table-cell am-sku"><input type="text" name="sku" onChange={(e) => onVariationChange(e, index)} placeholder="SKU" value={element.sku || ''} /></div>
          <div className="a-market-table-cell am-price"><input type="text" name="price" onChange={(e) => onVariationChange(e, index)} placeholder="Price" value={element.price || ''} /></div>
          <div className="a-market-table-cell am-stock"><input type="text" name="stock" onChange={(e) => onVariationChange(e, index)} placeholder="Stock" value={element.stock || ''} /></div>
        </div>
      );
    }

    return results;
  }

  const removeImage = (id) => {
    client.service('market-images').remove(id).then(res => {
      console.log(res);
      let tempItem = {...item};
      tempItem.images.splice(tempItem.images.findIndex(e => e.id === res.id),1);
      
      console.log(res, tempItem);
      setItem(tempItem);
    }).catch(error => {
      console.log(error);
    })
  }

  const renderImages = () => {
    let results = [];

    if (typeof item.images !== 'undefined'){
      let images = item.images.sort((a, b) => { return a.order - b.order; });

      for (let i = 0; i < images.length; i++) {
        const element = images[i];

        results.push(
        <div key={'rit'+i} data-id={element.id}>
          <div className="a-market-image-item"><img src={'https://api.mcgmedia.net/assets/market/' + props.customerId + '/' + item.id + '/' + element.url} /></div>
          <div className="a-market-image-remove-button" onClick={() => removeImage(element.id)}>Remove</div>
        </div>
        );
      }
    } else
      results = <div></div>;
    
    return results;
  }

  const loadCategories = () => {
    client.service('market-categories').find({
      query: {
        customerId: props.customerId
      }
    }).then(res => {
      setCategories(res.sort((a, b) => (a.category < b.category) ? -1 : 1));
    }).catch(error => {
      console.log(error);
    })
  }
  const renderCategoryList = () => {
    let results = [];

    if (categories.length > 0 && typeof item.categoryId === 'undefined')
      setItem({...item, categoryId: categories[0].id});
    
    for (let i = 0; i < categories.length; i++)
      results.push(<option key={'cat_' + i} value={categories[i].id}>{categories[i].category}</option>);
    
    return results;
  }
  const renderCategoryManagementList = () => {
    let results = [];

    for (let index = 0; index < categories.length; index++) {
      results.push(
        <div key={'rcmli'+index} className="am-category-management-item">
          <div className="am-category-management-label"><input type="text" value={categories[index].category} onChange={(e) => {
              let tempCatList = [...categories];
              tempCatList[index].category = e.currentTarget.value;
              saveCategory(tempCatList[index]);
              setCategories(tempCatList.sort((a, b) => (a.category < b.category) ? -1 : 1));
            }} /></div>
          <div className="a-market-option-delete am-category-management-remove"
          onClick={() => {
            removeCategory(categories[index], loadCategories);
          }}>
            <i className="far fa-trash-alt"></i>
          </div>
        </div>
      );
    }
    
    return results;
  }
  const saveCategory = (category) => {
    if (typeof category.id !== 'undefined' && category.id) {
      client.service("market-categories").update(category.id,
        category
      ).then(res => {
        loadCategories();
      }).catch(error => {
        console.log(error);
      })
    } else {
      client.service("market-categories").create({
        category: category,
        customerId: props.customerId
      }).then(res => {
        loadCategories();
      }).catch(error => {
        console.log(error);
      })
    }
  }
  const removeCategory = (category, callback) => {
    client.service("market-categories").remove(category.id).then(res => {
      callback();
    }).catch(error => {
      console.log(error);
    })
  }

  const cancel = () => {
    setStatusMessage('Rolling back changes...');

    if (item.newlyCreated) {
      removeItem(id);
    } else {
      if (props.adminLink) {
        window.location.href = '/modules/market/?r=' + props.region + '&custid=' + props.customerId;
      } else
        history.push('/dashboard/marketplace/');
    }
  }

  const save = () => {
    setStatusMessage('Saving changes...');
    if (props.adminLink)
      id = props.itemId;

    item.newlyCreated = false;

    console.log('save id', id);

    client.service("market-variations").remove(null, {
      query: {
        itemId: id
      }
    }).then(res => {
      // insert/update variations
      for (let i = 0; i < item.variations.length; i++) {
        const variation = item.variations[i];
        
        //if (variation.price)
          client.service('market-variations').create({
            itemId: id,
            ...variation,
            order: i+1
          }).catch(error => {
            console.log(error);
          })
      }
      
      client.service('market-items').update(id,
        item
      ).then(res => {
        setTimeout(() => {
          if (props.adminLink) {
            window.location.href = '/modules/market/?r=' + props.region + '&custid=' + props.customerId;
          } else
            history.push('/dashboard/marketplace/');
        }, 100);
      }).catch(error => {
        console.log(error);
      })
    }).catch(error => {
      console.log(error);
    })

  }
  const removeItem = (id) => {
    client.service('market-images').remove(null, {
      query: {
        itemId: id
      }
    }).then(res => {
      client.service('market-stats').remove(null, {
        query: {
          itemId: id
        }
      }).then(res => {
        client.service('market-variations').remove(null, {
          query: {
            itemId: id
          }
        }).then(res => {
          client.service('market-items').remove(id).then(res => {
            if (props.adminLink) {
              window.location.href = '/modules/market/?r=' + props.region + '&custid=' + props.customerId;
            } else
              history.push('/dashboard/marketplace/');
          }).catch(error => {
            console.log(error);
          });
        }).catch(error => {
          console.log(error);
        });
      }).catch(error => {
        console.log(error);
      });
    }).catch(error => {
      console.log(error);
    });
  }

  const onChangeImageOrder = (order) => {
    let newOrder = [];
    let updateArray = [];
    for (let i = 0; i < order.length; i++) {
      let image = item.images.find(img => { console.log(img); return img.id.toString() === order[i].toString(); });
      image.order = i+1;
      newOrder.push(image);
      updateArray.push({
        id: order[i],
        order: i+1
      });
    }

    let tempItem = {...item};
    tempItem.images = [...newOrder];

    setItem(tempItem);

    console.log('newOrder',newOrder);
    fetch(config.api + 'order-patch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: 'market-images',
        orderArray: updateArray
      })
    }).then(response => {
      if (response.ok)
        return response.json();
    }).then(data => {
      if (data.ok){
        console.log('Image order updated successfully.');
      } else {
        console.log('Not OK: Failed to update image order.');
      }
    }).catch(error => {
      console.error(error);
      console.log('Failed to update order.');
    })
  }
  const onChangeVariationsOrder = (order) => {
    let newOrder = [];
    let updateArray = [];
    console.log('v order', order)
    for (let i = 0; i < order.length; i++) {
      let variations = item.variations.find(v => { return v.id.toString() === order[i].toString(); });

      variations.order = i+1;
      newOrder.push(variations);
      updateArray.push({
        id: order[i],
        order: i+1
      });
    }

    let tempItem = {...item};
    tempItem.variations = [...newOrder];

    setItem(tempItem);

    console.log('newOrder',newOrder);
    fetch(config.api + 'order-patch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: 'market-variations',
        orderArray: updateArray
      })
    }).then(response => {
      if (response.ok)
        return response.json();
    }).then(data => {
      if (data.ok){
        console.log('Variation order updated successfully.');
      } else {
        console.log('Not OK: Failed to update variation order.');
      }
    }).catch(error => {
      console.error(error);
      console.log('Failed to update order.');
    })
  }

  const generateExternalURL = () => {
    
    client.service('account').find({
      query: {
        int_id: props.customerId,
        $select: [ 'str_domain' ]
      }
    }).then(res => {
      console.log(res[0]);

      let tempItem = {...item};
      tempItem.externalUrl =  res[0].str_domain + '/marketplace/' + id;
  
      setItem(tempItem);
    }).catch(error => {
      console.log(error);
    })

  }

  return (
    <div className="a-market-create-wrapper">
      <div className="a-market-create-back" onClick={() => { setPreviewImage([]); cancel(); }}><i className="fas fa-chevron-left"></i> Back to Item List</div>
      <div className="a-market-create-top-section">
        <div className="a-market-create-details">
          <div className="a-market-create-section-header">Item Details</div>
          <div className="a-market-create-detail-wrapper">
            <div className="a-market-create-input">
              <div className="am-create-label">Name</div>
              <div><input type="text" name="title" onChange={onChange} placeholder="Name" value={item.title || ''} /></div>
            </div>
            <div className="a-market-create-input">
              <div className="am-create-label">Status</div>
              <div className="am-create-status-select">
                <select onChange={(e) => { setItem({ ...item, status: e.currentTarget.value }) }} value={item.status || 'draft'}>
                  <option value="draft">Draft</option>
                  <option value="public">Public</option>
                </select>
              </div>
            </div>
            <div className="a-market-create-input">
              <div className="am-create-label">Sub Title</div>
              <div><input type="text" name="subTitle" onChange={onChange} placeholder="Brand, sub-title, etc" value={item.subTitle || ''}></input></div>
            </div>
            <div className="a-market-create-input">
              <div className="am-create-label">External Url</div>
              <div><input type="text" name="externalUrl" onChange={onChange} value={item.externalUrl || ''}></input></div>
              <div className="a-market-create-back am-category-new-toggle" onClick={() => { generateExternalURL() }}>Generate</div>
              
            </div>
            <div className="a-market-create-input">
              <div className="am-create-label">Category</div>
              <div className="am-create-status-select">
                <select onChange={(e) => { setItem({...item, sector: e.currentTarget.value}) }} value={item.sector}>
                  <option value="Accessories">Accessories</option>
                  <option value="Appliances">Appliances</option>
                  <option value="Arts, Crafts & Sewing">Arts, Crafts & Sewing</option>
                  <option value="Autos & Vehicles">Autos & Vehicles</option>
                  <option value="Baby">Baby</option>
                  <option value="Beauty & Personal Care">Beauty & Personal Care</option>
                  <option value="Books & Audio">Books & Audio</option>
                  <option value="Building Supplies">Building Supplies</option>
                  <option value="Cell Phones & Accessories">Cell Phones & Accessories</option>
                  <option value="Clothing">Clothing</option>
                  <option value="Electronics & Computers">Electronics & Computers</option>
                  <option value="Events & Travel">Events & Travel</option>
                  <option value="Fabrics, Textiles & Sewing">Fabrics, Textiles & Sewing</option>
                  <option value="Flooring & Carpet">Flooring & Carpet</option>
                  <option value="Footwear">Footwear</option>
                  <option value="Furniture">Furniture</option>
                  <option value="Garden & Outdoor">Garden & Outdoor</option>
                  <option value="Grocery & Food">Grocery & Food</option>
                  <option value="Handmade">Handmade</option>
                  <option value="Health">Health</option>
                  <option value="Home & Kitchen">Home & Kitchen</option>
                  <option value="Industrial & Manufacturing">Industrial & Manufacturing</option>
                  <option value="Jewelry">Jewelry</option>
                  <option value="Musical Instruments">Musical Instruments</option>
                  <option value="Office Products">Office Products</option>
                  <option value="Pets & Supplies">Pets & Supplies</option>
                  <option value="Power Equipment">Power Equipment</option>
                  <option value="Raw Materials">Raw Materials</option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Recreational Vehicles">Recreational & Marine</option>
                  <option value="Rental Units">Rental Units</option>
                  <option value="Sports & Outdoors">Sports & Outdoors</option>
                  <option value="Tires & Parts">Tires & Parts</option>
                  <option value="Tools & Home Improvement">Tools & Home Improvement</option>
                  <option value="Toys & Games">Toys & Games</option>
                </select>
              </div>
              <div className="am-create-status-select">
                {/*<input type="text" name="category" onChange={onChange} />*/}
                {(categories.length > 0) ? (
                  <select onChange={(e) => { setItem({...item, categoryId: e.currentTarget.value}) }} value={item.categoryId}>
                    {renderCategoryList()}
                  </select>
                ) : (
                  <div>No Categories</div>
                )}
              </div>
              <div className="a-market-create-back am-category-new-toggle" onClick={() => { setShowNewCategory(!showNewCategory) }}>{(showNewCategory) ? 'Hide New Category' : 'New Category'}</div>
            </div>
            <div className={(showNewCategory) ? 'a-market-create-input' : 'a-market-create-input hide'}>
              <div className="am-create-label am-category-new-label">
                New Category
                <div className="a-market-create-back am-category-new-toggle mcg-manage-category-button" onClick={() => { setShowCategoryManagement(!showCategoryManagement) }}>Manage</div>
              </div>
              <div className="a-market-create-category-selector">
                <input type="text" name="newCategory" onChange={(e) => { setNewCategory(e.currentTarget.value) }} />
              </div>
              <div className="mcg-button mcg-add-category-button" onClick={() => { saveCategory(newCategory, (newCat) => setCategories([...categories, newCat].sort((a, b) => (a.category < b.category) ? -1 : 1))); setNewCategory(''); setShowNewCategory(false); }}>Add</div>
            </div>
          </div>
        </div>
        <div className="a-market-create-photos">
          <div className="mcg-notice-form-item mcg-inline-drop-area">
          <div className="a-market-create-section-header">Product Images</div>
            <Dropzone onDrop={onDrop}
              onDropAccepted={onDropAccepted}
              onDropRejected={onDropRejected}
              accept={'image/png,image/jpeg,image/jpg,image/gif'}
              disabled={uploading}
              multiple={false}
              noKeyboard={true}
              onDragEnter={() => setImageHover(true)}
              onDragLeave={() => setImageHover(false)}>
              {({getRootProps, getInputProps}) => (
                <section className="mcg-notice-form-item">
                  <div className={'mcg-drop-area' + (uploading ? ' disabled' : '')} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {dropStateRender()}
                  </div>
                </section>
              )}
            </Dropzone>
            <Sortable
              className="a-market-image-list"
              onChange={(order, sortable, event) => {
                onChangeImageOrder(order);
              }}>
                {renderImages()}
            </Sortable>
            {(previewImage.length > 0) ? <div className="a-market-preview-image-count">{previewImage.length} Images not saved.</div> : ''}
          </div>
        </div>
      </div>
      <div className={(showCategoryManagement) ? 'a-market-category-management' : 'a-market-category-management hide'}>
        <div className="a-market-create-option-section">
          <div className="a-market-create-variation-section-header">
            <div className="a-market-create-section-header">Manage Categories</div>
          </div>
          <h2></h2>
          <div className="am-category-management-list">
            {renderCategoryManagementList()}
          </div>
          <div className="a-market-create-variation-button-container">
            <div className="mcg-button market-button a-market-create-button"
            onClick={() => {
              setShowCategoryManagement(false);
            }}>
              Finish Editing Categories
            </div>
          </div>
        </div>
      </div>
      <div className="a-market-create-option-section">
        <div className="a-market-create-variation-section-header">
          <div className="a-market-create-section-header">Stats</div>
        </div>
        <div className="am-create-stat-wrapper">
          <div className="am-create-stat-section">
            <div className="am-create-stat-label">
              Clicks
            </div>
            <div className="am-create-stat-value">
              {(typeof stats !== 'undefined' || stats) ? (typeof stats.clicks !== 'undefined') ? stats.clicks : 0 : 0}
            </div>
          </div>
          <div className="am-create-stat-section">
            <div className="am-create-stat-label">
              Views
            </div>
            <div className="am-create-stat-value">
              {(typeof stats !== 'undefined' || stats) ? (typeof stats.views !== 'undefined') ? stats.views : 0 : 0}
            </div>
          </div>
        </div>
      </div>
      <div className="a-market-create-option-section">
        <div className="a-market-create-variation-section-header">
          <div className="a-market-create-section-header">Description</div>
        </div>
        <div className="am-create-description-multiline">
          {/*<textarea name="description" onChange={onChange} placeholder="Description" rows="12" value={item.description || ''}></textarea>*/}
          <ReactQuill name="description" value={description} onChange={setDescription} />
        </div>
      </div>
      <div className="a-market-create-variation-container">
        <div className="a-market-create-variation-section">
          <div className="a-market-create-variation-section-header">
            <div className="a-market-create-section-header">Variations</div>
          </div>
          <div className="a-market-table">
            <div className="a-market-table-row a-market-table-header">
              <div className="a-market-table-cell am-label">Variation</div>
              <div className="a-market-table-cell am-sku">SKU</div>
              <div className="a-market-table-cell am-price">Price</div>
              <div className="a-market-table-cell am-stock">Stock</div>
              <div className="a-market-table-cell am-delete"></div>
            </div>
            <Sortable
              onChange={(order, sortable, event) => {
                onChangeVariationsOrder(order);
              }}>
              {renderVariations()}
            </Sortable>
          </div>
        </div>
        <div className="a-market-create-variation-button-container">
          <div className="mcg-button market-button a-market-create-button" onClick={addVariation}>
            Add Variation
          </div>
        </div>
      </div>
      <div>
        <div className="a-market-create-item-settings-section">
          <div className="a-market-create-item-settings-section-header">
            <div className="a-market-create-section-header">Item Settings</div>
          </div>
          <div>
            <div className="a-market-create-item-settings-section-header-stock-option">
              <div className="a-market-create-item-settings-section-header-stock-option-label">Tax Exempt</div>
              <div>
                <Switch checked={item.taxExempt || false}
                  name="taxExempt"
                  onChange={(value) => { setItem({ ...item, taxExempt: value }) }}
                  onColor="#86d3ff"
                  className="a-market-create-switch"
                  onHandleColor="#2693e6"
                  handleDiameter={24}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={14}
                  width={36} />
              </div>
            </div>
            <div className="a-market-create-item-settings-section-header-stock-option">
              <div className="a-market-create-item-settings-section-header-stock-option-label">Show Calculator</div>
              <div>
                <Switch checked={item.showPaymentCalculator || false}
                  name="showPaymentCalculator"
                  onChange={(value) => { setItem({ ...item, showPaymentCalculator: value }) }}
                  onColor="#86d3ff"
                  className="a-market-create-switch"
                  onHandleColor="#2693e6"
                  handleDiameter={24}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={14}
                  width={36} />
              </div>
            </div>
            <div className="a-market-create-item-settings-section-header-stock-option">
              <div className="a-market-create-item-settings-section-header-stock-option-label">Allow Shipping</div>
              <div>
                <Switch checked={item.allowShipping || false}
                  name="allowShipping"
                  onChange={(value) => { setItem({ ...item, allowShipping: value }) }}
                  onColor="#86d3ff"
                  className="a-market-create-switch"
                  onHandleColor="#2693e6"
                  handleDiameter={24}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={14}
                  width={36} />
              </div>
            </div>
            <div className="a-market-create-item-settings-section-header-stock-option">
              <div className="a-market-create-item-settings-section-header-stock-option-label">Track Stock</div>
              <div>
                <Switch checked={item.trackStock || false}
                  name="trackStock"
                  onChange={(value) => { setItem({ ...item, trackStock: value }) }}
                  onColor="#86d3ff"
                  className="a-market-create-switch"
                  onHandleColor="#2693e6"
                  handleDiameter={24}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={14}
                  width={36} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="a-market-create-sale-details-container">
        <div className="a-market-create-sale-details-section-header">
          <div className="a-market-create-section-header">Sale Details</div>
        </div>
        <div className="a-market-sale-visible-wrap">
          <div className="a-market-sale-toggle">
            <Switch checked={(typeof item.onSale !== 'undefined' && item.onSale) ? item.onSale : false}
              name="onSale"
              onChange={(value) => { setItem({ ...item, onSale: value }) }}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={24}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={14}
              width={36} />
            <div className="a-market-sale-toggle-label">Item On Sale</div>
          </div>
          <div className="a-market-sale-top-descriptor">
            When an item is on sale, you will be able to set the end date of the sale, the discount type/discount for the item in question.
          </div>
          {(item.onSale) ? (
            <div className="a-market-sale-details">
              <div className="a-market-sale-details-date">
                <div className="a-market-sale-cell a-market-sale-details-date-label">End Date</div>
                <div className="a-market-sale-cell a-market-sale-details-date-select">
                  <DatePicker
                    minDate={(new Date()).now}
                    value={(typeof item.saleEnd !== 'undefined' && item.saleEnd) ? new Date(item.saleEnd) : new Date()}
                    onChange={(value) => { setItem({...item, saleEnd:value }) }}
                    calendarIcon={false}
                    clearIcon={false} />
                  {/*<div className="a-market-sale-details-clear-date"><i className="fal fa-times"></i></div>*/}
                </div>
              </div>
              <div className="a-market-sale-details-discount">
                <div className="a-market-sale-cell a-market-sale-details-discount-label">Discount</div>
                <div className="a-market-sale-cell a-market-sale-details-discount-type">
                  <select onChange={(e) => { setItem({ ...item, discountType: e.currentTarget.value }) }} defaultValue={item.discountType || 'percent'}>
                    <option value="percent">Percent Off</option>
                    <option value="value">Dollars Off</option>
                  </select>
                </div>
                <div className="a-market-sale-cell a-market-sale-details-discount-value">
                  {(item.discountType === 'special') ? (
                    <input type="text" name="discountValue" onChange={onChange} placeholder="Buy One, Get One" value={item.discountValue || ''} />
                  ) : (
                    <input type="number" name="discountValue" onChange={onChange} placeholder="10" value={item.discountValue || ''} />
                  )}
                </div>
              </div>
            </div>
          ) : ''}
        </div>
      </div>
      <div className="a-market-create-save-button-container">
        {(statusMessage.length > 0) ? (
          <div className="mcg-rollback-cancel">{statusMessage}</div>
        ) : (
          <>
          <div className="mcg-button market-button a-market-create-button mcg-cancel" onClick={() => { setPreviewImage([]); cancel(); }}>
            Cancel
          </div>
          <div className="mcg-button market-button a-market-create-button" onClick={() => { setPreviewImage([]); save() }}>
            Save Item
          </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CreateItem;