import React, { Component } from 'react';
import './servicemanager.css';
import client from '../../feathers';

class ServiceManager extends Component {
	constructor(props) {
		super(props);

		this.state = {
      data: [],
      value: ''
    };

    this.addService = this.addService.bind(this);
    
    this.loadData();
  }

  render() {
    return (
      <div className="dash-stat-block">
        <h3>Services</h3>
        <div>
          {this.renderServices()}
          <div className="mcg-service-insert">
            <div className='mcg-button' onClick={this.addService}>
              <i className="fas fa-plus"></i> Add Service
            </div>
            <div className='mcg-service-input'>
              <input type="text" name={'value'} value={this.state.value} placeholder="Type your service here"
              onChange={(e) => { this.setState({ value: e.target.value }) }} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderServices() {
    let results = [];

    for (let index = 0; index < this.state.data.length; index++) {
      const service = this.state.data[index];

      results.push(
        <div className="mcg-service-item">
          <div className='mcg-remove' onClick={() => { this.remove(index) }} data-id={index}><i className="fas fa-trash"></i></div>
          <div className='mcg-service-input'>
            <input type="text" name={index} value={service} placeholder="Type your service here"
              onBlur={() => this.saveData()} 
              onChange={(e) => this.onChange(index, e)} />
          </div>
        </div>
      )
      
    }

    return results;
  }

  saveData() {
    client.service('account').patch(
      this.props.id,
      {
        str_services: this.state.data.join('\n')
      }
    ).then(res => {
      console.log(res);
    })
  }

  loadData() {
    client.service('account').get(
      this.props.id,
      {
        query: {
          $select: ['str_services']
        }
      }
    ).then(res => {
      this.setState({
        data: (typeof res.str_services !== 'undefined' && res.str_services) ? res.str_services.split('\n') : []
      })
    })
  }

  onChange(index, e) {
    let data = [...this.state.data];
    data[index] = e.target.value;

    this.setState({
      data: data
    })
  }

  remove(index) {
    let data = [...this.state.data];

    let removed = data.splice(index, 1);

    this.setState({
      data: data
    })
  }

  addService() {
    let data = [...this.state.data];
    data.push(this.state.value);
    this.setState({
      data: data
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.data !== prevState.data) {
      this.saveData();
    }
  }
}

export default ServiceManager;