import React, { Component } from 'react';
import '../../styles/front-end/articles.css';
import config from '../../config';
import { track } from '../../utility';

class Notices extends Component {
  render() {
    if (typeof this.props.notices === 'undefined')
      return '';

    if (this.props.notices.length <= 0)
      return '';
  
    return (
      <section key='mcg-content'>
        {(this.props.title.length > 0) ? <a id={this.props.block}><h2 className="animation fadeIn">{this.props.title}</h2></a> : ''}
        <div className="mcg-articles animation fadeIn">
          {this.renderNotices()}
        </div>
      </section>
    );
  }

  renderNotices() {
    let results = [];

    for (let index = 0; index < this.props.notices.length && index <= this.props.options.noticeLimit; index++) {
      const notice = this.props.notices[index];
      
      if (notice.bol_visible) {
        results.push(
        <div key={'mcg-notice-' + index} className="mcg-story">
          <div className="story-bubble">
            <img src={config.api + '/assets/' + this.props.company.region + '/' + this.props.company.id + '/profile/' + notice.str_image} alt={notice.str_description} />
          </div>
          <div className="story-inner">
            {/*<div className="story-source"><a href={notice.str_link} target="_blank" rel="noopener noreferrer">Article</a></div>*/}
            <div className="story-content">
              <a href={notice.str_link} onClick={() => {console.log('Track notice'); track(this.props.company.id, 'noticeInteract');}} target="_blank" rel="noopener noreferrer">
                <div className="story-headline">
                  {((typeof notice.str_title !== 'undefined' && notice.str_title.length > 143) ? notice.str_title.substring(0,140) + '...' : notice.str_title)}
                </div>
                <div className="story-message" dangerouslySetInnerHTML={{__html: ((typeof notice.str_description !== 'undefined') ? notice.str_description : '')}}></div>
              </a>
            </div>
          </div>
        </div>
        );
      }
    }

    return results;
  }
}

export default Notices;