import React, { Component } from 'react';
import Sortable from 'react-sortablejs';
import { resolve } from 'path';
import Config from '../../config';
import client from '../../feathers';

import './optionsmanager.css'

const defaultOptions = [
  {
    block: 'about',
    title: 'A little about us',
    order: 0,
    show: true
  },
  {
    block: 'featured',
    title: 'Featured Items',
    order: 1,
    show: true
  },
  {
    block: 'facebook',
    title: 'From our Facebook',
    order: 2,
    show: false
  },
  {
    block: 'twitter',
    title: 'Our Tweets',
    order: 3,
    show: false
  },
  {
    block: 'notices',
    title: 'Recent Happenings',
    order: 4,
    show: true
  },
  {
    block: 'gallery',
    title: 'From our Gallery',
    order: 5,
    show: true
  },
  {
    block: 'videos',
    title: 'Videos',
    order: 6,
    show: true
  },
  {
    block: 'files',
    title: 'Downloads',
    order: 7,
    show: true
  },
  {
    block: 'services',
    title: 'A bit of what we do',
    order: 8,
    show: true
  },
  {
    block: 'content',
    title: 'Content Area',
    order: 9,
    show: true
  },
  {
    block: 'contact',
    title: '',
    order: 10,
    show: true
  },
  {
    block: 'map',
    title: '',
    order: 11,
    show: true
  }
]

class OptionsManager extends Component {
	constructor(props) {
		super(props);

		this.state = {
      data: []
    };
    
    this.renderOptions = this.renderOptions.bind(this);
    this.loadData();
  }
  
  render() {
    return (
      <div className="dash-stat-block">
        <h3>Section Management</h3>
        <Sortable
          className="mcg-options-manager"
          onChange={(order, sortable, event) => {
            this.onChange(order);
          }}>
            {this.renderOptions()}
        </Sortable>
      </div>
    );
  }

  renderOptions() {
    let results = [];
    let options = this.state.data.sort((a, b) => { return a.order - b.order; });;

    for (let index = 0; index < options.length; index++) {
      const element = options[index];

      results.push(
      <div key={index} data-id={element.id} className='mcg-options-item'>
        <div className='mcg-options-controls'>
          <div className='mcg-options-order-area'>
            <i onClick={() => this.toggleVisibility(element.block)} className={(element.show) ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
            <i className="fas fa-sort"></i>
            <span className='mcg-options-order'>{element.order}</span>
          </div>
        </div>
        <div className='mcg-options-descriptors'>
          <div className='mcg-options-block'>{'#' + element.block}</div>
          <div className='mcg-options-input'>
            <input onBlur={() => this.saveField(element.block)} type="text" name={element.block} value={element.title} onChange={(e) => this.onTextChange(element.block, e)} placeholder="No header" />
          </div>
        </div>
      </div>);
    }
    return results;
  }

  onTextChange(block, event) {
    let data = this.state.data;
    data.find(e => e.block === block).title = event.currentTarget.value;
    this.setState({
      data: data
    })
  }

  saveField(block) {
    let target = this.state.data.find(e => e.block === block);

    client.service('profile-blocks').patch(
      target.id,
      {
        title: target.title
      }
    ).then(res => {
      let data = this.state.data;
      data.find(e => e.block === block).title = res.title;
      this.setState({
        data: data
      })
    })
  }

  toggleVisibility(block) {
    let target = this.state.data.find(e => e.block === block);

    client.service('profile-blocks').patch(
      target.id,
      {
        show: !target.show
      }
    ).then(res => {
      let data = this.state.data;
      data.find(e => e.block === block).show = res.show;
      this.setState({
        data: data
      })
    })
  }

  loadData() {
    client.service('profile-blocks').find({
      query: {
        customerId: this.props.id
      }
    }).then(data => {
      if (data.length > 0) {
        this.setState({
          data: data
        })
      } else {
        for (let i = 0; i < defaultOptions.length; i++) {
          const block = defaultOptions[i];

          block.customerId = this.props.id;

          client.service('profile-blocks').create(
            block
          ).then(res => {
            let newData = this.state.data;
            newData.push(res);

            this.setState({
              data: newData
            })
          }).catch(error => {
            console.error(error);
          });
        }
      }
    }).catch(error => {
      console.error(error);
    });
  }
  
  onChange(order) {
    let newOrder = [];
    let updateArray = [];
    for (let i = 0; i < order.length; i++) {
      let block = this.state.data.find(f => { return f.id.toString() === order[i].toString(); });
      block.order = i+1;
      newOrder.push(block);
      updateArray.push({
        id: order[i],
        order: i+1
      });
    }

    this.setState({ data: newOrder });

    console.log('newOrder', newOrder);

    fetch(Config.api + 'order-patch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: 'profile-blocks',
        orderArray: updateArray
      })
    }).then(response => {
      if (response.ok)
        return response.json();

      console.log('In response');
      throw response.json();
    }).then(data => {
      if (data.ok){
        console.log('Order updated successfully.');
      } else {
        console.log('Not OK: Failed to update order.');
      }
      resolve();
    }).catch(error => {
      console.error(error);
      console.log('Failed to update order.');
    })
  }
}

export default OptionsManager;