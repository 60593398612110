import React, { Component } from 'react';
import client from '../../feathers';
import Config from '../../config';
import Loader from '../loader.component';

class NoticeItem extends Component {
	constructor(props) {
    super(props);
    
    this.state = {
      data: this.props.data,
      processing: false
    }
  }

  render() {
    return (
      <div className="mcg-notice-item-container">
        {(typeof this.state.data === 'undefined') ? '' : (
          (this.state.processing) ? (
            <div className="mcg-notice-item-loading">
              <Loader />
            </div>
          ) : (
            <div className="mcg-notice-item">
              <div>
                <img className="mcg-notice-item-image" src={Config.api + '/assets/' + this.props.region + '/' + this.props.id + '/profile/' + this.state.data.str_image} alt={this.state.data.str_title} />
              </div>
              <div className="mcg-notice-descriptors">
                <div className="mcg-notice-buttons">
                  <div className="mcg-notice-button" onClick={() => this.toggleView()}>
                      <i className={'fas ' + ((this.state.data.bol_visible === true) ? 'fa-eye' : 'fa-eye-slash' )}></i> {(this.state.data.bol_visible === true) ? 'Visible' : 'Hidden'}
                  </div>
                  <div className="mcg-notice-button" onClick={() => this.removeItem()}>
                    <i className="far fa-trash-alt"></i> Delete
                  </div>
                </div>
                <div className="mcg-notice-details">
                  <div className="mcg-notice-item-title">
                    {this.state.data.str_title}
                  </div>
                  <div className="mcg-notice-item-link">
                    <a href={this.state.data.str_link} target={this.state.data.str_link_target}>{this.state.data.str_link}</a>
                  </div>
                  <div className="mcg-notice-item-description" dangerouslySetInnerHTML={this.getMarkup(this.state.data.str_description)}>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  }

  getMarkup(value) {
    return {__html: value};
  }

  toggleView() {
    this.setState({
      processing: true
    });

    client.service('profile-notices').patch(
      this.state.data.int_id,
      {
        bol_visible: !this.state.data.bol_visible
      }
    ).then(res => {
      this.setState({
        data: res,
        processing: false
      });
    }).catch(error => {
      console.error(error);
    })
  }

  removeItem() {
    this.setState({
      processing: true
    });

    client.service('profile-notices').remove(
      this.props.data.int_id
    ).then(res => {
      this.props.reload();
    }).catch(error => {
      console.error(error);
    })
  }
}

export default NoticeItem;