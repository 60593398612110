import React, { Component } from 'react';
import Sortable from 'react-sortablejs';
import Config from '../../config';
import { resolve } from 'path';
import client from '../../feathers';
import shortid from 'shortid';
import DashboardInput from '../dashboardinput';

import './linkmanager.styles.css';

class LinkManager extends Component {
	constructor(props) {
		super(props);

		this.state = {
      data: [],
      fileData: [],
      applicationOptions: [],
      text: '',
      link: '',
      type: 'external',
      status: '',
      statusMessage: '',
      application: 'Automotive'
    };
    
    this.addLink = this.addLink.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.renderLinks = this.renderLinks.bind(this);
    this.loadData = this.loadData.bind(this);
    this.removeItem = this.removeItem.bind(this);
	}

  render() {
    return (
      <div>
        <Sortable
          className="mcg-link-manager"
          onChange={(order, sortable, event) => {
            this.onChange(order);
          }}>
            {this.renderLinks()}
        </Sortable>
        {this.renderNotice()}
        <div className="mcg-notice-form-item">
          <DashboardInput label='Link Text' name='text' value={this.state.text} onChange={this.onFieldChange} />
          <div className="mcg-links-area">
            <label className="mcg-label">
              <span className="mcg-label-text">Link Type</span>
              <select className="form-dropdown" name="type" onChange={(value) => this.onSelectChange(value)}>
                <option value="external">External</option>
                <option value="internal">Internal</option>
                <option value="application">Application</option>
                <option value="file">File</option>
              </select>
            </label>
            <div className='mcg-flex-grow'>
            {(this.state.type === 'external' || this.state.type === 'internal') ? (
              <DashboardInput label='Link URL' name='link' value={this.state.link} onChange={this.onFieldChange} />
            ) : (this.state.type === 'application') ? (
              this.renderApplicationOptions()
            ) : (
              this.renderFileOptions()
            )}
            </div>
          </div>
          <label>
            <button className="mcg-form-button" type="button" onClick={this.addLink}>Add Link</button>
          </label>
        </div>
      </div>
    );
  }

  renderNotice() {
		switch(this.state.status) {
			case 'success':
				return <div className={'mcg-notice mcg-success mcg-popin'}><i className="fas fa-check"></i> {this.state.statusMessage}</div>
			case 'failed':
				return <div className={'mcg-notice mcg-failed mcg-popin'}><i className="fas fa-exclamation"></i> {this.state.statusMessage}</div>
			case 'uploading':
				return <div className={'mcg-notice mcg-popin'}>{this.state.statusMessage}</div>
			default:
				return <div className={'mcg-notice'}></div>;
		}
  }
  
  onSelectChange(event) {
    if (event.currentTarget.value === 'file' && this.state.fileData.length > 0) {
      this.setState({
        [event.currentTarget.name]: event.currentTarget.value,
        file: this.state.fileData[0]
      }, () => console.log('set selectfile state:',this.state));
    } else
      this.setState({
        [event.currentTarget.name]:  event.currentTarget.value
      }, () => console.log('set select state:',this.state));
  }
  
  onSelectFileChange(event) {
    console.log("on file change", [event.currentTarget.name],  this.state.fileData.find(e => e.name === event.currentTarget.value));
    this.setState({
      [event.currentTarget.name]:  this.state.fileData.find(e => e.name === event.currentTarget.value)
    }, () => console.log('set file state:',this.state));
  }

  onFieldChange(event) {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  renderApplicationOptions() {
    let results = [];

    for (let index = 0; index < this.state.applicationOptions.length; index++) {
      results.push(<option key={'ao-' + index} value={this.state.applicationOptions[index]}>{this.state.applicationOptions[index]}</option>);
    }

    return (
      <label className="mcg-label">
        <span className="mcg-label-text">Application</span>
        <select className="form-dropdown" name="application" onChange={(value) => this.onSelectChange(value)}>
          {results}
        </select>
      </label>
    )
  }

  renderFileOptions() {
    let results = [];

    for (let index = 0; index < this.state.fileData.length; index++) {
      results.push(<option key={'fo-' + index} value={this.state.fileData[index].name}>{this.state.fileData[index].name}</option>);
    }

    return (
      <label className="mcg-label">
        <span className="mcg-label-text">File</span>
        <select className="form-dropdown" name="file" onChange={(value) => this.onSelectFileChange(value)}>
          {results}
        </select>
      </label>
    )
  }

  addLink() {
    if (typeof this.props.id === 'undefined' && typeof this.props.region === 'undefined') {
      console.error('No ID or Region provided for notice.');
      this.setState({
        status: 'failed',
        statusMessage: 'There is an issue with the form, please contact support to resolve the issue.'
      });

      return;
    }

    switch(this.state.type) {
      case 'application':
      case 'file':
        break;
      case 'internal':
        if (this.state.link.length <= 0) {
          console.error('Invalid form input, please double check your link url and try again.');
    
          this.setState({
            status: 'failed',
            statusMessage: 'Invalid form input, please double check your link url and try again.'
          });
    
          return;
        }
        break;
      default:
        if ((this.state.link.length <= 0) || (!(this.state.link.includes('http://') || this.state.link.includes('https://')) || !this.state.link.includes('.'))) {
          console.error('Invalid form input, please double check your link url and try again.');
    
          this.setState({
            status: 'failed',
            statusMessage: 'Invalid form input, please double check your link url and try again.'
          });
    
          return;
        }
        break;
    }
    
    if (this.state.text.length <= 0) {
      console.error('Invalid form input, please provide text for your link and try again.');

      this.setState({
        status: 'failed',
        statusMessage: 'Invalid form input, please provide text for your link and try again.'
      });

      return;
    }

    let element = {};
    let lastorder = 0;

    if (this.state.data.length > 0) {
      this.state.data.forEach(i => {
        lastorder = i.order;
      })
    }
    lastorder++;

    element.customerId = this.props.id;
    element.order = lastorder;
    element.text = this.state.text;
    switch(this.state.type) {
      case 'application':
        console.log(this.state.application)
        element.link = '/' + this.state.application.replace('sales flyer', 'marketplace');
        break;
      case 'file':
        console.log("file link", 'https://api.mcgmedia.net/assets/' + this.props.region + '/' + this.props.id + '/profile/'  + this.state.file.name);
        element.link = 'https://api.mcgmedia.net/assets/' + this.props.region + '/' + this.props.id + '/profile/'  + this.state.file.name;
        break;
      case 'internal':
      case 'external':
      default:
        element.link = this.state.link;
        break;
    }

    console.log('Saving: ',element);

    client.service('profile-links').create(
      element
    ).then(result => {
      console.log(result);
      let newData = this.state.data;
      newData.push(result);
      this.setState({
        data: newData
      })
    }).catch(error => {
      console.error(error);
    });
  }

  renderLinks() {
    let results = [];
    let links = this.state.data.sort((a, b) => { return a.order - b.order; });;

    for (let index = 0; index < links.length; index++) {
      const element = links[index];
      results.push(
      <div key={element.id} data-id={element.id} className='mcg-links-item'>
        <span className='mcg-links-icon-area'>
          <div className='mcg-remove' onClick={this.removeItem} data-id={element.id}><i className="fas fa-trash"></i></div>
          <i className="fas fa-sort"></i>
          <span className='mcg-links-order'>{element.order}</span>
        </span>
        <div className='mcg-links-descriptors'>
        <span>{element.text}</span>
        <span className='mcg-links-url'>{element.link}</span>
        </div>
      </div>);
    }
    return results;
  }

  removeItem(event) {
    console.log('remove', event.currentTarget.dataset.id);
    client.service('profile-links').remove(
      event.currentTarget.dataset.id
    ).then(result => {
      console.log(result);
      this.setState({
        data: this.state.data.filter(item => { return item.id !== result.id})
      })
    }).catch(error => {
      console.error(error);
    });
  }

  loadData() {
    console.log('loadData 129 links')
    client.service('profile-links').find({
      query: {
        customerId: this.props.id
      }
    }).then(result => {
      let dataList = [];
      result.forEach(data => {
        dataList.push({
          id: data.id,
          link: data.link,
          text: data.text,
          order: data.order,
          uid: shortid.generate()
        });
      })

      this.setState({
        data: dataList
      });
    }).catch(error => {
      console.error(error);
    });

    this.loadFiles();

    console.log('Get roles');
    fetch(Config.api + 'get-roles', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: this.props.id
      })
    }).then(response => {
      if (response.ok)
        return response.json();

      throw response.json();
    }).then(data => {
      let applist = [];
      for (let i = 0; i < data.roles.length; i++) {
        const element = data.roles[i].Rolename;
        switch(element) {
          case 'Hub':
          case 'Mobile':
          case 'Events':
            break;
          default:
            applist.push(element);
            break;
        }
      }
      console.log('roles', applist)
      this.setState({
        applicationOptions: applist,
        application: applist[0]
      })
    })
  }

  loadFiles() {
    if (typeof this.props === 'undefined' || typeof this.props.id === 'undefined' || !this.props.id)
      return;

    client.service('profile-files').find({
      query: {
        int_customer_id: this.props.id
      }
    }).then(result => {
      if (result.length !== this.state.fileData.length){
        let files = [];
        result.forEach(file => {
          files.push({
            id: file.int_id,
            name: file.str_file,
            text: file.str_title,
            order: file.int_order,
            uid: shortid.generate()
          });
        })

        this.setState({
          fileData: files,
          file: files[0].name
        });
      }
    }).catch(error => {
      console.error(error);
    });
  }

  onChange(order) {
    let newOrder = [];
    let updateArray = [];
    for (let i = 0; i < order.length; i++) {
      let file = this.state.data.find(f => { return f.id.toString() === order[i].toString(); });
      file.order = i+1;
      newOrder.push(file);
      updateArray.push({
        id: order[i],
        order: i+1
      });
    }

    this.setState({ data: newOrder });

    console.log('newOrder',newOrder);

    fetch(Config.api + 'order-patch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: 'profile-links',
        orderArray: updateArray
      })
    }).then(response => {
      if (response.ok)
        return response.json();

      console.log('In response');
      throw response.json();
    }).then(data => {
      if (data.ok){
        console.log('Order updated successfully.');
      } else {
        console.log('Not OK: Failed to update order.');
      }
      resolve();
    }).catch(error => {
      console.error(error);
      console.log('Failed to update order.');
    })
  }

  componentDidMount() {
    this.loadData();
    var intervalId = setInterval(this.loadFiles.bind(this), 5000);
    this.setState({intervalId});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }
}

export default LinkManager;