import React, { Component } from 'react';
import '../../styles/front-end/hero.css';

class Hero extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ratio: 40
    };

    this.imageAdjustments = this.imageAdjustments.bind(this);

    this.imageAdjustments();
  }

  render() {
    if (typeof this.props.company.profile === 'undefined')
      return '';

    return (
      <div className='hero-image' style={{
        backgroundImage: 'url(' + this.props.company.profile.header + ')',
        marginTop: '70px'
        }}>
        
      </div>
    );
  }

  imageAdjustments() {
    if (typeof this.props.company.profile === 'undefined')
      return;

    let img = new Image();
    img.onload = (i) => {
      let ratio = (img.height / img.width * 100);

      this.setState({
        ratio: ratio
      })
    }
    img.src = this.props.company.profile.header;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.headerHeight !== this.props.headerHeight) {
      this.setState({ headerHeight: this.props.headerHeight });
      this.imageAdjustments();
    }
  }
}

export default Hero;