import React, { Component } from 'react';
import '../../styles/front-end/videos.css';

class Videos extends Component {
	constructor(props) {
		super(props);

		this.state = {
      videos: this.props.videos
    };
  }

  render() {
    if (this.props.videos.length <= 0)
      return '';

    return (
      <section id={this.props.block}>
        {(this.props.title.length > 0) ? <h2 className="animation fadeIn">{this.props.title}</h2> : ''}
        <div className="video-list">
          {this.renderVideos()}
        </div>
      </section>
    );
  }

  renderVideos() {
    let results = [];

    for (let index = 0; index < this.state.videos.length; index++) {
      const element = this.state.videos[index];
      
      let src = new URLSearchParams(element.link.split('?')[1]).get('v');
      
      if (!src)
        src = element.link.replace('https://youtu.be/', '');

      results.push(
        <div key={'video-' + index} className='video-item'>
          <div className="video-image-area">
            <a href={element.link} target="_blank" rel="noopener noreferrer">
              <img className='video-thumbnail' src={'https://img.youtube.com/vi/' + src + '/hqdefault.jpg'} alt={element.caption} />
            </a>
          </div>
          <a href={element.link} target="_blank" rel="noopener noreferrer">
            <div className='video-caption'>{element.caption}</div>
          </a>
        </div>
      );
    }

    return results;
  }

  static getDerivedStateFromProps (props, state) {
    state.videos = props.videos;

    return state;
  }
}

export default Videos;