import React, { Component } from 'react';
import NoticeItem from './noticeitem.component';

class NoticeList extends Component {
	constructor(props) {
		super(props);

		this.state = {
      data: this.props.notices
    }

    this.renderListItems = this.renderListItems.bind(this);
  }
  
  render() {
    return (
      <div>
        {this.renderListItems()}
      </div>
    );
  }

  renderListItems() {
    if (typeof this.state.data === 'undefined') return;
    let results = [];

    for (let index = this.state.data.length-1; index >= 0; index--) {
      results.push(<NoticeItem key={'notice' + index} id={this.props.id} region={this.props.region} data={this.state.data[index]} reload={this.props.reload} />);
    }

    return results
  }

  static getDerivedStateFromProps (props, state) {
    state.data = props.notices;

    return state;
  }
}

export default NoticeList;