import React, { Component } from 'react';
import Item from './item';
import './automotive.css';

const displayItems = 9;

class Automotive extends Component {
	constructor(props) {
		super(props);

		this.state = {
      list: this.props.items,
      filters: [],
      loading: false
    };
    
    console.log(this.props.items);

    this.itemDisplayArea = React.createRef();
  }

  render() {
    console.log(this.state);
    return (
      <div className="auto-item-wrapper">
        <div className="auto-item-list">
          {this.renderItems()}
        </div>
      </div>
    );
  }

  renderItems() {
    if (typeof this.state.list === 'undefined') return;
    let results = [];

    let filterCounter = 0;
    for (let index = 0; index < this.state.list.length; index++) {
      const item = this.state.list[index];

      if (!item.sold)
        results.push(<Item {...this.props} key={'automotive' + item.id} item={item} />);

      if (!item.filtered) filterCounter++;
    }

    return results;
  }

  getPrice(item) {
    if (typeof item.salePrice !== 'undefined' && item.salePrice)
      return parseFloat(item.salePrice);
    return parseFloat(item.regularPrice);
  }

  sortList() {

  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      console.log(this.props.items);
      let list = [];
      for (let index = 0; index < this.props.items.length; index++) {
        var item = this.props.items[index];
        item.filtered = false;
        list.push(item);
      }
      this.setState({list}, this.sortList);
    }
  }
}

export default Automotive;