import React, { Component } from 'react';
import Item from './item';
import './restaurant.css'
import BlockItem from './blockItem';

class Restaurant extends Component {
	constructor(props) {
		super(props);

		this.state = {
      day: (new Date()).getDay()
    }
  }
  render() {
    if (typeof this.props.options === 'undefined') {
      return '';
    }

    return (
      <div className="restaurant-wrapper">
        <div className="restaurant-day-select">
          <h2>Day Selector</h2>
          <div className="restaurant-day-group">
            <div className={(this.state.day === 1) ? 'restaurant-day selected' : 'restaurant-day'} onClick={() => this.setState({ day: 1 })}>Monday</div>
            <div className={(this.state.day === 2) ? 'restaurant-day selected' : 'restaurant-day'} onClick={() => this.setState({ day: 2 })}>Tuesday</div>
            <div className={(this.state.day === 3) ? 'restaurant-day selected' : 'restaurant-day'} onClick={() => this.setState({ day: 3 })}>Wednesday</div>
            <div className={(this.state.day === 4) ? 'restaurant-day selected' : 'restaurant-day'} onClick={() => this.setState({ day: 4 })}>Thursday</div>
            <div className={(this.state.day === 5) ? 'restaurant-day selected' : 'restaurant-day'} onClick={() => this.setState({ day: 5 })}>Friday</div>
            <div className={(this.state.day === 6) ? 'restaurant-day selected' : 'restaurant-day'} onClick={() => this.setState({ day: 6 })}>Saturday</div>
            <div className={(this.state.day === 0) ? 'restaurant-day selected' : 'restaurant-day'} onClick={() => this.setState({ day: 0 })}>Sunday</div>
          </div>
        </div>
        {(this.props.options.restaurantDisplayType === 'menu') ? (
          <div className="restaurant-container">
            <div className="restaurant-time">
              <h3>Breakfast</h3>
              {this.renderItems(1)}
            </div>
            <div className="restaurant-time">
              <h3>Lunch</h3>
              {this.renderItems(2)}
            </div>
            <div className="restaurant-time">
              <h3>Dinner</h3>
              {this.renderItems(3)}
            </div>
            <div className="restaurant-time">
              <h3>Desserts</h3>
              {this.renderItems(5)}
            </div>
            <div className="restaurant-time">
              <h3>Drink Specials</h3>
              {this.renderItems(4)}
            </div>
          </div>
        ):(
          <div className="restaurant-container">
            <div className="restaurant-time">
              <h3>Breakfast {(typeof this.props.items !== 'undefined') ? this.props.items.filter(e => e.dayId === this.state.day && e.timeId === 1).count : 'Not there'}</h3>
              <div className="restaurant-block-time">
                {this.renderBlockItems(1)}
              </div>
            </div>
            <div className="restaurant-time">
              <h3>Lunch</h3>
              <div className="restaurant-block-time">
                {this.renderBlockItems(2)}
              </div>
            </div>
            <div className="restaurant-time">
              <h3>Dinner</h3>
              <div className="restaurant-block-time">
                {this.renderBlockItems(3)}
              </div>
            </div>
            <div className="restaurant-time">
              <h3>Desserts</h3>
              <div className="restaurant-block-time">
                {this.renderBlockItems(5)}
              </div>
            </div>
            <div className="restaurant-time">
              <h3>Drink Specials</h3>
              <div className="restaurant-block-time">
                {this.renderBlockItems(4)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderItems(time) {
    if (typeof this.props.company === 'undefined'|| typeof this.props.items === 'undefined') return;
    let results = [];

    for (let index = 0; index < this.props.items.length; index++) {
      const item = this.props.items[index];
      if (item.dayId === this.state.day && item.timeId === time)
        results.push(
          <Item key={'restaurant' + index} item={item} company={this.props.company} />
        )
    }

    return results;
  }

  renderBlockItems(time) {
    if (typeof this.props.company === 'undefined'|| typeof this.props.items === 'undefined') return;
    let results = [];

    for (let index = 0; index < this.props.items.length; index++) {
      const item = this.props.items[index];
      if (item.dayId === this.state.day && item.timeId === time)
        results.push(
          <BlockItem key={'restaurant' + index} item={item} company={this.props.company} />
        )
    }

    return results;
  }


  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company)
      this.setState({company: this.props.company});

    if (prevProps.items !== this.props.items)
      this.setState({list: this.props.items});
    
  }
}

export default Restaurant;