import React, { Component } from 'react';
import LinkManager from '../../../components/LinkManager/linkmanager.component';
import NoticeForm from '../../../components/NoticeForm/noticeform.component';
import ServiceManager from '../../../components/ServiceManager/servicemanager';
import ContentManager from '../../../components/ContentAreaManager/contentmanager';
import FileUpload from '../../../components/FileUpload/fileupload.component';

class Content extends Component {
  render() {
    return (
      <div>
        <h2>Content</h2>
        <div className="dash-stat-block">
          <h3>Site Navigation</h3>
          <LinkManager id={this.props.id}
            region={this.props.region} />
        </div>
        <div className="dash-stat-block">
          <h3>Files</h3>
          <FileUpload id={this.props.id}
            region={this.props.region}
            destination="/profile/"
            type="profile-files"
            uploadType="files"
            multiple={true}
            service="profile-files" />
        </div>
        <div className="dash-stat-block">
          <h3>Notices</h3>
          <NoticeForm id={this.props.id}
            region={this.props.region}
            options={{
              outputFormat: 'jpeg',
              targetWidth: 512,
              targetHeight: 512,
              crop: true
            }} />
        </div>
        <ServiceManager id={this.props.id} />
        <ContentManager id={this.props.id} />
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({
        id: this.props.id
      })
    }
    if (prevProps.region !== this.props.region) {
      this.setState({
        region: this.props.region
      })
    }
  }
}

export default Content;