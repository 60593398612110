import React, { Component } from 'react';
import Switch from "react-switch";
import client from '../../feathers';

class PaymentCalculatorOptions extends Component {
	constructor(props) {
		super(props);

		this.state = {
      value: ''
    };
    
    this.onChange = this.onChange.bind(this);
    this.saveData = this.saveData.bind(this);
    this.saveToggleData = this.saveToggleData.bind(this);
    
    this.loadData();
  }

  render() {
    return (
      <div className="dash-stat-block">
        <h3>Payment Calculator Defaults</h3>
        <div className='mcg-details-item'>
          <div className='mcg-details-descriptor'>Show Payment Calculator</div>
          <div className='mcg-details-input'>
            <Switch checked={this.state.bol_ao_show_payment_calc || false}
              onChange={(value) => { this.setState({ bol_ao_show_payment_calc: !this.state.bol_ao_show_payment_calc }, () => this.saveToggleData('bol_ao_show_payment_calc', value)); }}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={24}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={14}
              width={36} />
          </div>
        </div>
        <div className='mcg-details-item'>
          <div className='mcg-details-descriptor'>Show Price Area</div>
          <div className='mcg-details-input'>
            <Switch checked={this.state.bol_ao_show_price_area || false}
              onChange={(value) => { this.setState({ bol_ao_show_price_area: !this.state.bol_ao_show_price_area }, () => this.saveToggleData('bol_ao_show_price_area', value)); }}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={24}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={14}
              width={36} />
          </div>
        </div>
        <div className='mcg-details-item'>
          <div className='mcg-details-descriptor'>Interest Rate</div>
          <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('flt_ao_payment_rate', e)} type="text" name="flt_ao_payment_rate" value={this.state.flt_ao_payment_rate} onChange={(e) => this.onChange('flt_ao_payment_rate', e)} />
          </div>
        </div>
        <div className='mcg-details-item'>
          <div className='mcg-details-descriptor'>Term Length</div>
          <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('int_ao_payment_term', e)} type="text" name="int_ao_payment_term" value={this.state.int_ao_payment_term} onChange={(e) => this.onChange('int_ao_payment_term', e)} />
          </div>
        </div>
      </div>
    );
  }

  saveToggleData(selector, value) {
    client.service('account').patch(
      this.props.id,
      {
        [selector]: value
      }
    ).then(res => {
    }).catch(err => {
      console.error(err);
    })
  }

  saveData(selector, e) {
    client.service('account').patch(
      this.props.id,
      {
        [selector]: e.target.value
      }
    ).then(res => {
    }).catch(err => {
      console.error(err);
    })
  }

  onChange(selector, e) {
    this.setState({ [selector]: e.target.value });
  }

  loadData() {
    client.service('account').get(
      this.props.id,
      {
        query: {
          $select: [
            'bol_ao_show_payment_calc',
            'bol_ao_show_price_area',
            'flt_ao_payment_rate',
            'int_ao_payment_term'
          ]
        }
      }
    ).then(res => {
      this.setState({
        bol_ao_show_payment_calc: !!+res.bol_ao_show_payment_calc,
        bol_ao_show_price_area: !!+res.bol_ao_show_price_area,
        flt_ao_payment_rate: res.flt_ao_payment_rate,
        int_ao_payment_term: res.int_ao_payment_term
      })
    })
  }
}

export default PaymentCalculatorOptions;