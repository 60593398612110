import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import { convertToCurrency } from '../../../../utility';
import { resolve } from 'path';
import client from '../../../../feathers';
import Config from '../../../../config';

import "../../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";

class ItemDetails extends Component {
	constructor(props) {
    super(props);

    this.state = {
      company: this.props.company
    }

    this.getItem();
    console.log(this.props);
  }

  render() {
    if (typeof this.state.item === 'undefined' || typeof this.state.company === 'undefined') return '';

    return (
      <div className="item-details-wrapper">
        <div className="item-details-back-link">
          <a href="/automotive"><i className="fas fa-angle-double-left"></i> Back to automotive</a>
        </div>
        <div className="item-details-container">
          <div className="item-details-images">
            <ImageGallery items={this.state.item.images}
              showPlayButton={false}
              showFullscreenButton={false} />
          </div>
          <div className="item-details-sidearea">
            <h2 className="auto-details-title">{this.state.item.year} {this.state.item.make} {this.state.item.model}</h2>
            <div className="auto-details-price">
              {this.renderPrice()}
            </div>
            <div className="auto-details-list">
              <div className={(this.state.item.stockNo == null) ? 'hide' : 'detail-item'}>
                <span className="detail-item-label">Stock #</span><span className="detail-item-value">{this.state.item.stockNo}</span>
              </div>
              <div className={(this.state.item.vin == null) ? 'hide' : 'detail-item'}>
                <span className="detail-item-label">Vin</span><span className="detail-item-value">{this.state.item.vin}</span>
              </div>
              <div className={(this.state.item.mileage == null) ? 'hide' : 'detail-item'}>
                <span className="detail-item-label">Mileage</span><span className="detail-item-value">{this.state.item.mileage}</span>
              </div>
              <div className={(this.state.item.year == null) ? 'hide' : 'detail-item'}>
                <span className="detail-item-label">Year</span><span className="detail-item-value">{this.state.item.year}</span>
              </div>
              <div className={(this.state.item.make == null) ? 'hide' : 'detail-item'}>
                <span className="detail-item-label">Make</span><span className="detail-item-value">{this.state.item.make}</span>
              </div>
              <div className={(this.state.item.model == null) ? 'hide' : 'detail-item'}>
                <span className="detail-item-label">Model</span><span className="detail-item-value">{this.state.item.model}</span>
              </div>
              <div className={(this.state.item.trim == null) ? 'hide' : 'detail-item'}>
                <span className="detail-item-label">Trim</span><span className="detail-item-value">{this.state.item.trim}</span>
              </div>
              <div className={(this.state.item.color == null) ? 'hide' : 'detail-item'}>
                <span className="detail-item-label">Color</span><span className="detail-item-value">{this.state.item.color}</span>
              </div>
              <div className={(this.state.item.cylinder == null) ? 'hide' : 'detail-item'}>
                <span className="detail-item-label">Cylinders</span><span className="detail-item-value">{this.state.item.cylinder}</span>
              </div>
              <div className={(this.state.item.displacement == null) ? 'hide' : 'detail-item'}>
                <span className="detail-item-label">Displacement</span><span className="detail-item-value">{this.state.item.displacement}</span>
              </div>
              <div className={(this.state.item.drivetrain == null) ? 'hide' : 'detail-item'}>
                <span className="detail-item-label">Drivetrain</span><span className="detail-item-value">{this.state.item.drivetrain}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={(this.state.item.description == null) ? 'hide' : 'auto-description'}>
          <h3>Overview</h3>
          <div>
            {this.state.item.description}
          </div>
        </div>
      </div>
    );
  }

  renderPrice() {
    let item = this.state.item;

    if (item.paymentType != null) {
      return <div>{convertToCurrency(item.altPrice) + ' ' + item.paymentType}</div>;
    } else if (item.specialPrice != null) {
      return <div>
        <div className="sale-crossout">{convertToCurrency(item.price)}</div>
        <div>{convertToCurrency(item.specialPrice)}</div>
      </div>
    } else if (item.price != null) {
      return <div>{convertToCurrency(item.price)}</div>;
    } else {
      return <div>Call for price</div>
    }
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company)
      this.setState({company: this.props.company}, this.getDetails);
    

    if (prevProps.items !== this.props.items)
      this.getItem();
  }

  getItem() {
    if (typeof this.props.items === 'undefined') return;
    
    let item = this.props.items.filter(e => e.id === this.props.match.params.id)[0];
    let images = [];
    for (let i = 0; i < item.images.length; i++) {
      const image = item.images[i];
      images.push({
        original: 'https://images.cwportals.com/automotive/' + this.state.company.region + '/' + this.state.company.id + '/' + this.props.match.params.id + '/' + image.image + '.jpg',
        originalAlt: item.title,
        thumbnail: 'https://images.cwportals.com/automotive/' + this.state.company.region + '/' + this.state.company.id + '/' + this.props.match.params.id + '/' + image.image + '.jpg',
        thumbnailAlt: item.title
      })
    }
    item.images = images;

    this.setState({item}, () => this.loadFeatured());

  }

  loadFeatured() {
    fetch(Config.api + 'get-automotive-features', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: this.state.item.id
      })
    }).then(response => {
      if (response.ok)
        return response.json();

      console.log('Features response', response);
      throw response.json();
    }).then(data => {
      console.log('features return', data);
      
      resolve();
    }).catch(error => {
      console.error(error);
      console.log('Failed to retrieve features.');
    })
  }
}

export default ItemDetails;