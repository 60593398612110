import React, { Component } from 'react';
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import '../../styles/front-end/footer.css';
import { track } from '../../utility';

class Footer extends Component {
	constructor(props) {
		super(props);

		this.state = {
      links: this.props.links
    };
  }

  render() {
    if (typeof this.props.company.address === 'undefined')
      return '';
    
    return (
      <footer className="animation fadeIn">
        <div className="footer-links">
          {this.renderLinks()}
        </div>
        <div className="footer-information">
          {this.renderSocialLinks()}
        </div>
        
        <div className="footer-credit">
          <div className="f-location">
            <ul>
              <li>{this.props.company.address.street} {this.props.company.address.city} {this.props.company.address.province} {this.props.company.address.postalCode}</li>
              <li><a href={'tel:' + this.props.company.contact.phone}>{this.props.company.contact.phone}</a></li>
              <li><a href={'mailto:' + this.props.company.contact.email}>{this.props.company.contact.email}</a></li>
            </ul>
          </div>
          <div className="f-copyright">
            <div>Copyright &copy;{(new Date().getFullYear())} {this.props.company.name}</div>
            <div>Website from <a href="https://mcgmedia.net/" target="_blank" rel="noopener noreferrer">MCG Media</a></div>
          </div>
        </div>
      </footer>
    );
  }

  renderLinks() {
    if (typeof this.state.links === 'undefined')
      return '';

    let results = [];

    for (let index = 0; index < this.state.links.length; index++) {
      const element = this.state.links[index];
      
      results.push(
        (element.link.substring(0,1) === '#') ? (
          <HashLink key={'f-link-' + index} onClick={() => { console.log('Track interaction'); track(this.props.company.id, 'interact'); }} smooth to={element.link}>{element.label}</HashLink>
        ) : (
          <a key={'f-link-' + index} href={element.link} onClick={() => {console.log('Track navigation'); track(this.props.company.id, 'navigation');}}>{element.label}</a>
        )
      );
    }
    results.push(
      <Link key={'f-link-login'} className="f-login" to="/dashboard">Login</Link>
    );

    return results;
  }

  renderSocialLinks() {
    if (typeof this.props.company.social === 'undefined')
      return;
    
    let blocks = [];
    const social = this.props.company.social;

    if (typeof social.instagram !== 'undefined' && social.instagram && social.instagram.length > 0) {
      // render insta block
      blocks.push(
      <div key="f-instagram" className="f-social-link">
        <a className="f-social-a" href={'https://www.instagram.com/' + social.instagram} onClick={() => {console.log('Track social'); track(this.props.company.id, 'socialInteract');}}>
          <div className="f-social-label">Instagram</div>
          <div className="f-social-icon"><i className="fab fa-instagram"></i></div>
        </a>
      </div>)
    }

    if (typeof social.facebook !== 'undefined' && social.facebook && social.facebook.length > 0) {
      // render insta block
      blocks.push(
      <div key="f-facebook" className="f-social-link">
        <a className="f-social-a" href={'https://www.facebook.com/' + social.facebook} onClick={() => {console.log('Track social'); track(this.props.company.id, 'socialInteract');}}>
          <div className="f-social-label">Facebook</div>
          <div className="f-social-icon"><i className="fab fa-facebook-square"></i></div>
        </a>
      </div>)
    }

    if (typeof social.youtube !== 'undefined' && social.youtube && social.youtube.length > 0) {
      // render insta block
      blocks.push(
      <div key="f-youtube" className="f-social-link">
        <a className="f-social-a" href={'https://www.youtube.com/' + social.youtube} onClick={() => {console.log('Track social'); track(this.props.company.id, 'socialInteract');}}>
          <div className="f-social-label">Youtube</div>
          <div className="f-social-icon"><i className="fab fa-youtube"></i></div>
        </a>
      </div>)
    }

    if (typeof social.linkedin !== 'undefined' && social.linkedin && social.linkedin.length > 0) {
      // render insta block
      blocks.push(
      <div key="f-linkedin" className="f-social-link">
        <a className="f-social-a" href={'https://www.linkedin.com/' + social.linkedin} onClick={() => {console.log('Track social'); track(this.props.company.id, 'socialInteract');}}>
          <div className="f-social-label">Linkedin</div>
          <div className="f-social-icon"><i className="fab fa-linkedin"></i></div>
        </a>
      </div>)
    }

    if (typeof social.twitter !== 'undefined' && social.twitter && social.twitter.length > 0) {
      // render insta block
      blocks.push(
      <div key="f-twitter" className="f-social-link">
        <a className="f-social-a" href={'https://www.twitter.com/' + social.twitter} onClick={() => {console.log('Track social'); track(this.props.company.id, 'socialInteract');}}>
          <div className="f-social-label">Twitter</div>
          <div className="f-social-icon"><i className="fab fa-twitter-square"></i></div>
        </a>
      </div>)
    }

    if (blocks.length > 0) {
      return (
        <div className="f-social">
        {blocks}
      </div>);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company !== this.props.company)
      this.setState({company: this.props.company});

    if (prevProps.links !== this.props.links)
      this.setState({links: this.props.links});
  }
}

export default Footer;