import React, { Component } from 'react';
import '../styles/front-end/statusnotice.css';

class StatusNotice extends Component {
  render() {
    return this.renderNotice();
  }
	
	renderNotice() {
		switch(this.props.status) {
			case 'success':
				return <div className="mcg-status mcg-success mcg-popin"><i className="fas fa-check"></i> {this.props.message}</div>
			case 'failed':
				return <div className="mcg-status mcg-failed mcg-popin"><i className="fas fa-exclamation"></i> {this.props.message}</div>
			case 'waiting':
				return <div className="mcg-status mcg-popin">{this.props.message}</div>
			default:
				return <div className="mcg-status" />;
		}
	}
}

export default StatusNotice;