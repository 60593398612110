import React, { Component } from 'react';

import './front-end.css';

import config from './config';
import client from './feathers';

import Footer from './containers/front-end/footer';
import Header from './containers/front-end/header';
import Navigation from './containers/front-end/navigation';
import Hero from './containers/front-end/hero';
import Contact from './containers/front-end/contact';
import Files from './containers/front-end/files';
import Videos from './containers/front-end/videos';
import ParallaxGallery from './containers/front-end/parallaxGallery';
import ListGallery from './containers/front-end/listGallery';
import Facebook from './containers/front-end/facebook';
import Notices from './containers/front-end/notices';

import Slider from './components/slider';
import Featured from './containers/front-end/routes/featured/featured';
import Toaster from "toasted-notes";
import "toasted-notes/src/styles.css";

class FrontEnd extends Component {
	constructor(props) {
		super(props);

		this.state = {
      company: this.props.company,
      show: this.props.show,
      showGallery: false,
      links: [],
      articles: [],
      notices: [],
      gallery: [],
      videos: [],
      files: [],
      facebookData: this.props.facebookData,
      dataLoaded: false,
      options: this.props.options,
      didAdblockCheck: false
    };

    if (typeof this.props.id !== 'undefined')
      this.loadData(this.props.id, false);

    this.toggleNav = this.toggleNav.bind(this);
    this.toggleGallery = this.toggleGallery.bind(this);
  }

  render() {
    if (typeof this.state.company !== 'undefined') {
      return (
        <div className="mcg-app-wrapper">
          <Navigation
            links={this.state.links}
            show={this.state.show}
            toggleNav={this.toggleNav} />
          <Header
            company={this.state.company}
            links={this.state.links}
            toggleNav={this.toggleNav}
            cart={this.props.cart}
            toggleCart={this.props.toggleCart} />
          <Hero company={this.state.company} />
          {this.renderBlocks()}
          <Footer company={this.state.company} links={this.state.links} />
          {(typeof this.state.company !== 'undefined' && this.state.gallery.length > 0) ? (
            <Slider
              images={this.state.gallery}
              id={this.state.company.id}
              region={this.state.company.region}
              show={this.state.showGallery}
              toggleGallery={this.toggleGallery} />
          ) : ''}
        </div>
      )
    } else {
      return ''
    }
  }

  renderBlocks() {
    if (typeof this.state.company === 'undefined')
      return;
    if (typeof this.state.blocks === 'undefined')
      return;

    let results = [];
    let blocks = this.state.blocks.sort((a, b) => { return a.order - b.order; });

    for (let index = 0; index < blocks.length; index++) {
      results.push(this.getBlock(blocks[index]));
    }

    return (
      <div className="main">
        {results}
      </div>
    )
  }

  getBlock(block) {
    if (typeof block === 'undefined' || typeof this.state.company === 'undefined')
      return '';

    switch(block.block) {
      case 'about':
        if (!block.show || typeof this.state.company.profile === 'undefined')
          return '';

        return (
            <section key={'mcg-key-' + block.block} id={block.block}>
              {(block.title.length > 0) ? <h2 className="animation fadeIn">{block.title}</h2> : ''}
              <p className="animation fadeIn">{this.state.company.profile.about}</p>
            </section>
        );
      case 'featured':
        if (!block.show || typeof this.state.options === 'undefined')
          return '';

        return (
            <section key={'mcg-key-' + block.block} id={block.block}>
              {(block.title.length > 0) ? <h2 className="animation fadeIn">{block.title}</h2> : ''}
              <Featured {...this.props} options={this.state.options} />
            </section>
          );
      case 'facebook':
        if (!block.show || typeof this.state.options === 'undefined')
          return '';

        return <Facebook key={'mcg-key-' + block.block} block={block.block} title={block.title} customerId={this.state.company.id} facebookUsername={this.state.company.social.facebook} options={this.state.options} />
      case 'gallery':
        if (!block.show)
          return '';

        return (
          <ListGallery key={'mcg-key-' + block.block}
            block={block.block}
            images={this.state.gallery}
            title={block.title}
            id={this.state.company.id}
            region={this.state.company.region} />
        )
      case 'videos':
        if (!block.show)
          return '';

        return <Videos key={'mcg-key-' + block.block} block={block.block} videos={this.state.videos} title={block.title} />
      case 'files':
        if (!block.show)
          return '';

        return <Files key={'mcg-key-' + block.block} block={block.block} files={this.state.files} title={block.title} id={this.state.company.id} region={this.state.company.region} />
      case 'services':
        if (!block.show)
          return '';

        return (
          <section key={'mcg-key-' + block.block} id={block.block} className="services-container">
            <h2 className="service-title animation fadeIn">
              {(block.title.length > 0) ? block.title : 'Services'}
            </h2>
            {this.renderServices()}
          </section>
        );
      case 'content':
        if (!block.show || typeof this.state.company.profile === 'undefined' || this.state.company.profile.contentArea.length <= 0)
          return '';

        return (
          <section key={'mcg-key-' + block.block} id={block.block}>
            {(block.title.length > 0) ? <h2 className="animation fadeIn">{block.title}</h2> : ''}
            <div className="mcg-content-area animation fadeIn" dangerouslySetInnerHTML={{__html: this.state.company.profile.contentArea}}></div>
          </section>
        );
      case 'contact':
        if (!block.show || typeof this.state.options === 'undefined')
          return '';

        return <Contact key={'mcg-key-' + block.block} block={block.block} company={this.state.company} title={block.title} options={this.state.options} />
      case 'map':
        if (!block.show)
          return '';
          
        return (
          <section key={'mcg-key-' + block.block} id={block.block} className="map-section animation fadeIn">
            {(block.title.length > 0) ? <h2 className="animation fadeIn">{block.title}</h2> : ''}
            <iframe title="location-map"
            src={this.getMapLink()}
            frameBorder="0"
            style={{border:0, width: '100%', height: '100%'}}></iframe>
          </section>
        );
      case 'notices':
        if (!block.show || typeof this.state.options === 'undefined')
          return '';
        
        return <Notices key={'mcg-key-' + block.block} block={block.block} notices={this.state.notices} title={block.title} company={this.state.company} options={this.state.options} />
      default:
        console.warn('unsupported block [' + block.name + ']');
        return '';
    }
  }

  getMapLink() {
    if (typeof this.state.company.address !== 'undefined')
      return config.googleMapLink + this.state.company.address.street + ' ' + this.state.company.address.city + ' ' + this.state.company.address.province + ' ' + this.state.company.address.postalCode + '&key=' + config.googleApi;
  }

  renderServices() {
    if (typeof this.state.company.profile === 'undefined')
      return '';

    let results = [];

    for (let index = 0; index < this.state.company.profile.services.length; index++) {
      const element = this.state.company.profile.services[index];

      results.push(
      <li key={'service-' + index} className="service-item">
        <div className="service-element">{element}</div>
      </li>)
    }

    return (
      <ul className="services animation fadeIn">
        {results}
      </ul>
    );
  }

  toggleNav() {
    this.setState({
      show: !this.state.show
    })
  }

  toggleGallery() {
    this.setState({
      showGallery: !this.state.showGallery
    })
  }

  loadData(id, setLoaded = true) {
    let company = {...this.state.company};
  
    if (typeof company === 'undefined')
      return;

    this.loadBlocks();
  
    client.service('profile-videos').find({
      query: {
        customerId: id
      }
    }).then(result => {
      let dataList = [];
      result.forEach(data => {
        dataList.push({
          link: data.link,
          caption: data.caption,
          order: data.order
        });
      })
  
      this.setState({ videos: dataList.sort((a, b) => { return a.order - b.order; }) });
    })
  
    client.service('profile-notices').find({
      query: {
        int_customer_id: id
      }
    }).then(result => {
      this.setState({ notices: result });
    })
  
    client.service('profile-links').find({
      query: {
        customerId: id
      }
    }).then(result => {
      let dataList = [];
      result.forEach(data => {
        dataList.push({
          link: data.link,
          label: data.text,
          order: data.order
        });
      })
  
      this.setState({ links: dataList.sort((a, b) => { return a.order - b.order; }) });
    })
      
    client.service('profile-files').find({
      query: {
        int_customer_id: id
      }
    }).then(result => {
      let files = [];
      result.forEach(file => {
        files.push({
          name: file.str_file,
          text: file.str_title,
          order: file.int_order
        });
      })
  
      this.setState({ files: files.sort((a, b) => { return a.order - b.order; }) });
    })
  
    client.service('profile-gallery').find({
      query: {
        customerId: id
      }
    }).then(result => {
      let images = [];
      result.forEach(file => {
        images.push({
          name: file.image,
          text: file.caption,
          order: file.order
        });
      })
  
      this.setState({ gallery: images.sort((a, b) => { return a.order - b.order; }) });
    })

    
    if (this._ismounted) {
      this.setState({
        dataLoaded: setLoaded
      })
    } else {
      this.state.dataLoaded = setLoaded;
    }
  }

  loadBlocks() {
    client.service('profile-blocks').find({
      query: {
        customerId: this.props.id
      }
    }).then(blocks => {
      if (blocks.length > 0) {
        this.setState({
          blocks: blocks
        })
      }
    }).catch(error => {
      console.error(error);
    });
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
     this._ismounted = false;
  }

  componentDidUpdate(prevProps) {
    if (!this.state.dataLoaded)
      this.loadData(this.props.id);

    if (prevProps.company !== this.props.company) {
      this.setState({company: this.props.company});
      this.loadData(this.props.id);
    }

    if (prevProps.options !== this.props.options) {
      this.setState({options: this.props.options});
    }

    if (prevProps.facebookData !== this.props.facebookData) {
      this.setState({facebookData: this.props.facebookData});
    }
  }
}

export default FrontEnd;