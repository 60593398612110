import React from 'react';
import './fileupload.styles.css';
import Dropzone from 'react-dropzone';
import client from '../../feathers';
import shortid from 'shortid';
import resizeImage from 'smart-img-resize';

import Loader from '../loader.component';
import Config from '../../config';
import { resolve } from 'path';
import FileManager from '../FileManager/filemanager.component';
import { urltoFile } from '../../utility';

class FileUpload extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			uploading: false,
			successfulUpload: false,
			hover: false,
			uploadStatus: '',
			statusMessage: '',
			files: [],
			fileManagerOptions: {}
		};

		this.onDrop = this.onDrop.bind(this);
		this.onDropAccepted = this.onDropAccepted.bind(this);
		this.onDropRejected = this.onDropRejected.bind(this);
		this.getServiceData = this.getServiceData.bind(this);
	}

	render() {
		return (
			<div className={(this.props.size === 'small') ? ' mcg-small-upload' : 'mcg-upload'}>
        {(typeof this.props.title !== 'undefined') ? <span className="mcg-title">this.props.title</span> : ''}
				{this.renderNotice()}
				{(typeof this.props.service !== 'undefined') ? <FileManager region={this.props.region} id={this.props.id} service={this.props.service} files={this.state.files} options={this.state.fileManagerOptions} updateData={this.getServiceData} /> : ''}
        <div>
					<Dropzone onDrop={this.onDrop}
						onDropAccepted={this.onDropAccepted}
						onDropRejected={this.onDropRejected}
						accept={((this.props.uploadType === 'files') ? 'image/png,image/jpeg,image/jpg,image/gif,.pdf' : 'image/png,image/jpeg,image/jpg,image/gif')}
						disabled={this.state.uploading}
						multiple={this.props.multiple}
						noKeyboard={true}
						onDragEnter={() => { this.setState({ hover: true })}}
						onDragLeave={() => { this.setState({ hover: false })}}>
						{({getRootProps, getInputProps}) => (
							<div>
								<div className={'mcg-drop-area' + (this.state.uploading ? ' disabled' : '')} {...getRootProps()}>
									<input {...getInputProps()} />
									{this.dropStateRender()}
								</div>
							</div>
						)}
					</Dropzone>
        </div>
      </div>
    )
	}

	dropStateRender() {
		if (this.state.uploading)
			return <Loader />;
		
		if (this.state.hover)
			return <p className="mcg-large-text"><i className="fas fa-cloud-upload-alt"></i> Drop to Upload</p>;

		return <p className="mcg-large-text">Drag 'n' drop files here, or click to select files</p>;
	}
	
	renderNotice() {
		switch(this.state.uploadStatus) {
			case 'success':
				return <div className={'mcg-notice mcg-success mcg-popin'}><i className="fas fa-check"></i> {this.state.statusMessage}</div>
			case 'failed':
				return <div className={'mcg-notice mcg-failed mcg-popin'}><i className="fas fa-exclamation"></i> {this.state.statusMessage}</div>
			case 'uploading':
				return <div className={'mcg-notice mcg-popin'}>{this.state.statusMessage}</div>
			default:
				return <div className={'mcg-notice'}></div>;
		}
	}

	onDrop(files) {
		this.setState({hover: false})
	}

	onDropAccepted(files) {

		const uploadData = new FormData();
		uploadData.append('destination', 'assets/' + this.props.region + '/' + this.props.id + (this.props.destination || ''));
		if (typeof this.props.id !== 'undefined') uploadData.append('id', this.props.id);
		if (typeof this.props.region !== 'undefined') uploadData.append('region', this.props.region);
		if (typeof this.props.type !== 'undefined') uploadData.append('type', this.props.type);
		if (typeof this.props.requiredName !== 'undefined') uploadData.append('requiredName', this.props.requiredName);

		if (typeof this.props.options !== 'undefined') {
			this.setState({
				uploading: true,
				uploadStatus: 'uploading',
				statusMessage: 'Processing ' + files.length + ' files.'
			});
	
			for (let index = 0; index < files.length; index++) {
				const file = files[index];

				resizeImage(file, this.props.options, (err, preview) => {
					if (err) {
						console.error(err);
						return;
					}

					urltoFile(preview, file.name, 'image/jpeg')
					.then(image => {
						uploadData.append('files', image);
	
						if (index === files.length-1) 
							this.submitUpload(uploadData);
					})
				})
			}
		} else {
			files.forEach(file => {
				uploadData.append('files', file);
			});
			this.submitUpload(uploadData);
		}
	}

	submitUpload(uploadData) {
		this.setState({
			uploading: true,
			uploadStatus: 'uploading',
			statusMessage: 'Uploading files.'
		});
		
		fetch(Config.api + 'media-upload', {
			method: 'POST',
			body: uploadData
		}).then(response => {
			if (response.ok)
				return response.json();

			throw response.json();
		}).then(data => {
			if (data.ok){
				this.setState({
					uploading: false,
					uploadStatus: 'success',
					statusMessage: data.files.length + ' File' + ((data.files.length > 1) ? 's' : '') + ' uploaded successfully!'
				});

				this.getServiceData();

				setTimeout(() => { this.removeNotice() }, 5000);
			} else {
				this.setState({
					uploading: false,
					uploadStatus: 'failed',
					statusMessage: 'Error! Failed to upload: ' + data.message
				});
			}
			resolve();
		}).catch(error => {
			return error.then(error => {
				this.setState({
					uploading: false,
					uploadStatus: 'failed',
					statusMessage: '[' + error.code + ' Error] Failed to upload: ' + error.message
				});
			});
		})
	}
	
	removeNotice() {
		this.setState({
			uploadStatus: '',
			statusMessage: ''
		});
	}

	onDropRejected(files) {
		this.setState({
			uploading: false,
			uploadStatus: 'failed',
			statusMessage: 'Files failed to upload, upload attempt rejected by server, please verify that your files are the correct format and size.'
		});
	}

	getServiceData() {
		let service = this.props.service;
		if (typeof service !== 'undefined') {
			switch (service) {
				case 'profile-gallery':
					client.service(service).find({
						query: {
							customerId: this.props.id
						}
					}).then(result => {
						let files = [];
						result.forEach(file => {
							files.push({
								id: file.id,
								name: file.image,
								text: file.caption,
								order: file.order,
								uid: shortid.generate()
							});
						})

						this.setState({
							files: files,
							fileManagerOptions: {
								sortable: true,
								removable: true
							}
						});
					}).catch(error => {
						console.error(error);
					});
					break;
				case 'profile-files':
					client.service(service).find({
						query: {
							int_customer_id: this.props.id
						}
					}).then(result => {
						let files = [];
						result.forEach(file => {
							files.push({
								id: file.int_id,
								name: file.str_file,
								text: file.str_title,
								order: file.int_order,
								uid: shortid.generate()
							});
						})

						this.setState({
							files: files,
							fileManagerOptions: {
								sortable: true,
								removable: true
							}
						});
					}).catch(error => {
						console.error(error);
					});
					break;
				case 'logo':
					client.service('account').get(
						this.props.id,
						{
							query: {
								$select: ['str_logo_image']
							}
						}
					).then(result => {
						this.setState({
							files: [{
								id: this.props.id,
								name: result.str_logo_image,
								uid: shortid.generate()
							}],
							fileManagerOptions: {
								sortable: false,
								removable: false
							}
						});
					}).catch(error => {
						console.error(error);
					});
					break;
				case 'header':
					client.service('account').get(
						this.props.id,
						{
							query: {
								$select: ['str_header_image']
							}
						}
					).then(result => {
						this.setState({
							files: [{
								id: this.props.id,
								name: result.str_header_image,
								uid: shortid.generate()
							}],
							fileManagerOptions: {
								sortable: false,
								removable: false
							}
						});
					}).catch(error => {
						console.error(error);
					});
					break;
				default:
					break;
			}
			
		}
	}

	componentDidMount() {
		this.getServiceData();
	}
}

export default FileUpload;