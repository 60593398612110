import React, { Component } from 'react';
import {Route, Redirect} from "react-router-dom";

class PrivateRoute extends Component {
  render() {
    const { children, ...rest } = this.props;
    return (
      <Route
      {...rest}
      render={({ location }) =>
        this.props.auth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location, from2: 'test' }
            }}
          />
        )
      }
    />
    );
  }
}

export default PrivateRoute;