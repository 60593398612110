import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { resolve } from 'path';
import ReactQuill from 'react-quill';
import resizeImage from 'smart-img-resize';
import NoticeList from './noticelist.component';
import DashboardInput from '../dashboardinput';
import client from '../../feathers';
import Config from '../../config';

import './noticeform.styles.css';
import { urltoFile } from '../../utility';

class NoticeForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			uploading: false,
			hover: false,
			successfulUpload: false,
			uploadStatus: '',
      statusMessage: '',
      previewImage: null,
      image: null,
      title: '',
      link: '',
      content: '',
      notices: []
		};

    this.AddSiteNotice = this.AddSiteNotice.bind(this);
		this.onContentChange = this.onContentChange.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onDropAccepted = this.onDropAccepted.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.getNotices = this.getNotices.bind(this);
  }
  
  render() {
    return (
      <div>
        <div>
          <NoticeList id={this.props.id} region={this.props.region} notices={this.state.notices} reload={this.getNotices} />
        </div>
        {this.renderNotice()}
        <div className="mcg-notice-form">
          <div className="mcg-notice-form-item  mcg-inline-drop-area">
            <span className="mcg-label-text">Notice Image</span>
            <Dropzone onDrop={this.onDrop}
              onDropAccepted={this.onDropAccepted}
              onDropRejected={this.onDropRejected}
              accept={'image/png,image/jpeg,image/jpg,image/gif'}
              disabled={this.state.uploading}
              multiple={false}
              noKeyboard={true}
              onDragEnter={() => { this.setState({ hover: true })}}
              onDragLeave={() => { this.setState({ hover: false })}}>
              {({getRootProps, getInputProps}) => (
                <section className="mcg-notice-form-item">
                  <div className={'mcg-drop-area' + (this.state.uploading ? ' disabled' : '')} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {this.dropStateRender()}
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="mcg-notice-form-item">
            <DashboardInput label="Title" name="title" onChange={this.onChange} value={this.state.title} />
            <DashboardInput label={'Link'} name="link" onChange={this.onChange} value={this.state.link} placeholder="https://" icon={<i className="fas fa-link"></i>} />
            <label>
              <span className="mcg-label-text">Notice Content Area</span>
              <ReactQuill value={this.state.content} onChange={this.onContentChange} />
            </label>
            <label>
              <button className="mcg-form-button" type="button" onClick={this.AddSiteNotice}>Add Notice</button>
            </label>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getNotices();
  }

  getNotices() {
    client.service('profile-notices').find({
      query: {
        int_customer_id: this.props.id
      }
    }).then(result => {
      this.setState({
        notices: result
      });
    }).catch(error => {
      console.error(error);
    });
  }

	dropStateRender() {
		if (this.state.hover)
      return <p className="mcg-large-text"><i className="fas fa-cloud-upload-alt"></i> Drop to Upload</p>;
      
    if (this.state.previewImage)
      return <img className="mcg-restrict-width" src={this.state.previewImage} alt='' />

		return <p className="mcg-large-text">Drag 'n' drop files here, or click to select files</p>;
	}

  onContentChange(value) {
    this.setState({
      content: value
    })
  }

  onChange(event) {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  renderNotice() {
		switch(this.state.uploadStatus) {
			case 'success':
				return <div className={'mcg-notice mcg-success mcg-popin'}><i className="fas fa-check"></i> {this.state.statusMessage}</div>
			case 'failed':
				return <div className={'mcg-notice mcg-failed mcg-popin'}><i className="fas fa-exclamation"></i> {this.state.statusMessage}</div>
			case 'uploading':
				return <div className={'mcg-notice mcg-popin'}>{this.state.statusMessage}</div>
			default:
				return <div className={'mcg-notice'}></div>;
		}
  }

  AddSiteNotice() {
    if (typeof this.props.id === 'undefined' && typeof this.props.region === 'undefined') {
      console.error('No ID or Region provided for notice.');
      this.setState({
        uploadStatus: 'failed',
        statusMessage: 'There is an issue with the form, please contact support to resolve the issue.'
      });

      return;
    }

    if (!this.validForm()) {
      console.error('Invalid form input.');

      this.setState({
        uploadStatus: 'failed',
        statusMessage: 'Invalid form input, please include a proper title.'
      });

      return;
    }

    const noticeData = new FormData();
		noticeData.append('destination', 'assets/' + this.props.region + '/' + this.props.id + '/profile');
    noticeData.append('id', this.props.id);
    noticeData.append('region', this.props.region);
    noticeData.append('type', 'profile-notice');
    
    // Notice Details
    noticeData.append('title', this.state.title);
    noticeData.append('link', this.state.link);
    noticeData.append('linkTarget', '_blank');
    noticeData.append('content', this.state.content);
    
		this.state.image.forEach(file => {
			noticeData.append('files', file);
		});

		this.setState({
			uploading: true,
			uploadStatus: 'uploading',
			statusMessage: 'Submitting site notice.'
		});
    
    fetch(Config.api + 'media-upload', {
			method: 'POST',
			body: noticeData
		}).then(response => {
			if (response.ok)
				return response.json();

			throw response.json();
		}).then(data => {
			if (data.ok){
				this.setState({
					uploading: false,
					uploadStatus: 'success',
					statusMessage: 'Notice submitted successfully!',
          title: '',
          link: '',
          content: '',
          image: null,
          previewImage: null
        })
        this.getNotices();

				setTimeout(() => { this.removeNotice() }, 5000);
			} else {
				this.setState({
					uploading: false,
					uploadStatus: 'failed',
					statusMessage: 'Error! Failed to submit: ' + data.message
				});
			}
			resolve();
		}).catch(error => {
      this.setState({
        uploading: false,
        uploadStatus: 'failed',
        statusMessage: '[' + error.code + ' Error] Failed to submit: ' + error.message
      });
      return error
		})
  }

  validForm() {
    if (this.state.title.length <= 2)
      return false;

    return true;
  }

  validFormURL() {
    // check if the URL is a valid format

    return true;
  }

  onDrop(files) {
    this.setState({hover: false})
	}

	onDropAccepted(images) {
    images.forEach(image => {
      resizeImage(image, this.props.options, (err, preview) => {
        if (err) {
          console.error(err);
          return;
        }
        
        urltoFile(preview, image.name, 'image/jpeg')
        .then(image => {
          this.setState({
            uploading: false,
            image: [image],
            previewImage: preview
          });
        })
      })
    });
  }
  
	removeNotice() {
		this.setState({
			uploadStatus: '',
			statusMessage: ''
		});
	}

	onDropRejected(files) {
		this.setState({
			uploading: false,
			uploadStatus: 'failed',
			statusMessage: 'Image failed to upload, upload attempt rejected, please verify that your Image is the correct format and size.'
		});
	}
}

export default NoticeForm;