import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CartItem from './CartItem';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import ReactPixel from 'react-facebook-pixel';
import Toaster from "toasted-notes";
import "toasted-notes/src/styles.css";

import './checkout.css'
import client from '../../feathers';

function Checkout(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [items, setItems] = useState(props.items);
  const [active, setActive] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState('waiting');
  let [orderStatus, setOrderStatus] = useState('');
  let [checkoutMessage, setCheckoutMessage] = useState('');
  let [validationMessage, setValidationMessage] = useState('');
  let [allowFlatShipping, setAllowFlatShipping] = useState(false);
  let [allowPickup, setAllowPickup] = useState(false);
  let [allowDelivery, setAllowDelivery] = useState(false);
  let [shippingFlatRate, setShippingFlatRate] = useState(0);
  let [deliveryRate, setDeliveryRate] = useState(0);
  let [selectedShippingOption, setSelectedShippingOption] = useState('shipping');
  let [itemsAllowShipping, setItemsAllowShipping] = useState(true);

  const [input, setInput] = useState({
    province: 'NB',
    country: 'Canada'
  });
  let panelIndex = 0;


  useEffect(() => {
    determineItemShippingBoolean();
  }, [])

  useEffect(() => {
    setItems(props.items);
    localStorage.setItem('cartItems-' + props.company.id, JSON.stringify(props.items));
    panelIndex = 0;
    
    determineItemShippingBoolean();
  }, [props.items, items])
  
  useEffect(() => {
    loadShippingDetails();
    determineItemShippingBoolean();
  }, [props.company.id])
  

  const determineItemShippingBoolean = () => {
    let result = true;
    items.forEach(item => {
      result = result && item.item.allowShipping
    });
    props.items.forEach(item => {
      result = result && item.item.allowShipping
    });

    setItemsAllowShipping(result);
  }

  const loadShippingDetails = () => {
    client.service("store-settings").find({
      query: {
        customerId: props.company.id
      }
    }).then(res => {
      setAllowFlatShipping(res[0].allowFlatRate)
      setAllowPickup(res[0].allowLocalPickup);
      setAllowDelivery(res[0].allowDelivery);
      setShippingFlatRate(res[0].flatRate);
      setDeliveryRate(res[0].deliveryRate);
      setSelectedShippingOption((res[0].allowFlatRate) ? 'shipping' : ((res[0].allowDelivery) ? 'delivery' : 'pickup'));
    })
  }

  const getPanelState = (value) => {
    if (Number(value) > Number(active))
      return ' right';
    else if (Number(value) < Number(active))
      return ' left';
    else
      return '';
  }

  const close = () => {
    props.close();
    setOrderStatus('');
    setCheckoutMessage('');
    setActive(0);
  }

  const prev = (i) => {
    setActive(Math.min(active-1, 0));
  }

  const next = () => {
    switch(active) {
      case 0:
        ReactPixel.track('track', 'Lead');
        setActive(active+1);
        setCheckoutMessage('');
        break;
      case 1:
        if (selectedShippingOption === 'shipping' && !itemsAllowShipping) {
          setCheckoutMessage('You have items in your cart that cannot be shipped, and you chose to have your items shipped.');
          
          Toaster.notify("You have items in your cart that cannot be shipped, and you chose to have your items shipped.")
          setTimeout(() => {
            setCheckoutMessage('');
          }, 5000);
        } else {
          setActive(active+1);
          setCheckoutMessage('');
        }
        break;
      case 2:// require fields
        if (
          typeof input.name === 'undefined' || input.name === '' || !input.name
          || typeof input.email === 'undefined' || input.email === '' || !input.email || input.email.length < 6
          || typeof input.phone === 'undefined' || input.phone === '' || !input.phone
          || typeof input.address === 'undefined' || input.address === '' || !input.address
          || typeof input.city === 'undefined' || input.city === '' || !input.city
          || typeof input.country === 'undefined' || input.country === '' || !input.country
          || typeof input.province === 'undefined' || input.province === '' || !input.province
          || typeof input.postalcode === 'undefined' || input.postalcode === '' || !input.postalcode
        ) {
          setValidationMessage('There are missing fields, please complete the form.');
          setTimeout(() => {
            setValidationMessage('');
          }, 5000);
        } else {
          if (typeof input.recipient === 'undefined' || input.recipient === '' || !input.recipient)
            setInput({...input,
              recipient: input.name
            })

            //AddPaymentInfo
          setActive(active+1);
          setCheckoutMessage('');
        }
        break;
      case 3:
        let contents = [];
        items.forEach(item => {
          contents.push({id: item.id, quantity: item.quantity})
        })
        ReactPixel.track('track', 'AddPaymentInfo',
          {currency: "CAD", value: calculateTotal(), contents: contents});
      default:
        setActive(active+1);
        setCheckoutMessage('');
        break;
    }
  }

  const handleOptionChange = (event) => {
    setSelectedShippingOption(event.currentTarget.value);
  }

  const placeOrder = async event => {
    // get the paymentMethod here and then submit that to the API
    //next();
    setPaymentStatus('processing');

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)
    });

    // const {id, items, success_url, cancel_url, receipt_email, currency} = req.body;

    if (!error) {
      const { id } = paymentMethod;

      try {
        let url = (props.test) ? 'http://localhost:3040/payments' : 'https://api.mcgmedia.net/payments';
        const { data } = await axios.post(url, {
          id,
          customerid: props.company.id,
          items: items,
          data: input,
          shippingType: selectedShippingOption,
          currency: 'cad'
        });

        if (data.ok) {
          ReactPixel.track('track', 'Purchase', {currency: "CAD", value: calculateTotal()});
          setItems([]);
          props.clearCart();
          localStorage.removeItem('cartItems');
          setOrderStatus('success');
          setPaymentStatus('waiting');
          next();
        } else {
          setOrderStatus('error');
          setPaymentStatus('waiting');
          switch(data.message.code) {
            case 'card_declined':
              switch (data.message.decline_code) {
                case 'insufficient_funds':
                  setCheckoutMessage('Your card was declined. Insufficient Funds.');
                  break;
                case 'lost_card':
                  setCheckoutMessage('Your card was declined. Your card has been flagged as lost.');
                  break;
                case 'stolen_card':
                  setCheckoutMessage('Your card was declined. Your card has been flagged as stolen.');
                  break;
                case 'generic_decline':
                default:
                  setCheckoutMessage('Your card was declined. Please double check your payment details.');
                  break;
              }
              break;
            case 'processing_error':
              setCheckoutMessage('There was a processing error with your card. Please wait a moment and try again.');
            default:
              setCheckoutMessage(data.message);
              break;
          }
          prev();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log(error);
      setPaymentStatus('waiting');
      setCheckoutMessage(error.message);
      prev();
    }
  }

  const renderItemList = () => {
    let result = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      
      result.push(<CartItem key={'cart-item-' + i} item={item} itemList={props.itemList} remove={() => props.remove(i)} update={(value) => props.update(i, Number(value))} />)
    }

    return (result.length > 0) ? result : <p className="checkout-no-items">There are no items in your cart.</p>;
  }

  const renderCostTotals = () => {
    let result = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      
      result.push(
        <div key={'cost-item-' + i} className="checkout-cost-item">
          <div className="checkout-cost-item-image">
            <img src={item.image} />
          </div>
          <div className="checkout-cost-item-name">
            <div>{item.name}</div>
            <div className="checkout-cost-item-name-subline">quantity: {item.quantity}</div>
          </div>
          <div className="checkout-cost-item-price">${(item.quantity * item.price).toFixed(2)}</div>
        </div>
      );
    }

    return (result.length > 0) ? result : <p className="checkout-no-items">There are no items in your cart.</p>;
  }

  const getTaxRate = () => {
    let tax = 0;
    switch(input.province) {
      case 'AB':
      case 'NT':
      case 'NU':
      case 'YT':
        tax = 0.05;
        break;
      case 'BC':
      case 'MB':
        tax = 0.12;
        break;
      case 'ON':
        tax = 0.13;
        break;
      case 'QC':
        tax = 0.14975;
        break;
      case 'SK':
        tax = 0.11;
        break;
      case 'NB':
      case 'NL':
      case 'NS':
      case 'PE':
        tax = 0.15;
        break;
      default:
        tax = 0;
        break;
    }
    return tax;
  }

  const calculateSubTotal = (includeShipping = false) => {
    let result = 0;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      
      result += item.quantity * item.price;
    }

    if (includeShipping && selectedShippingOption === 'shipping') 
      result += shippingFlatRate;
    else if (includeShipping && selectedShippingOption === 'delivery') 
      result += deliveryRate;

    return result.toFixed(2);
  }
  
  const calculateTax = () => {
    let result = 0;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.item.taxExempt)
        result += 0;
      else
        result += item.quantity * item.price * (getTaxRate());
    }

    return result.toFixed(2);
  }

  const calculateTotal = () => {
    let result = 0;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      
      if (item.item.taxExempt)
        result += item.quantity * item.price;
      else
        result += item.quantity * item.price * (1 + getTaxRate());
    }

    if (selectedShippingOption === 'shipping') 
      result += shippingFlatRate * (1 + getTaxRate());
    else if (selectedShippingOption === 'delivery') 
      result += deliveryRate * (1 + getTaxRate());

    return result.toFixed(2);
  }

  const handleChange = (event) => setInput({
    ...input,
    [event.currentTarget.name]: event.currentTarget.value
  })
  
  const handlePhoneChange = (event) => {
    let value = event.currentTarget.value.replace('(','').replace(')','').replace('-','').replace(' ','').split("");

    if (value.length <= 10) {
      let phone = '(';

      for (let i = 0; i < value.length; i++) {
        phone += value[i];
        
        if (i === 2)
          phone += ') ';

        if (i === 5)
          phone += '-';
      }
      
      setInput({
        ...input,
        phone: phone
      })
    }
  }

  return (
    <div className={(props.visible) ? 'checkout-container show' : 'checkout-container'}>
      {(checkoutMessage.length > 0) ? (
        <div className="checkout-error">
          {checkoutMessage}
          <div className="checkout-error-close" onClick={() => { setCheckoutMessage('') }}><i className="fas fa-times"></i></div>
          </div>
      ) : ''}
      <div className={'checkout-cart-view ' + getPanelState(panelIndex++)}>
        <div className="checkout-scroll-container">
          <div className="checkout-header-container">
            <div className="checkout-header">Shopping Cart</div>
            <div className="checkout-close-button" onClick={close}><i className="fas fa-times"></i></div>
          </div>
          <div className="checkout-item-list-container">
            {renderItemList()}
          </div>
        </div>
        <div className="checkout-footer-container">
          <div className="checkout-sub-total">
            <div className="checkout-sub-total-label">Sub Total</div>
            <div className="checkout-sub-total-value">${calculateSubTotal()}</div>
          </div>
          <div className="checkout-tax-shipping-info"><p>Taxes and Shipping are calculated at checkout.</p></div>
          <div className="checkout-button" onClick={next}>
            Checkout
          </div>
        </div>
      </div>
      <div className={'checkout-complete-view ' + getPanelState(panelIndex++)}>
        <div className="checkout-scroll-container not-item-display">
          <div className="checkout-header-container">
            <div className="checkout-header">Shipping Options</div>
            <div className="checkout-close-button" onClick={close}><i className="fas fa-times"></i></div>
          </div>
          <div className="checkout-item-list-container">
            <form className="mcg-form">
              {(allowFlatShipping) ? (
                <div className="checkout-radio">
                  <label>
                    <input type="radio" value="shipping" checked={selectedShippingOption === 'shipping'} onChange={handleOptionChange} />
                    {(typeof shippingFlatRate !== 'undefined' && shippingFlatRate > 0) ? 'Flat Rate Shipping - $' + shippingFlatRate + '' : 'Free Shipping'}
                  </label>
                </div>
              ) : ''}
              {(allowPickup) ? (
                <div className="checkout-radio">
                  <label>
                    <input type="radio" value="pickup" checked={selectedShippingOption === 'pickup'} onChange={handleOptionChange} />
                    Local Pickup
                  </label>
                </div>
              ) : ''}
              {(allowDelivery) ? (
                <div className="checkout-radio">
                  <label>
                    <input type="radio" value="delivery" checked={selectedShippingOption === 'delivery'} onChange={handleOptionChange} />
                    Local Delivery
                  </label>
                </div>
              ) : ''}
              {(!allowFlatShipping && !allowPickup) ? (
                <div>No Shipping Options Available.</div>
              ) : ''}
            </form>
          </div>
          <div className="checkout-button" onClick={next}>
            Continue
          </div>
        </div>
      </div>
      <div className={'checkout-shipping-view ' + getPanelState(panelIndex++)}>
        <div className="checkout-scroll-container not-item-display">
          <div className="checkout-header-container">
            <div className="checkout-header">Shipping Information</div>
            <div className="checkout-close-button" onClick={close}><i className="fas fa-times"></i></div>
          </div>
          <div className="checkout-item-list-container">
            {(validationMessage) ? (
              <div className="checkout-validation-message">
                {validationMessage}
              </div>
            ) : ''}
            <div className="mcg-form">
              <div className="mcg-form-input">
                <div className="mcg-label">Name</div>
                <div className="mcg-input"><input type="text" name="name" onChange={handleChange} placeholder="John Scott" /></div>
              </div>
              <div className="mcg-form-input">
                <div className="mcg-label">Phone Number</div>
                <div className="mcg-input"><input type="tel" name="phone" onChange={handlePhoneChange} value={input.phone} placeholder="(506) 555-5555" /></div>
              </div>
              <div className="mcg-form-input">
                <div className="mcg-label">Email</div>
                <div className="mcg-input"><input type="text" name="email" onChange={handleChange} placeholder="john@example.com" /></div>
              </div>
              <div className="mcg-form-input">
                <div className="mcg-label">Ship to</div>
                <div className="mcg-input"><input type="text" name="recipient" onChange={handleChange} placeholder="John Scott" /></div>
              </div>
              <div className="mcg-form-input">
                <div className="mcg-label">Address</div>
                <div className="mcg-input"><input type="text" name="address" onChange={handleChange} placeholder="2315 King George Hwy Apt 4" /></div>
              </div>
              <div className="mcg-form-input">
                <div className="mcg-label">City</div>
                <div className="mcg-input"><input type="text" name="city" onChange={handleChange} placeholder="Miramichi" /></div>
              </div>
              <div className="mcg-form-input">
                <div className="mcg-label">Province</div>
                <div className="mcg-input">
                  {(input.country === 'Canada') ? (
                  <select className="mcg-select" name="province" onChange={handleChange} defaultValue="NB">
                    <option value="AB">Alberta</option>
                    <option value="BC">British Columbia</option>
                    <option value="MB">Manitoba</option>
                    <option value="NB">New Brunswick</option>
                    <option value="NL">Newfoundland and Labrador</option>
                    <option value="NS">Nova Scotia</option>
                    <option value="ON">Ontario</option>
                    <option value="PE">Prince Edward Island</option>
                    <option value="QC">Quebec</option>
                    <option value="SK">Saskatchewan</option>
                    <option value="NT">Northwest Territories</option>
                    <option value="NU">Nunavut</option>
                    <option value="YT">Yukon</option>
                  </select>
                  ) : (
                  <select className="mcg-select" name="province" onChange={handleChange}>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                  )}
                </div>
              </div>
              <div className="mcg-form-input">
                <div className="mcg-label">Postal Code</div>
                <div className="mcg-input"><input type="text" name="postalcode" onChange={handleChange} placeholder="E1V 1Q1" maxLength="7" /></div>
              </div>
              <div className="mcg-form-input">
                <div className="mcg-label">Country</div>
                <div className="mcg-input">
                  <select className="mcg-select" name="country" onChange={handleChange}>
                    <option value="Canada">Canada</option>
                    <option value="United States">United States</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="checkout-button" onClick={next}>
            Continue
          </div>
        </div>
      </div>
      <div className={'checkout-complete-view ' + getPanelState(panelIndex++)}>
        <div className="checkout-scroll-container not-item-display">
          <div className="checkout-header-container">
            <div className="checkout-header">Review Order</div>
            <div className="checkout-close-button" onClick={close}><i className="fas fa-times"></i></div>
          </div>
          <div className="checkout-item-list-container">
            {renderCostTotals()}
            <div className="checkout-review subtotal">
              <div className="review-label">Subtotal</div>
              <div className="review-currency">${calculateSubTotal()}</div>
            </div>
            {(selectedShippingOption === 'shipping') ? (
              <div className="checkout-review shipping">
                <div className="review-label">Shipping</div>
                <div className="review-currency">{(selectedShippingOption === 'shipping' && typeof shippingFlatRate !== 'undefined' && shippingFlatRate > 0) ? '$' + shippingFlatRate.toFixed(2) : 'Free'}</div>
              </div>
            ) : (selectedShippingOption === 'delivery') ? (
              <div className="checkout-review shipping">
                <div className="review-label">Delivery</div>
                <div className="review-currency">{(selectedShippingOption === 'delivery' && typeof deliveryRate !== 'undefined' && deliveryRate > 0) ? '$' + deliveryRate.toFixed(2) : 'Free'}</div>
              </div>
            ) : (
              <div className="checkout-review shipping">
                <div className="review-label">Delivery</div>
                <div className="review-currency">Pickup</div>
              </div>
            )}
            <div className="checkout-review taxes">
              <div className="review-label">Taxes</div>
              <div className="review-currency">${calculateTax()}</div>
            </div>
            <div className="checkout-review total">
              <div className="review-label">Total</div>
              <div className="review-currency">${calculateTotal()}</div>
            </div>
          </div>
          <div className="checkout-card-container">
            <h4>Delivery Instructions / Notes</h4>
            <textarea rows="6" className="checkout-delivery-area" name="notes" onChange={handleChange} placeholder="Notes Area" />
          </div>
          <div className="checkout-card-container">
            <h4>Payment Information</h4>
            <CardElement />
          </div>
          <div onClick={placeOrder} className={(paymentStatus === 'processing') ? 'checkout-button disable-payment' : 'checkout-button'}>
            {(paymentStatus === 'waiting') ? (
              'Place Order'
            ) : (
              <i className="fas fa-spinner fa-pulse"></i>
            )}
          </div>
        </div>
      </div>
      <div className={'checkout-complete-view ' + getPanelState(panelIndex++)}>
        <div className="checkout-scroll-container not-item-display">
          <div className="checkout-header-container">
            <div className="checkout-header">Order Complete</div>
            <div className="checkout-close-button" onClick={close}><i className="fas fa-times"></i></div>
          </div>
          <div className="checkout-item-list-container">
            <div className="checkout-success-check">{(orderStatus === 'success') ? <i className="far fa-check-circle"></i> : ''}</div>
            <h3 className="checkout-success-header">{(orderStatus === 'success') ? 'Your Order Was Placed Successfully' : ''}</h3>
            <div className="checkout-success-message">Your order has successfully been placed, please check your email for a receipt of the transaction.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checkout;