import React, {Component} from 'react';
import {Switch, Route} from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import FrontEnd from './front-end';
import Dashboard from './containers/dashboard/dashboard';
import Login from './containers/dashboard/login';
import PrivateRoute from './containers/dashboard/privateroute';
import Admin from './containers/dashboard/admin';
import Subpage from './containers/front-end/subpage';
import queryString from 'query-string';
import ReactPixel from 'react-facebook-pixel';

import AutoDetails from './containers/front-end/routes/automotive/details';
import Automotive from './containers/front-end/routes/automotive/automotive';

import Marketplace, { MarketplaceDetails } from '@mcgmedia/marketplace';

import Restaurant from './containers/front-end/routes/restaurants/restaurant';

import Funerals from './containers/front-end/routes/funerals';

import Events from './containers/front-end/routes/events';

import Config from './config';
import client from './feathers';
import { parseFacebookUser, track, trackMarket } from './utility';
import './marketplace.css';
import './app.css';
import Checkout from './components/Checkout/Checkout';

let stripeSet = false;
const fbPixelOptions = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const auth = {
  isAuthenticated: false,
  authenticate(cb) {
    auth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    auth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
      id: 0,
      company: {},
      products: {
        marketplace: []
      },
      facebookData: [],
      cart: [],
      showCart: false,
      stripePromise: undefined,
      fbPixelInit: false
    };
    
    this.filterFeatured = this.filterFeatured.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.toggleCart = this.toggleCart.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.updateQuantity = this.updateQuantity.bind(this);
    this.clearCart = this.clearCart.bind(this);
    
    this.loadData();
  }

  render() {
    if (stripeSet) {
      return (
        <Elements stripe={this.state.stripePromise}>
        <Checkout
          visible={this.state.showCart}
          items={this.state.cart}
          itemList={this.state.itemList}
          close={this.toggleCart}
          remove={this.removeItem}
          update={this.updateQuantity}
          clearCart={this.clearCart}
          company={this.state.company} />
        <Switch>
          <PrivateRoute path="/dashboard/" auth={auth}>
            <Dashboard id={this.state.id} company={this.state.company} auth={auth} />
          </PrivateRoute>
          <Route path="/admin/" auth={auth}>
            <Admin id={this.state.id} company={this.state.company} />
          </Route>
          <Route path="/login/" render={(props) =>
            <Login {...props} id={this.state.id} isAuthenticated={auth.isAuthenticated} authenticate={(cb) => { auth.authenticate(cb) }} /> } />
          <Route path="/pages/:id/:name" render={(props) =>
            <Subpage {...props} id={this.state.id} company={this.state.company} title={'Automotive Details'} cart={this.state.cart} toggleCart={this.toggleCart}>
              <AutoDetails {...props} id={this.state.id} items={this.state.products.automotive} company={this.state.company} type={'automotive'} />
            </Subpage>} />
          <Route path="/marketplace/c/:category" render={(props) =>
            <Subpage {...props} id={this.state.id} company={this.state.company} title={'Marketplace'} cart={this.state.cart} toggleCart={this.toggleCart}>
              {(typeof this.state.company !== 'undefined' && typeof this.state.products !== 'undefined' && typeof this.state.products.marketplace !== 'undefined' && this.state.products.marketplace.length > 0) ? (
                <Marketplace {...props} ReactPixel={ReactPixel} items={this.state.products.marketplace} addToCart={this.addToCart} ecommerceEnabled={this.state.company.ecommerceEnabled} showSideBar={true} track={track} trackMarket={trackMarket} />
              ) : <div>No Available Items.</div>}
            </Subpage>} />
          <Route path="/marketplace/sale" render={(props) =>
            <Subpage {...props} id={this.state.id} company={this.state.company} title={'Marketplace'} cart={this.state.cart} toggleCart={this.toggleCart}>
              {(typeof this.state.company !== 'undefined' && typeof this.state.products !== 'undefined' && typeof this.state.products.marketplace !== 'undefined' && this.state.products.marketplace.length > 0) ? (
                <Marketplace showSaleItems={true} {...props} ReactPixel={ReactPixel} items={this.state.products.marketplace} addToCart={this.addToCart} ecommerceEnabled={this.state.company.ecommerceEnabled} showSideBar={true} track={track} trackMarket={trackMarket} />
              ) : <div>No Available Items.</div>}
            </Subpage>} />
          <Route path="/marketplace/:id" render={(props) =>
            <Subpage {...props} id={this.state.id} company={this.state.company} title={'Marketplace Details'} cart={this.state.cart} toggleCart={this.toggleCart}>
              <MarketplaceDetails {...props} ReactPixel={ReactPixel} items={this.state.products.marketplace} ecommerceEnabled={this.state.company.ecommerceEnabled} addToCart={this.addToCart} track={track} showBuyButton={true} trackMarket={trackMarket} />
            </Subpage>} />
          <Route path="/marketplace" render={(props) =>
            <Subpage {...props} id={this.state.id} company={this.state.company} title={'Marketplace'} cart={this.state.cart} toggleCart={this.toggleCart}>
              {(typeof this.state.company !== 'undefined' && typeof this.state.products !== 'undefined' && typeof this.state.products.marketplace !== 'undefined' && this.state.products.marketplace.length > 0) ? (
                <Marketplace {...props} ReactPixel={ReactPixel} items={this.state.products.marketplace} addToCart={this.addToCart} ecommerceEnabled={this.state.company.ecommerceEnabled} showSideBar={true} showBuyButton={true} track={track} trackMarket={trackMarket} />
              ) : <div>No Available Items.</div>}
            </Subpage>} />
          <Route path="/automotive/:id" render={(props) =>
            <Subpage {...props} id={this.state.id} company={this.state.company} title={'Automotive Details'} cart={this.state.cart} toggleCart={this.toggleCart}>
              <AutoDetails {...props} id={this.state.id} items={this.state.products.automotive} company={this.state.company} type={'automotive'} />
            </Subpage>} />
          <Route path="/automotive" render={(props) =>
            <Subpage {...props} id={this.state.id} company={this.state.company} title={'Automotive'} cart={this.state.cart} toggleCart={this.toggleCart}>
              <Automotive {...props} id={this.state.id} items={this.state.products.automotive} company={this.state.company} />
            </Subpage>} />
          <Route path="/restaurant" render={(props) =>
            <Subpage {...props} id={this.state.id} company={this.state.company} title={'Restaurant Specials'} cart={this.state.cart} toggleCart={this.toggleCart}>
              <Restaurant {...props} id={this.state.id} items={this.state.products.restaurant} company={this.state.company} options={this.state.options} />
            </Subpage>} />
          <Route path="/funerals" render={(props) =>
            <Funerals {...props} id={this.state.id} items={this.state.funerals} />} />
          <Route path="/events" render={(props) =>
            <Events {...props} id={this.state.id} items={this.state.events} />} />
          <Route path="/restaurant" render={(props) =>
            <Restaurant {...props} id={this.state.id} items={this.state.products.restaurant} options={this.state.options} />} />
          <Route path="/" render={(props) =>
            <FrontEnd {...props}
              id={this.state.id}
              company={this.state.company}
              facebookData={this.state.facebookData}
              featuredItems={this.state.featured}
              options={this.state.options}
              cart={this.state.cart}
              toggleCart={this.toggleCart} />} />
        </Switch>
        </Elements>
      );
    } else {
      return '';
    }
  }

  toggleCart() {
    this.setState({ showCart: !this.state.showCart });
  }

  addToCart(e, item) {
    e.stopPropagation();

    /*console.log('add to cart item', item,{
      content_ids: item.id,
      content_name: item.title,
      content_category: item.category
      //currency: item
    });*/

    ReactPixel.track('AddToCart', {
      content_ids: item.id,
      content_name: item.name,
      content_category: item.item.category.category,
      currency: item.price,
      content_type: 'product'
    });

    let cart = [...this.state.cart];

    if (typeof cart.find(e => e.id === item.id) !== 'undefined') {
      let index = cart.findIndex(e => e.id === item.id);
      cart[index].quantity++;
    } else {
      cart.push(item);
    }
    
    this.setState({cart})
  }

  removeItem(index) {
    let items = [...this.state.cart];
    items.splice(index, 1);
    this.setState({cart: items});
  }

  updateQuantity(index, quantity) {
    let items = [...this.state.cart];
    items[index].quantity = quantity;
    this.setState({cart: items});
  }

  clearCart() {
    console.log('clear cart');
    this.setState({cart: []});
  }

  loadData() {
    let query = { 'int_id': this.props.id };

    //console.log('ls cart: ', JSON.parse(localStorage.getItem('cartItems')))
    //this.setState({cart: JSON.parse(localStorage.getItem('cartItems'))});

    const parsed = queryString.parse(window.location.search);

    if (typeof parsed.previewId !== 'undefined')
      query = { 'int_id': parsed.previewId }
    else if (typeof this.props.id === 'undefined') {
      query = { 'str_domain': getHostname() }
      
      if (getHostname() === 'http://localhost:3000' || getHostname() === 'http://localhost:3001')
        query = { 'int_id': 16353 } //16353 - giver   // 12298    /// sog 16253  /// M Marine 8428
    }

    client.service('account').find({
      query: query
    }).then(res => {
      if (res.length > 0) {

        let company = this.mapData(res[0]);
        track(company.id, 'pageView');

        this.setState({
          id: company.id,
          company: company,
          cart: JSON.parse(localStorage.getItem('cartItems-' + company.id)) || [],
          stripePromise: loadStripe(company.stripe_pk)
        }, () => {
          document.title = company.name;
          this.loadApps(company.id);
          this.loadOptions(company.id);
          this.loadStyles(company.id);
          stripeSet = true;
        })
      }
    }).catch(error => {
      console.error('Failed to retrieve company.', error);
    })
  }

  loadStyles(id) {
    client.service('profile-styles').find({
      query: {
        customerId: id
      }
    }).then(res => {
      if (res.length > 0) {
        Object.keys(res[0]).forEach(element => {
          switch(element) {
            case 'themeType':
            case 'customerId':
            case 'id':
            case 'selected':
              break;
            case 'customStyles':
              var style = document.createElement('style');
              style.innerHTML = res[0].customStyles.replace(/(<([^>]+)>)/gi, "").trim();
              document.head.appendChild(style);
              break;
            default :
              document.querySelector(":root").style.setProperty('--'+element, res[0][element]);
              break;
          }
        })
      } else {
        client.service('profile-styles').create({
          customerId: id
        }).then(res => {
          if (res.length > 0) {
            Object.keys(res).forEach(element => {
              switch(element) {
                case 'themeType':
                case 'customerId':
                case 'id':
                case 'selected':
                  break;
                case 'customStyles':
                  break;
                default :
                  document.querySelector(":root").style.setProperty('--'+element, res[element]);
                  break;
              }
            })
          }
        })
      }
    })
  }

  loadOptions(id) {
    client.service('profile-options').find({
      query: {
        customerId: id
      }
    }).then(res => {
      let options = {
        showHours: true,
        facebookLimit: 5,
        facebookDisplay: 'vertical',
        featureLimit: 5,
        noticeLimit: 3,
        dataRefresh: 6,
        restaurantDisplayType: 'block'
      };
      if (res.length > 0) {
        options = {
          showHours: res[0].showHours || true,
          facebookLimit: res[0].facebookLimit || 5,
          facebookDisplay: res[0].facebookDisplay || 'vertical',
          featureLimit: res[0].featureLimit || 5,
          noticeLimit: res[0].noticeLimit || 5,
          restaurantDisplayType: res[0].restaurantDisplayType || 'menu'
        }
      }
      this.setState({options});
    })
  }

  loadApps(id) {
    // get applicable apps, and get the items from each app
    fetch(Config.api + 'get-roles', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id
      })
    }).then(response => { if (response.ok) return response.json(); throw response.json(); }).then(data => {
      let products = {};
      for (let i = 0; i < data.roles.length; i++) {
        switch (data.roles[i].Rolename) {
          case 'Events':
            client.service('events').find({
              query: {
                'int_customer_id': id
              }
            }).then(res => {
              if (res.length > 0) {
                this.setState({
                  events: res
                })
              }
            }).catch(error => {
              console.error('Failed to retrieve events.', error);
            })
            break;
          case 'Funeral':
            client.service('funerals').find({
              query: {
                'int_customer_id': id
              }
            }).then(res => {
              if (res.length > 0) {
                if (res.length > 0) {
                  this.setState({
                    funerals: res
                  })
                }
              }
            }).catch(error => {
              console.error('Failed to retrieve funerals.', error);
            })
            break;
          case 'Automotive':
            console.log('auto get')
            client.service('automotive').find({
              query: {
                'customerId': id
              }
            }).then(res => {
              if (res.length > 0) {
                let items = [];
                res.forEach(item => {
                  delete item.customerId;
                  items.push({...item, type: 'automotive'});
                })
                products.automotive = items.filter(e => e.sold === false);
                
                this.setState({
                  products: products
                }, this.filterFeatured )
              }
            }).catch(error => {
              console.error('Failed to retrieve automotive Items.', error);
            })
            break;
          case 'Sales Flyer':
            client.service('market-items').find({
              query: {
                'customerId': id
              }
            }).then(res => {
              if (res.length > 0) {
                products.marketplace = [];
                res.forEach(item => {
                  products.marketplace.push({
                    ...item,
                    type: 'marketplace'
                  })
                })
                // products.marketplace = res;
                
                this.setState({
                  products: products
                }, this.filterFeatured )
              }
            }).catch(error => {
              console.error('Failed to retrieve Marketplace Items.', error);
            })
            break;
          case 'Restaurant':
            client.service('restaurant').find({
              query: {
                'int_customer_id': id
              }
            }).then(res => {
              if (res.length > 0) {
                let items = [];
                res.forEach(item => {
                  items.push({...item, type: 'restaurant'});
                })
                products.restaurant = items;

                this.setState({
                  products: products
                }, this.filterFeatured )
              }
            }).catch(error => {
              console.error('Failed to retrieve restaurant Items.', error);
            })
            break;
          case 'Mobile':
          case 'Hub':
            // Apps to do nothing with
            break;
          default:
            //console.log(' -- un-implemented role: ', data.roles[i]);
            break;
        }
      }
    })
  }

  filterFeatured() {
      let featured = [];

      Object.keys(this.state.products).forEach((key, index) => {
        switch(key) {
          case 'marketplace':
            featured = featured.concat(this.state.products[key].filter(e => e.featured === true));
            break;
          case 'automotive':
            featured = featured.concat(this.state.products[key].filter(e => e.featured === true));
            break;
          case 'restaurant':
            featured = featured.concat(this.state.products[key].filter(item => item.featured && item.dayId === (new Date()).getDay()));
            break;
          default:
            break;
        }
      })

      /*featured.forEach(item => {
        console.log('item', item);
      })*/

      this.setState({
        featured
      })
  }

  mapData(data) {
    if (typeof data === 'undefined')
      return;

    if (typeof data.facebookPixelId !== 'undefined' && data.facebookPixelId){
      ReactPixel.init(data.facebookPixelId, fbPixelOptions);
      ReactPixel.pageView();
    }
    let company = {
      id: data.int_id,
      status: data.int_status_id,
      region: data.regionId,
      name: data.str_company_name,
      catalogEnabled: data.catalogEnabled,
      ecommerceEnabled: data.ecommerceEnabled,
      marketplaceEnabled: data.marketplaceEnabled,
      stripe_pk: data.stripePublicKey,
      address: {
        street: ((data.str_address1) ? data.str_address1 : '') + ' ' + ((data.str_address2) ? data.str_address2 : ''),
        city: ((data.str_address1) ? data.str_city : ''),
        province: ((data.str_address1) ? data.str_province : ''),
        postalCode: ((data.str_address1) ? data.str_pcode : ''),
        country: ((data.str_address1) ? data.str_country : ''),
      },
      contact: {
        contact: data.str_contact_name,
        phone: data.str_phone,
        tollfree: data.str_tollfree,
        mobile: data.str_mobile,
        fax: data.str_fax,
        email: data.str_email
      },
      social: {
        instagram: data.str_instagram,
        facebook: parseFacebookUser(data.str_facebook),
        twitter: data.str_twitter_id,
        //youtube: 'mightymiramichi',
        //linkedin: 'mightymiramichi'
      },
      profile: {
        logo: 'https://api.mcgmedia.net/assets/' + data.regionId + '/' + data.int_id + '/' + data.str_logo_image,
        header: 'https://api.mcgmedia.net/assets/' + data.regionId + '/' + data.int_id + '/profile/' + data.str_header_image ,//'https://mcgmedia.net/wp-content/uploads/2018/07/website.jpg',
        subtitle: data.str_title,
        about: data.str_description,
        contentTitle: data.str_content_area_title,
        contentArea: data.str_content_area_content,
        services: (typeof data.str_services !== 'undefined' && data.str_services) ? data.str_services.split('\n') : [],
        files: [],
        gallery: [],
        videos: []
      },
      links: [],
      blocks: [
        {
          name: 'about',
          title: 'A little about us',
          order: 0,
          show: true
        },
        {
          name: 'featured',
          title: 'Featured Items',
          order: 1,
          show: true
        },
        {
          name: 'facebook',
          title: 'From our Facebook',
          order: 2,
          show: true
        },
        {
          name: 'notices',
          title: 'Recent Happenings',
          order: 3,
          show: true
        },
        {
          name: 'gallery',
          title: 'From our Gallery',
          order: 4,
          show: true
        },
        {
          name: 'videos',
          title: 'Videos',
          order: 5,
          show: true
        },
        {
          name: 'files',
          title: 'Downloads',
          order: 6,
          show: true
        },
        {
          name: 'services',
          title: 'A bit of what we do',
          order: 7,
          show: true
        },
        {
          name: 'content',
          title: 'Content Area',
          order: 8,
          show: true
        },
        {
          name: 'contact',
          title: '',
          order: 9,
          show: true
        },
        {
          name: 'map',
          title: '',
          order: 10,
          show: true
        }
      ]
    }
    
    return company;
  }
}

function getHostname() {
  return (window.location.protocol + '//' + window.location.host).replace('www.', '');
}


export default App;
