import React, { Component } from 'react';
import Config from '../../config';
import client from '../../feathers';

class FileManagerItem extends Component {
  render() {
    return (
      (typeof this.props.file !== 'undefined') ? (
      <div data-id={this.props.file.id} className={'mcg-filemanager-item' + (this.useControls() ? ' mcg-filemanager-controls' : '')}>
        {this.renderFile()}
        {(this.useControls()) ? (
          <div className="mcg-filemanager-display layer-top">
            <div className="mcg-filemanager-controls-option">
              <i className="fas fa-arrows-alt"></i>
              <span className="mcg-controls-option-subtext">Move</span>
            </div>
            <div className="mcg-filemanager-controls-option" onClick={() => { this.removeItem() }}>
              <i className="far fa-trash-alt"></i>
              <span className="mcg-controls-option-subtext">Delete</span>
            </div>
          </div>
        ) : ''}
      </div>
      ) : ''
    );
  }

  renderFile() {
    let url = Config.api + 'assets/' + this.props.region + '/' + this.props.id + '/profile/' + this.props.file.name;
    let css = 'mcg-max-width';
    switch(this.props.service) {
      case 'profile-files':
        if (this.props.file.name.includes('.pdf')) {
          return (
          <div className="mcg-filemanager-display-item">
            <i className="fas fa-file-pdf"></i>
            <span className="mcg-controls-option-subtext">{this.props.file.name}</span>
          </div>);
        }
        break;
      case 'profile-gallery':
        css = 'mcg-max-width-250px';
        break;
      case 'header':
        break;
      case 'logo':
        url = Config.api + 'assets/' + this.props.region + '/' + this.props.id + '/' + this.props.file.name;
        break;
      default:
        return {};
    }
    return (
      <img key={this.props.file.uid} className={css + ' layer-bottom'} src={url} alt={this.props.file.text} />
    );
  }

  removeItem() {
    client.service(this.props.service).remove(this.props.file.id).then(res => {
      this.props.updateData();
    }).catch(error => {
      console.error(error);
    })
  }

  useControls() {
    switch(this.props.service) {
      case 'profile-files':
      case 'profile-gallery':
        return true;
      default:
        break;
    }
    return false;
  }
}

export default FileManagerItem;