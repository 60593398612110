import React, { Component } from 'react';
import './appoptions.css';
import client from '../../feathers';
import DashboardInput from '../dashboardinput';

class AppOptions extends Component {
	constructor(props) {
		super(props);

		this.state = {
      restaurantDisplayType: 'menu',
      noticeLimit: 4,
      featureLimit: 4,
      showHours: true,
      facebookLimit: 3,
      facebookDisplay: 'vertical'
    };

    this.loadOptions = this.loadOptions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.loadOptions();
  }

  render() {
    return (
      <div className="dash-stat-block app-options">
        <h3>General Options</h3>
        <div className='mcg-details-item'>
          <div className='mcg-details-descriptor'>Restaurant Display Type</div>
          <div className='mcg-details-input'>
            <select className="form-dropdown" name="restaurantDisplayType" onChange={(event) => this.onChange(event)} value={this.state.restaurantDisplayType}>
              <option value="block">Block</option>
              <option value="menu">Menu</option>
            </select>
          </div>
        </div>
        <div className='mcg-details-item'>
          <div className='mcg-details-descriptor'>Notice Limit</div>
          <div className='mcg-details-input'>
            <select className="form-dropdown" name="noticeLimit" onChange={(event) => this.onChange(event)} value={this.state.noticeLimit}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>
        <div className='mcg-details-item'>
          <div className='mcg-details-descriptor'>Featured Item Limit</div>
          <div className='mcg-details-input'>
            <select className="form-dropdown" name="featureLimit" onChange={(event) => this.onChange(event)} value={this.state.featureLimit}>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
        </div>
        <div className='mcg-details-item'>
          <div className='mcg-details-descriptor'>Facebook Limit</div>
          <div className='mcg-details-input'>
            <select className="form-dropdown" name="facebookLimit" onChange={(event) => this.onChange(event)} value={this.state.facebookLimit}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>
        <div className='mcg-details-item'>
          <div className='mcg-details-descriptor'>Facebook Display</div>
          <div className='mcg-details-input'>
            <select className="form-dropdown" name="facebookDisplay" onChange={(event) => this.onChange(event)} value={this.state.facebookDisplay}>
              <option value="vertical">Vertical</option>
              <option value="horizontal">Horizontal</option>
            </select>
          </div>
        </div>
      </div>
    );
  }

  onChange(event) {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    }, () => {
      client.service('profile-options').update(this.state.id, {
        customerId: this.props.id,
        restaurantDisplayType: this.state.restaurantDisplayType,
        featureLimit: this.state.featureLimit,
        noticeLimit: this.state.noticeLimit,
        showHours: this.state.showHours,
        facebookLimit: this.state.facebookLimit,
        facebookDisplay: this.state.facebookDisplay
      })
    });
  }

  loadOptions() {
    client.service('profile-options').find({
      query: {
        customerId: this.props.id
      }
    }).then(res => {
      if (res.length > 0) {
        this.setState({
          id: res[0].id,
          restaurantDisplayType: res[0].restaurantDisplayType,
          featureLimit: res[0].featureLimit,
          noticeLimit: res[0].noticeLimit,
          showHours: res[0].showHours,
          facebookLimit: res[0].facebookLimit,
          facebookDisplay: res[0].facebookDisplay
        }, () => console.log('options set', this.state, res[0]));
      } else {
        client.service('profile-options').create({
          customerId: this.props.id
        }).then(res => {
          console.log(res);

          if (res.length > 0) {
            this.setState({
              id: res.id,
              restaurantDisplayType: res.restaurantDisplayType,
              featureLimit: res.featureLimit,
              noticeLimit: res.noticeLimit,
              showHours: res.showHours,
              facebookLimit: res.facebookLimit,
              facebookDisplay: res.facebookDisplay
            }, () => console.log('options set', this.state, res[0]));
          }
        })
      }
    })
  }
}

export default AppOptions;