import React, { Component } from 'react';
import client from '../../../feathers';
import Loader from '../../../components/loader.component';
import HourManager from '../../../components/HourManager/hourmanager';

import '../../../styles/dashboard/details.css';

class Details extends Component {
	constructor(props) {
		super(props);

    this.state = {
    }

    this.loadDetails();
  }

  render() {
    if (!this.state.dataLoaded)
      return <Loader />;

    return (
      <div>
        <div className="dash-stat-block">
          <h3>Location Details</h3>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Address</div>
            <div className='mcg-details-input'>
              <input onBlur={(e) => this.saveField('str_address1', e)} type="text" name="str_address1" value={this.state.str_address1} onChange={(e) => this.onChange('str_address1', e)} placeholder="123 Fancy dr" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Address 2</div>
            <div className='mcg-details-input'>
              <input onBlur={(e) => this.saveField('str_address2', e)} type="text" name="str_address2" value={this.state.str_address2 || ''} onChange={(e) => this.onChange('str_address2', e)} placeholder="unit 2" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>City</div>
            <div className='mcg-details-input'>
              <input onBlur={(e) => this.saveField('str_city', e)} type="text" name="str_city" value={this.state.str_city || ''} onChange={(e) => this.onChange('str_city', e)} placeholder="Miramichi" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Province</div>
            <div className='mcg-details-input'>
              <input onBlur={(e) => this.saveField('str_province', e)} type="text" name="str_province" value={this.state.str_province || ''} onChange={(e) => this.onChange('str_province', e)} placeholder="NB" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Postal Code</div>
            <div className='mcg-details-input'>
              <input onBlur={(e) => this.saveField('str_pcode', e)} type="text" name="str_pcode" value={this.state.str_pcode || ''} onChange={(e) => this.onChange('str_pcode', e)} placeholder="E1N 1N1" />
            </div>
          </div>
        </div>
        <div className="dash-stat-block">
          <h3>Company Details</h3>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Company Name</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_company_name', e)} type="text" name="str_company_name" value={this.state.str_company_name} onChange={(e) => this.onChange('str_company_name', e)} placeholder="Company Name" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Slogan / Sub Title</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_title', e)} type="text" name="str_title" value={this.state.str_title || ''} onChange={(e) => this.onChange('str_title', e)} placeholder="You will find it all here!" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>About us / Business Description</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_description', e)} type="text" name="str_description" value={this.state.str_description || ''} onChange={(e) => this.onChange('str_description', e)} />
            </div>
          </div>
        </div>
        <div className="dash-stat-block">
          <h3>Contact Info</h3>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Phone</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_phone', e, true)} type="text" name="str_phone" value={this.state.str_phone || ''} onChange={(e) => this.handlePhoneChange('str_phone', e)} placeholder="(xxx) xxx-xxxx" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Mobile Phone</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_mobile', e, true)} type="text" name="str_mobile" value={this.state.str_mobile || ''} onChange={(e) => this.handlePhoneChange('str_mobile', e)} placeholder="(xxx) xxx-xxxx" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Fax</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_fax', e, true)} type="text" name="str_fax" value={this.state.str_fax || ''} onChange={(e) => this.handlePhoneChange('str_fax', e)} placeholder="(xxx) xxx-xxxx" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Toll Free</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_tollfree', e, true)} type="text" name="str_tollfree" value={this.state.str_tollfree || ''} onChange={(e) => this.handlePhoneChange('str_tollfree', e)} placeholder="(xxx) xxx-xxxx" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Email</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_email', e)} type="text" name="str_email" value={this.state.str_email || ''} onChange={(e) => this.onChange('str_email', e)} placeholder="example@domain.com" />
            </div>
          </div>
        </div>
        <div className="dash-stat-block">
          <h3>Social Media</h3>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Facebook Username/ID</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_facebook', e)} type="text" name="str_facebook" value={this.state.str_facebook} onChange={(e) => this.onChange('str_facebook', e)} placeholder="username" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Twitter Username/ID</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_twitter', e)} type="text" name="str_twitter" value={this.state.str_twitter} onChange={(e) => this.onChange('str_twitter', e)} placeholder="username" />
            </div>
          </div>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Instagram Username/ID</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_instagram', e)} type="text" name="str_instagram" value={this.state.str_instagram} onChange={(e) => this.onChange('str_instagram', e)} placeholder="username" />
            </div>
          </div>
        </div>
        <div className="dash-stat-block">
          <h3>Analytics</h3>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Facebook Pixel ID</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('facebookPixelId', e)} type="text" name="facebookPixelId" value={this.state.facebookPixelId} onChange={(e) => this.onChange('facebookPixelId', e)} placeholder="ex. 734486654670123" />
            </div>
          </div>
        </div>
        <div className="dash-stat-block">
          <h3>Mobile Settings</h3>
          <div className='mcg-details-item'>
            <div className='mcg-details-descriptor'>Mobile URL</div>
            <div className='mcg-details-input'>
            <input onBlur={(e) => this.saveField('str_mobile_url', e)} type="text" name="str_mobile_url" value={this.state.str_mobile_url} onChange={(e) => this.onChange('str_mobile_url', e)} placeholder="" />
            </div>
          </div>
        </div>
        <HourManager id={this.props.id} />
      </div>
    );
  }

  saveField(selector, e, phone = false) {
    if (phone) {
      let error = this.validateInput(e.target.value);

      if (error.length > 0) {
        console.error(error);

        return;
      }

      let company = this.state.company;
      company[selector] = e.target.value;

      client.service('account').patch(
        this.props.id,
        {
          [selector]: e.target.value
        }
      ).then(res => {
      }).catch(err => {
        console.error(err);
      })
    } else {
      client.service('account').patch(
        this.props.id,
        {
          [selector]: e.target.value
        }
      ).then(res => {
      }).catch(err => {
        console.error(err);
      })
    }
  }

  normalizeInput (value, previousValue) {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    if (!previousValue || value.length > previousValue.length) {
      if (currentValue.length <= 3) return currentValue;
      if (currentValue.length === 3) return `(${currentValue})`;
      if (currentValue.length <= 6) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      if (currentValue.length === 6) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}-`
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  }

  handlePhoneChange(selector, e) {
    const normalized = this.normalizeInput(e.target.value, this.state[selector]);
    
    this.setState({ [selector]: normalized });
  };

  onChange(selector, e) {
    this.setState({ [selector]: e.target.value });
  }

  validateInput(value) {
    let error = ""
    
    if ((value.length > 0) && (value.length !== 14)) error = "Invalid phone format. ex: (555) 555-5555";
    
    return error;
  }

  loadDetails() {
    client.service('account').get(
        this.props.id
    ).then(res => {
      this.setState({
        company: res,
        dataLoaded: true,
        str_domain: res.str_domain || '',

        // Location
        str_address1: res.str_address1 || '',
        str_address2: res.str_address2 || '',
        str_city: res.str_city || '',
        str_province: res.str_province || '',
        str_pcode: res.str_pcode || '',

        // Company
        str_company_name: res.str_company_name || '',
        str_title: res.str_title || '',
        str_description: res.str_description || '',

        // Contact Info
        str_phone: res.str_phone || '',
        str_mobile: res.str_mobile || '',
        str_fax: res.str_fax || '',
        str_tollfree: res.str_tollfree || '',
        str_email: res.str_email || '',

        // Integrations
        str_facebook: res.str_facebook || '',
        str_twitter: res.str_twitter || '',

        // Mobile
        str_mobile_url: res.str_mobile_url || ''
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.loadDetails();
    }
  }
}

export default Details;