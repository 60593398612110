import React, { Component } from 'react';
import StatusNotice from '../../components/statusnotice'
import '../../styles/dashboard/login.css'

class Login extends Component {
	constructor(props) {
    super(props);
    
    this.state = {
      user: '',
      pass: '',
      message: '',
      status: ''
    }

    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  
  render() {
    return (
      <div>
        <div className="mcg-login-logo">
          <img src="https://mcgmedia.net/wp-content/uploads/2017/07/mcglogowebdark.png" alt='MCG Media Admin logo' />
        </div>
        <div className="mcg-login">
          <StatusNotice status={this.state.status} message={this.state.message} />
          <h3>Sign in</h3>
          <div className="mcg-login-input">
            <i className="fas fa-envelope"></i>
            <input type="text" name="user" value={this.state.user} placeholder="username/email" onChange={this.onChange} />
          </div>
          <div className="mcg-login-input">
            <i className="fas fa-key"></i>
            <input type="password" name="pass" value={this.state.pass} placeholder="password" onChange={this.onChange} />
          </div>
          <button className="mcg-form-button" type="button" onClick={this.login}>Sign in</button>
        </div>
      </div>
    );
  }

  onChange(event) {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  login() {
    if((this.state.user === 'splash' && this.state.pass === 'splash2020$') || (this.state.user === 'mcgadmin' && this.state.pass === 'mightymir993')) {
      this.props.authenticate(() => {
        console.log('login successful');
        this.props.history.push('/dashboard');
      });
    } else {
      this.setState({
        status: 'failed',
        message: 'Username or password are incorrect.'
      })

      setTimeout(() => {
        this.setState({
          status: '',
          message: ''
        })
      }, 5000)
    }
  }
}

export default Login;