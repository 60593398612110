import React, { Component } from 'react';
import client from '../../../feathers';
import { Line } from 'react-chartjs-2';

import '../../../styles/dashboard/stats.css'
import moment from 'moment';
import Moment from 'react-moment';

class Stats extends Component {
	constructor(props) {
		super(props);

    this.state = {
      monthData: [],
      selectedMonth: undefined
    }

    this.loadData();
    this.renderMonthList = this.renderMonthList.bind(this);
  }

  render() {
    return (
      <div>
        <h2>MCG Analytics</h2>
        <div className="dash-stats-row">
          <div className="dash-stat-block">
            <div className="dash-stat-value">
              <div>{(typeof this.state.selectedMonth !== 'undefined') ? this.state.selectedMonth.visits : 0}</div>
            </div>
            <div className="dash-stat-header">
              <div>Views ({(typeof this.state.selectedMonth !== 'undefined') ? ((this.state.selectedMonth.month === 'Last 30 Days') ? this.state.selectedMonth.month : moment(this.state.selectedMonth.month).format('MMMM YYYY')) : 'Last 30 Days'})</div>
            </div>
          </div>
          <div className="dash-stat-block">
            <div className="dash-stat-value">
              <div>{(typeof this.state.selectedMonth !== 'undefined') ? this.state.selectedMonth.sessionCount : 0}</div>
            </div>
            <div className="dash-stat-header">
              <div>Visits ({(typeof this.state.selectedMonth !== 'undefined') ? ((this.state.selectedMonth.month === 'Last 30 Days') ? this.state.selectedMonth.month : moment(this.state.selectedMonth.month).format('MMMM YYYY')) : 'Last 30 Days'})</div>
            </div>
          </div>
          <div className="dash-stat-block">
            <div className="dash-stat-value">
              <div>{(typeof this.state.selectedMonth !== 'undefined') ? this.state.selectedMonth.users.length : 0}</div>
            </div>
            <div className="dash-stat-header">
              <div>Unique Users ({(typeof this.state.selectedMonth !== 'undefined') ? ((this.state.selectedMonth.month === 'Last 30 Days') ? this.state.selectedMonth.month : moment(this.state.selectedMonth.month).format('MMMM YYYY')) : 'Last 30 Days'})</div>
            </div>
          </div>
        </div>
        <div className="dash-stats-row">
          <div className="dash-stat-block">
            <h3>Daily Views ({(typeof this.state.selectedMonth !== 'undefined') ? ((this.state.selectedMonth.month === 'Last 30 Days') ? this.state.selectedMonth.month : moment(this.state.selectedMonth.month).format('MMMM YYYY')) : 'Last 30 Days'})</h3>
            <div className="dash-daily-chart">
              {this.renderDailyChart()}
            </div>
          </div>
          <div className="dash-stat-block dash-referrers">
            <h3>Monthly Views</h3>
            <div className="dash-referrer-list">
              {this.renderMonthList()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  /* Top Pages, Traffic Sources */
  renderDailyChart() {
    if (typeof this.state.selectedMonth === 'undefined')
      return;

    let compiledData = {
      labels: this.state.selectedMonth.days,
      datasets: [{
        key: 'Visits',
        label: 'Visits',
        responsive: true,
        fill: true,
        lineTension: 0.15,
        backgroundColor: 'rgba(58, 100, 179,0.1)',
        borderColor: 'rgba(58, 100, 179,1)',
        borderCapStyle: 'butt',
        borderWidth: 0.75,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(58, 100, 179,1)',
        pointBackgroundColor: 'rgba(58, 100, 179,1)',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(58, 100, 179,1)',
        pointHoverBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 5,
        data: this.state.selectedMonth.dailyVisits
      }]
    }
    let options = {
      scales: {
          xAxes: [{
              gridLines: {
                  display:false
              },
              ticks: {
                lineHeight: 2,
                padding: 3
              } 
          }],
          yAxes: [{
              gridLines: {
                color: "rgba(0, 0, 0, 0.05)",
              },
              ticks: {
                lineHeight: 2,
                padding: 3
              } 
          }]
      }
    }

    return <Line data={compiledData} legend={{display: false}} options={options} height={120} />;
  }

  renderMonthList() {
    let results = [];

    for (let i = this.state.monthData.length - 1; i >= this.state.monthData.length - 12 && i >= 0; i--) {
      const element = {...this.state.monthData[i]};

      results.push(
        <div key={'refmi-' + i} className="dash-referrer-item stat-month-list-item" onClick={() => {
          this.setState({ selectedMonth: element });
        }}>
          {(element.month === 'Last 30 Days') ? element.month : <Moment format="MMM YYYY">{element.month}</Moment>} <span className="dash-referrer-number">{element.visits}</span>
        </div>
      );
    }

    return results;
  }

  async loadData() {
    client.service('profile-analytics').find({
      query: {
        customerId: this.props.id,
        createdAt: {
          $gt: moment().subtract(12, 'months').format()
        }
      }
    }).then(res => {
      let monthData = [];
      let monthString, currentMonth, userIndex;
      let lastDays = [];
      // Summarize all the months
      for (let i = 0; i < res.length; i++) {
        monthString = res[i].createdAt.split('T')[0].substring(0,7);
        currentMonth = monthData.find(e => e.month === monthString);

        if (new Date(res[i].createdAt) >= moment().subtract(1, 'months')) {
          lastDays.push(res[i]);
        }

        if (typeof currentMonth !== 'undefined') {
          currentMonth.visits += 1;
          currentMonth.data.push(res[i]);
          if (!currentMonth.users.includes(res[i].userId + '_' + res[i].createdAt.split('T')[0]))
            currentMonth.users.push(res[i].userId + '_' + res[i].createdAt.split('T')[0]);

          //sessions
          userIndex = currentMonth.sessions.findIndex(e => e.userId === res[i].userId)
          if (userIndex < 0) {
            currentMonth.sessions.push({
              userId: res[i].userId,
              time: res[i].createdAt,
              visit: 1
            })
          } else {
            if (moment(res[i].createdAt).isSameOrAfter(moment(currentMonth.sessions[userIndex].time).add(20, 'minutes'))) {
              currentMonth.sessions[userIndex].visit += 1;
              currentMonth.sessions[userIndex].time = res[i].createdAt;
            }
          }
        } else {
          monthData.push({
            month: monthString,
            visits: 1,
            data: [res[i]],
            users: [res[i].userId + '_' + res[i].createdAt.split('T')[0]],
            sessions: [{
              userId: res[i].userId,
              time: res[i].createdAt,
              visit: 1
            }]
          })
        }
      }

      
      let recentMonth = {
        month: 'Last 30 Days',
        data: lastDays,
        users: [],
        sessions: [],
        visits: lastDays.length
      }

      // format the month data into day data
      let dailyVisits, dayLabels, sessionSum;
      monthData.forEach(month => {
        month.days = getDaysInMonth(month.month);
        dailyVisits = new Array();
        dayLabels = [];

        for (let d = 0; d < month.days.length; d++) {
          dayLabels.push(moment(month.days[d]).format('MMM DD'));
          dailyVisits.push(month.data.filter(e => new Date(e.createdAt).getDate() == month.days[d].getDate()).length);
        }

        sessionSum = 0;
        for (let i = 0; i < month.sessions.length; i++)
          sessionSum += month.sessions[i].visit;
        
        month.sessionCount = sessionSum;
        month.dailyVisits = dailyVisits;
        month.days = dayLabels;
      });

      // last 30 days
      for (let i = 0; i < recentMonth.data.length; i++) {
        const d = recentMonth.data[i];
        
        // users
        if (!recentMonth.users.includes(d.userId + '_' + d.createdAt.split('T')[0]))
          recentMonth.users.push(d.userId + '_' + d.createdAt.split('T')[0]);

        // sessions
        userIndex = recentMonth.sessions.findIndex(e => e.userId === d.userId)
        if (userIndex < 0) {
          recentMonth.sessions.push({
            userId: d.userId,
            time: d.createdAt,
            visit: 1
          })
        } else {
          if (moment(d.createdAt).isSameOrAfter(moment(recentMonth.sessions[userIndex].time).add(20, 'minutes'))) {
            recentMonth.sessions[userIndex].visit += 1;
            recentMonth.sessions[userIndex].time = d.createdAt;
          }
        }


        let dailyVisitslt = [];
        let dayLabelslt = [];


        var date = new Date(moment().subtract(1, 'months'));
        for (let dc = 0; dc <= 30; dc++) {
          dayLabelslt.push(moment(new Date(date)).format('MMM DD'));
          dailyVisitslt.push(recentMonth.data.filter(e => new Date(e.createdAt).getDate() == date.getDate()).length);

          date.setDate(date.getDate() + 1);
        }

        sessionSum = 0;
        for (let i = 0; i < recentMonth.sessions.length; i++)
          sessionSum += recentMonth.sessions[i].visit;
        
        recentMonth.sessionCount = sessionSum;
        recentMonth.dailyVisits = dailyVisitslt;
        recentMonth.days = dayLabelslt;
      }

      monthData.push(recentMonth);
      console.log(monthData);

      this.setState({
        monthData: monthData,
        selectedMonth: JSON.parse(JSON.stringify(recentMonth))
      })
    })
  }
}

function getDaysInMonth(dateString) {
  let month = parseInt(dateString.substring(5,7))-1;
  let year = parseInt(dateString.substring(0,4));
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

export default Stats;