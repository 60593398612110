import React, { Component } from 'react';

class Funerals extends Component {
  render() {
    return (
      <div>
        {this.renderItems()}
      </div>
    );
  }

  renderItems() {
    if (typeof this.props.items === 'undefined') return;
    let results = [];

    for (let index = 0; index < this.props.items.length; index++) {
      const item = this.props.items[index];
      results.push(
        <div key={'funeral' + index}>
          {item.name}
        </div>
      )
    }

    return results;
  }
}

export default Funerals;